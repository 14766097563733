import React from 'react';
import { format } from 'date-fns';
import { MessageCircle, CheckCircle, Phone, User } from 'lucide-react';
import type { ChatConversation } from '../../types';

interface ChatListProps {
  conversations: ChatConversation[];
  onSelectConversation: (conversation: ChatConversation) => void;
  selectedConversationId?: string;
}

export default function ChatList({
  conversations,
  onSelectConversation,
  selectedConversationId
}: ChatListProps) {
  if (conversations.length === 0) {
    return (
      <div className="text-center py-8">
        <MessageCircle className="w-12 h-12 text-gray-400 mx-auto mb-4" />
        <p className="text-gray-500">No conversations yet</p>
      </div>
    );
  }

  return (
    <div className="divide-y divide-gray-200">
      {conversations.map((conversation) => (
        <button
          key={conversation.id}
          onClick={() => onSelectConversation(conversation)}
          className={`w-full px-4 py-3 hover:bg-gray-50 flex items-start space-x-3 ${
            selectedConversationId === conversation.id ? 'bg-blue-50' : ''
          }`}
        >
          <div className="flex-shrink-0">
            <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center">
              <User className="w-6 h-6 text-blue-600" />
            </div>
          </div>
          <div className="flex-1 min-w-0 text-left">
            <div className="flex items-center justify-between">
              <p className="text-sm font-medium text-gray-900 truncate flex items-center">
                {conversation.user_name || 'Unknown User'}
                {conversation.status === 'completed' && (
                  <CheckCircle className="w-4 h-4 ml-1 text-green-500" />
                )}
              </p>
              {conversation.last_message_at && (
                <p className="text-xs text-gray-500">
                  {format(new Date(conversation.last_message_at), 'MMM d, HH:mm')}
                </p>
              )}
            </div>
            {conversation.phone_number && (
              <p className="text-sm text-gray-600 flex items-center">
                <Phone className="w-4 h-4 mr-1" />
                {conversation.phone_number}
              </p>
            )}
            {conversation.last_message && (
              <p className="text-sm text-gray-500 truncate">
                {conversation.last_message}
              </p>
            )}
            {conversation.status === 'completed' && (
              <p className="text-xs text-green-600 mt-1">
                Conversation completed
              </p>
            )}
          </div>
        </button>
      ))}
    </div>
  );
}