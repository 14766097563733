import React from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import DashboardStats from '../../components/admin/DashboardStats';
import RecentActivity from '../../components/admin/RecentActivity';
import AdminChat from '../../components/admin/Chat';
import OrderNotifications from '../../components/admin/OrderNotifications';
import { Power, Loader, HeadphonesIcon } from 'lucide-react';
import { ref, onValue, update } from 'firebase/database';
import { database } from '../../lib/firebase';
import toast from 'react-hot-toast';

export default function Dashboard() {
  const [loading, setLoading] = React.useState(false);
  const [supportOnline, setSupportOnline] = React.useState(true);

  // Subscribe to system settings
  React.useEffect(() => {
    const settingsRef = ref(database, 'system_settings');
    const unsubscribe = onValue(settingsRef, (snapshot) => {
      if (snapshot.exists()) {
        const settings = snapshot.val();
        setSupportOnline(settings.support_online ?? true);
      }
    });

    return () => unsubscribe();
  }, []);

  const toggleSupportStatus = async () => {
    try {
      setLoading(true);
      const settingsRef = ref(database, 'system_settings');
      
      await update(settingsRef, {
        support_online: !supportOnline,
        updated_at: new Date().toISOString()
      });

      setSupportOnline(!supportOnline);
      toast.success(`Support is now ${supportOnline ? 'offline' : 'online'}`);
    } catch (error) {
      console.error('Error toggling support status:', error);
      toast.error('Failed to update support status');
    } finally {
      setLoading(false);
    }
  };

  return (
    <AdminLayout>
      <div className="space-y-8">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-gray-900">Dashboard Overview</h1>
          <div className="flex items-center space-x-4">
            <button
              onClick={toggleSupportStatus}
              disabled={loading}
              className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
                supportOnline
                  ? 'bg-green-100 text-green-800 hover:bg-green-200'
                  : 'bg-red-100 text-red-800 hover:bg-red-200'
              }`}
            >
              {loading ? (
                <Loader className="w-5 h-5 mr-2 animate-spin" />
              ) : (
                <HeadphonesIcon className="w-5 h-5 mr-2" />
              )}
              <span className="font-medium">
                Support Status: {supportOnline ? 'Online' : 'Offline'}
              </span>
            </button>
            <div className="text-sm text-gray-500">
              <span>Last updated:</span>
              <span className="ml-2 font-medium">
                {new Date().toLocaleString()}
              </span>
            </div>
          </div>
        </div>

        <DashboardStats />
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="h-full">
            <OrderNotifications />
          </div>
          <div className="h-full">
            <RecentActivity />
          </div>
        </div>

        <AdminChat />
      </div>
    </AdminLayout>
  );
}