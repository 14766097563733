import React, { createContext, useContext, useState, useEffect } from 'react';
import { ref, onValue, set } from 'firebase/database';
import { database } from '../lib/firebase';
import toast from 'react-hot-toast';

interface RatesSettings {
  section_title: string;
  buy_rate_label: string;
  sell_rate_label: string;
  show_buy_rate: boolean;
  show_sell_rate: boolean;
  manual_rates: boolean;
  manual_buy_rate: number;
  manual_sell_rate: number;
  api_source: string;
  api_key: string;
}

interface RateContextType {
  currentRate: number;
  setCurrentRate: (rate: number) => void;
  buyRate: number;
  sellRate: number;
  lastUpdated: Date | null;
  isOnline: boolean;
}

const RateContext = createContext<RateContextType | undefined>(undefined);

const DEFAULT_SETTINGS: RatesSettings = {
  section_title: 'Best USDT Rates in Pakistan',
  buy_rate_label: 'Buy Rate',
  sell_rate_label: 'Sell Rate',
  show_buy_rate: true,
  show_sell_rate: true,
  manual_rates: false,
  manual_buy_rate: 285.50,
  manual_sell_rate: 284.50,
  api_source: 'exchangerate-api.com',
  api_key: 'fa47175a9f3fa91907fa7f8d'
};

export function RateProvider({ children }: { children: React.ReactNode }) {
  const [currentRate, setCurrentRate] = useState(284.50);
  const [buyRate, setBuyRate] = useState(currentRate + 1);
  const [sellRate, setSellRate] = useState(currentRate);
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
  const [settings, setSettings] = useState<RatesSettings>(DEFAULT_SETTINGS);
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    // Subscribe to maintenance mode status
    const maintenanceRef = ref(database, 'system_settings/maintenance_mode');
    const unsubscribeMaintenance = onValue(maintenanceRef, (snapshot) => {
      setIsOnline(!snapshot.val());
    });

    // Subscribe to rates settings
    const settingsRef = ref(database, 'rates_settings');
    const unsubscribeSettings = onValue(settingsRef, async (snapshot) => {
      try {
        let currentSettings: RatesSettings;
        
        if (snapshot.exists()) {
          currentSettings = snapshot.val();
        } else {
          // Initialize settings if they don't exist
          currentSettings = DEFAULT_SETTINGS;
          await set(settingsRef, {
            ...DEFAULT_SETTINGS,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString()
          });
        }

        setSettings(currentSettings);

        if (currentSettings.manual_rates) {
          setBuyRate(currentSettings.manual_buy_rate);
          setSellRate(currentSettings.manual_sell_rate);
          setCurrentRate(currentSettings.manual_sell_rate);
          setLastUpdated(new Date());
        } else {
          fetchRates(currentSettings);
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
        // Use default settings if there's an error
        setSettings(DEFAULT_SETTINGS);
        fetchRates(DEFAULT_SETTINGS);
      }
    });

    return () => {
      unsubscribeMaintenance();
      unsubscribeSettings();
    };
  }, []);

  const fetchRates = async (currentSettings: RatesSettings) => {
    if (currentSettings.manual_rates) return;

    try {
      const response = await fetch(
        `https://v6.exchangerate-api.com/v6/${currentSettings.api_key}/latest/USD`,
        {
          headers: {
            'Accept': 'application/json',
          },
          cache: 'no-store'
        }
      );
      
      if (!response.ok) {
        throw new Error('Failed to fetch rates');
      }

      const data = await response.json();
      
      if (data.result === 'success') {
        const usdToPkr = data.conversion_rates.PKR;
        const baseRate = usdToPkr * 1.002; // 0.2% premium for USDT
        setSellRate(baseRate);
        setBuyRate(baseRate + 1); // 1 PKR spread
        setCurrentRate(baseRate);
        setLastUpdated(new Date());
      }
    } catch (error) {
      console.error('Error fetching rates:', error);
      // Keep using existing rates if API fails
      // Don't show error toast here as it's not critical
    }
  };

  // Set up periodic rate updates
  useEffect(() => {
    if (!settings.manual_rates) {
      const interval = setInterval(() => fetchRates(settings), 30000); // Update every 30 seconds
      return () => clearInterval(interval);
    }
  }, [settings]);

  return (
    <RateContext.Provider value={{ 
      currentRate, 
      setCurrentRate,
      buyRate,
      sellRate,
      lastUpdated,
      isOnline
    }}>
      {children}
    </RateContext.Provider>
  );
}

export function useRate() {
  const context = useContext(RateContext);
  if (context === undefined) {
    throw new Error('useRate must be used within a RateProvider');
  }
  return context;
}