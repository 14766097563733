import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  PhoneCall, 
  BookOpen, 
  Shield, 
  HelpCircle, 
  FileText, 
  MessageSquare,
  Facebook,
  Twitter,
  Instagram
} from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { ref, onValue } from 'firebase/database';
import { database } from '../lib/firebase';
import AuthModal from './auth/AuthModal';

interface Settings {
  company_name: string;
  support_email: string;
  support_phone: string;
  support_hours: string;
  social_media: {
    facebook: { url: string; enabled: boolean };
    twitter: { url: string; enabled: boolean };
    tiktok: { url: string; enabled: boolean };
    instagram: { url: string; enabled: boolean };
  };
}

export default function Footer() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [settings, setSettings] = useState<Settings>({
    company_name: 'USDT Exchange',
    support_email: '',
    support_phone: '',
    support_hours: '',
    social_media: {
      facebook: { url: '', enabled: false },
      twitter: { url: '', enabled: false },
      tiktok: { url: '', enabled: false },
      instagram: { url: '', enabled: false }
    }
  });

  useEffect(() => {
    const settingsRef = ref(database, 'system_settings');
    const unsubscribe = onValue(settingsRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setSettings({
          company_name: data.company_name || 'USDT Exchange',
          support_email: data.support_email || '',
          support_phone: data.support_phone || '',
          support_hours: data.support_hours || '',
          social_media: data.social_media || {
            facebook: { url: '', enabled: false },
            twitter: { url: '', enabled: false },
            tiktok: { url: '', enabled: false },
            instagram: { url: '', enabled: false }
          }
        });
      }
    }, (error) => {
      console.error('Error fetching settings:', error);
    });

    return () => unsubscribe();
  }, []);

  const handleExchangeClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (user) {
      navigate('/user');
    } else {
      setShowAuthModal(true);
    }
  };

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <footer className="bg-gradient-to-b from-gray-900 to-blue-900 text-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-4 gap-8 mb-8">
          <div>
            <h3 className="text-lg font-semibold mb-4 text-blue-300">About Us</h3>
            <p className="text-gray-400 text-sm">
              Pakistan's most trusted platform for buying and selling USDT. We provide secure, instant transactions with the best rates.
            </p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4 text-blue-300">Quick Links</h3>
            <ul className="space-y-2 text-gray-400">
              <li>
                <a
                  href="/user"
                  onClick={handleExchangeClick}
                  className="hover:text-white transition-colors cursor-pointer flex items-center"
                >
                  <Shield className="w-4 h-4 mr-2" />
                  Exchange USDT
                </a>
              </li>
              <li>
                <Link to="/blog" className="hover:text-white transition-colors flex items-center">
                  <BookOpen className="w-4 h-4 mr-2" />
                  Blog
                </Link>
              </li>
              <li>
                <a
                  href="/#live-rates"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('live-rates');
                  }}
                  className="hover:text-white transition-colors flex items-center"
                >
                  <FileText className="w-4 h-4 mr-2" />
                  Live Rates
                </a>
              </li>
              <li>
                <a
                  href="/#security"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('security');
                  }}
                  className="hover:text-white transition-colors flex items-center"
                >
                  <Shield className="w-4 h-4 mr-2" />
                  Security Tips
                </a>
              </li>
              <li>
                <a
                  href="/#faq"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('faq');
                  }}
                  className="hover:text-white transition-colors flex items-center"
                >
                  <HelpCircle className="w-4 h-4 mr-2" />
                  FAQs
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4 text-blue-300">Support</h3>
            <ul className="space-y-2 text-gray-400">
              <li className="flex items-center">
                <PhoneCall className="w-4 h-4 mr-2" />
                {settings.support_hours}
              </li>
              <li>
                <Link
                  to="/contact"
                  className="hover:text-white transition-colors flex items-center"
                >
                  <MessageSquare className="w-4 h-4 mr-2" />
                  Contact Us
                </Link>
              </li>
              <li>
                <a
                  href="/user"
                  onClick={handleExchangeClick}
                  className="hover:text-white transition-colors cursor-pointer flex items-center"
                >
                  <Shield className="w-4 h-4 mr-2" />
                  Report an Issue
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4 text-blue-300">Legal</h3>
            <ul className="space-y-2 text-gray-400">
              <li>
                <Link to="/legal/terms" className="hover:text-white transition-colors flex items-center">
                  <FileText className="w-4 h-4 mr-2" />
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link to="/legal/privacy" className="hover:text-white transition-colors flex items-center">
                  <Shield className="w-4 h-4 mr-2" />
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/legal/aml" className="hover:text-white transition-colors flex items-center">
                  <FileText className="w-4 h-4 mr-2" />
                  AML Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Social Media Links */}
        {(settings.social_media.facebook.enabled ||
          settings.social_media.twitter.enabled ||
          settings.social_media.tiktok.enabled ||
          settings.social_media.instagram.enabled) && (
          <div className="flex justify-center space-x-6 mb-8">
            {settings.social_media.facebook.enabled && settings.social_media.facebook.url && (
              <a
                href={settings.social_media.facebook.url}
                target="_blank"
                rel="noopener noreferrer"
                className="transform hover:scale-110 transition-transform duration-300"
                title="Follow us on Facebook"
              >
                <div className="w-10 h-10 bg-blue-600 rounded-lg shadow-lg flex items-center justify-center hover:bg-blue-700 transition-colors">
                  <Facebook className="w-6 h-6 text-white" />
                </div>
              </a>
            )}
            {settings.social_media.twitter.enabled && settings.social_media.twitter.url && (
              <a
                href={settings.social_media.twitter.url}
                target="_blank"
                rel="noopener noreferrer"
                className="transform hover:scale-110 transition-transform duration-300"
                title="Follow us on X (Twitter)"
              >
                <div className="w-10 h-10 bg-black rounded-lg shadow-lg flex items-center justify-center hover:bg-gray-900 transition-colors">
                  <Twitter className="w-6 h-6 text-white" />
                </div>
              </a>
            )}
            {settings.social_media.tiktok.enabled && settings.social_media.tiktok.url && (
              <a
                href={settings.social_media.tiktok.url}
                target="_blank"
                rel="noopener noreferrer"
                className="transform hover:scale-110 transition-transform duration-300"
                title="Follow us on TikTok"
              >
                <div className="w-10 h-10 bg-black rounded-lg shadow-lg flex items-center justify-center hover:bg-gray-900 transition-colors">
                  <svg className="w-6 h-6 text-white" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z"/>
                  </svg>
                </div>
              </a>
            )}
            {settings.social_media.instagram.enabled && settings.social_media.instagram.url && (
              <a
                href={settings.social_media.instagram.url}
                target="_blank"
                rel="noopener noreferrer"
                className="transform hover:scale-110 transition-transform duration-300"
                title="Follow us on Instagram"
              >
                <div className="w-10 h-10 bg-gradient-to-br from-purple-600 via-pink-600 to-orange-600 rounded-lg shadow-lg flex items-center justify-center hover:from-purple-700 hover:via-pink-700 hover:to-orange-700 transition-colors">
                  <Instagram className="w-6 h-6 text-white" />
                </div>
              </a>
            )}
          </div>
        )}

        <div className="border-t border-gray-800 pt-8 text-center">
          <p className="text-gray-400">
            © {new Date().getFullYear()}{' '}
            <Link to="/" className="hover:text-white transition-colors">
              {settings.company_name}
            </Link>
            . All rights reserved.
          </p>
        </div>
      </div>

      <AuthModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        defaultTab="login"
      />
    </footer>
  );
}