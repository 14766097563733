import React from 'react';
import { DollarSign } from 'lucide-react';
import type { SystemSettings } from '../../../types';

interface TransactionSettingsProps {
  settings: SystemSettings;
  onSettingsChange: (settings: SystemSettings) => void;
}

export default function TransactionSettings({ settings, onSettingsChange }: TransactionSettingsProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h2 className="text-lg font-semibold mb-4 flex items-center">
        <DollarSign className="w-5 h-5 mr-2 text-blue-600" />
        Transaction Settings
      </h2>
      <div className="space-y-6">
        <div className="grid md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Minimum Transaction Amount (USDT)
            </label>
            <input
              type="number"
              value={settings.min_transaction_amount}
              onChange={(e) => onSettingsChange({ ...settings, min_transaction_amount: parseFloat(e.target.value) })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              min="0"
              step="1"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Maximum Transaction Amount (USDT)
            </label>
            <input
              type="number"
              value={settings.max_transaction_amount}
              onChange={(e) => onSettingsChange({ ...settings, max_transaction_amount: parseFloat(e.target.value) })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              min="0"
              step="1"
              required
            />
          </div>
        </div>

        <div className="border-t pt-6">
          <div className="flex items-center justify-between mb-4">
            <div>
              <label className="font-medium text-gray-900">Transaction Fee</label>
              <p className="text-sm text-gray-500">
                Enable and set transaction fee
              </p>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={settings.transaction_fee_enabled || false}
                onChange={(e) => onSettingsChange({ ...settings, transaction_fee_enabled: e.target.checked })}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
          </div>

          <div className={settings.transaction_fee_enabled ? 'opacity-100' : 'opacity-50 pointer-events-none'}>
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Fee Type
                </label>
                <select
                  value={settings.transaction_fee_type || 'percentage'}
                  onChange={(e) => onSettingsChange({ ...settings, transaction_fee_type: e.target.value as 'percentage' | 'flat' })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  disabled={!settings.transaction_fee_enabled}
                >
                  <option value="percentage">Percentage (%)</option>
                  <option value="flat">Flat Fee (USDT)</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {settings.transaction_fee_type === 'flat' ? 'Flat Fee (USDT)' : 'Fee Percentage (%)'}
                </label>
                <input
                  type="number"
                  value={settings.transaction_fee_type === 'flat' ? settings.transaction_fee_flat || 0 : settings.transaction_fee_percentage || 0}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (settings.transaction_fee_type === 'flat') {
                      onSettingsChange({ ...settings, transaction_fee_flat: value });
                    } else {
                      onSettingsChange({ ...settings, transaction_fee_percentage: value });
                    }
                  }}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  min="0"
                  step={settings.transaction_fee_type === 'flat' ? '0.1' : '0.01'}
                  required
                  disabled={!settings.transaction_fee_enabled}
                />
              </div>
            </div>
            <p className="mt-2 text-sm text-gray-500">
              {settings.transaction_fee_type === 'flat' 
                ? 'A fixed amount will be deducted from each transaction'
                : 'This percentage will be deducted from each transaction'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}