import React, { useState, useEffect } from 'react';
import { CheckCircle, Clock, XCircle, FileText, Image as ImageIcon, X as XIcon, ExternalLink } from 'lucide-react';
import { ref, onValue } from 'firebase/database';
import { database } from '../../lib/firebase';
import { useAuth } from '../../context/AuthContext';
import toast from 'react-hot-toast';

interface Order {
  id: string;
  amount: number;
  rate: number;
  status: 'pending' | 'processing' | 'completed' | 'failed';
  transaction_id: string;
  proof_url: string;
  admin_proof_url?: string;
  created_at: string;
}

export default function RecentOrders() {
  const { user } = useAuth();
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedProofUrl, setSelectedProofUrl] = useState<string | null>(null);
  const [proofType, setProofType] = useState<'user' | 'admin' | null>(null);

  useEffect(() => {
    if (user) {
      const ordersRef = ref(database, 'orders');
      const unsubscribe = onValue(ordersRef, (snapshot) => {
        try {
          if (snapshot.exists()) {
            const ordersData = snapshot.val();
            const ordersArray = Object.entries(ordersData)
              .map(([id, data]: [string, any]) => ({
                id,
                ...data
              }))
              // Filter orders for current user
              .filter(order => order.user_id === user.uid)
              // Sort by date descending
              .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
            
            setOrders(ordersArray);
          } else {
            setOrders([]);
          }
        } catch (error) {
          console.error('Error processing orders:', error);
          toast.error('Failed to load orders');
        } finally {
          setLoading(false);
        }
      });

      return () => unsubscribe();
    }
  }, [user]);

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'completed':
        return <CheckCircle className="w-5 h-5 text-green-500" />;
      case 'rejected':
        return <XCircle className="w-5 h-5 text-red-500" />;
      default:
        return <Clock className="w-5 h-5 text-yellow-500" />;
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'completed':
        return 'text-green-800 bg-green-100';
      case 'rejected':
        return 'text-red-800 bg-red-100';
      default:
        return 'text-yellow-800 bg-yellow-100';
    }
  };

  const viewProof = (url: string, type: 'user' | 'admin') => {
    setSelectedProofUrl(url);
    setProofType(type);
  };

  if (loading) {
    return (
      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-6">Recent Orders</h2>
        <div className="bg-white rounded-lg shadow-sm p-8">
          <div className="animate-pulse space-y-4">
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            <div className="h-4 bg-gray-200 rounded w-1/2"></div>
            <div className="h-4 bg-gray-200 rounded w-1/3"></div>
          </div>
        </div>
      </div>
    );
  }

  if (!loading && orders.length === 0) {
    return (
      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-6">Recent Orders</h2>
        <div className="bg-white rounded-lg shadow-sm p-8 text-center">
          <FileText className="w-12 h-12 text-gray-400 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">No Orders Yet</h3>
          <p className="text-gray-600">
            Your recent transactions will appear here once you make your first order.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-8">
      <h2 className="text-xl font-semibold mb-6">Recent Orders</h2>
      <div className="bg-white rounded-lg shadow-sm overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Transaction ID
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Amount
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Rate
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total (PKR)
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Proof
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {orders.map((order) => (
                <tr key={order.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {new Date(order.created_at).toLocaleString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {order.transaction_id}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {order.amount} USDT
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {order.rate}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {(order.amount * order.rate).toFixed(2)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      {getStatusIcon(order.status)}
                      <span className={`ml-2 text-sm px-2 py-1 rounded-full capitalize ${getStatusColor(order.status)}`}>
                        {order.status}
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={() => viewProof(order.proof_url, 'user')}
                        className="text-blue-600 hover:text-blue-800"
                        title="View your proof"
                      >
                        <ImageIcon className="w-5 h-5" />
                      </button>
                      {order.admin_proof_url && (
                        <button
                          onClick={() => viewProof(order.admin_proof_url!, 'admin')}
                          className="text-green-600 hover:text-green-800"
                          title="View admin payment proof"
                        >
                          <CheckCircle className="w-5 h-5" />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Proof Viewer Modal */}
        {selectedProofUrl && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
            <div className="relative max-w-4xl w-full mx-4">
              <div className="bg-white rounded-lg p-4">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-medium">
                    {proofType === 'user' ? "Your Payment Proof" : "Admin's Payment Proof"}
                  </h3>
                  <div className="flex items-center space-x-2">
                    <a
                      href={selectedProofUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="p-2 text-gray-600 hover:text-gray-900"
                      title="Open in new tab"
                    >
                      <ExternalLink className="w-5 h-5" />
                    </a>
                    <button
                      onClick={() => {
                        setSelectedProofUrl(null);
                        setProofType(null);
                      }}
                      className="p-2 text-gray-600 hover:text-gray-900"
                    >
                      <XIcon className="w-5 h-5" />
                    </button>
                  </div>
                </div>
                <img
                  src={selectedProofUrl}
                  alt="Payment Proof"
                  className="max-w-full max-h-[70vh] mx-auto rounded-lg"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}