import React, { useState, useEffect } from 'react';
import ChatList from '../chat/ChatList';
import ChatWindow from '../chat/ChatWindow';
import type { ChatConversation } from '../../types';
import { ref, onValue, get } from 'firebase/database';
import { database } from '../../lib/firebase';
import { useAuth } from '../../context/AuthContext';
import { Loader, MessageSquare } from 'lucide-react';
import toast from 'react-hot-toast';

export default function AdminChat() {
  const { user, profile } = useAuth();
  const [selectedConversation, setSelectedConversation] = useState<ChatConversation | null>(null);
  const [conversations, setConversations] = useState<ChatConversation[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user && profile?.role === 'admin') {
      const unsubscribe = subscribeToConversations();
      return () => unsubscribe();
    }
  }, [user, profile]);

  const subscribeToConversations = () => {
    const conversationsRef = ref(database, 'chat_conversations');
    
    return onValue(conversationsRef, async (snapshot) => {
      try {
        if (snapshot.exists()) {
          const conversationsData = snapshot.val();
          const conversationsPromises = Object.entries(conversationsData).map(async ([id, conv]: [string, any]) => {
            try {
              // Fetch user profile for each conversation
              const userRef = ref(database, `profiles/${conv.user_id}`);
              const userSnapshot = await get(userRef);
              const userData = userSnapshot.val();

              return {
                id,
                ...conv,
                user_name: userData?.full_name || 'Unknown User',
                phone_number: userData?.phone_number || 'No phone number'
              };
            } catch (error) {
              console.error('Error fetching user details:', error);
              return {
                id,
                ...conv,
                user_name: 'Unknown User',
                phone_number: 'No phone number'
              };
            }
          });

          const resolvedConversations = await Promise.all(conversationsPromises);
          
          // Sort conversations by last message time
          const sortedConversations = resolvedConversations.sort((a, b) => {
            const timeA = a.last_message_at ? new Date(a.last_message_at).getTime() : 0;
            const timeB = b.last_message_at ? new Date(b.last_message_at).getTime() : 0;
            return timeB - timeA;
          });

          setConversations(sortedConversations);

          // Update selected conversation if it exists in the new data
          if (selectedConversation) {
            const updatedConversation = sortedConversations.find(
              conv => conv.id === selectedConversation.id
            );
            if (updatedConversation) {
              setSelectedConversation(updatedConversation);
            }
          }
        } else {
          setConversations([]);
        }
      } catch (error) {
        console.error('Error processing conversations:', error);
        toast.error('Failed to load conversations');
      } finally {
        setLoading(false);
      }
    });
  };

  if (loading) {
    return (
      <div className="bg-white rounded-lg shadow-sm overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-xl font-semibold">Customer Support Chat</h2>
        </div>
        <div className="flex items-center justify-center h-[600px]">
          <Loader className="w-8 h-8 text-blue-600 animate-spin" />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm overflow-hidden">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-xl font-semibold">Customer Support Chat</h2>
      </div>

      <div className="grid grid-cols-3 divide-x divide-gray-200 h-[600px]">
        {/* Conversations List */}
        <div className="col-span-1 overflow-y-auto border-r border-gray-200">
          {conversations.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-full text-gray-500">
              <MessageSquare className="w-12 h-12 text-gray-400 mb-2" />
              <p>No conversations yet</p>
            </div>
          ) : (
            <ChatList
              conversations={conversations}
              onSelectConversation={setSelectedConversation}
              selectedConversationId={selectedConversation?.id}
            />
          )}
        </div>

        {/* Chat Window */}
        <div className="col-span-2">
          {selectedConversation ? (
            <ChatWindow
              conversationId={selectedConversation.id}
              recipientName={selectedConversation.user_name}
            />
          ) : (
            <div className="flex items-center justify-center h-full text-gray-500">
              <p>Select a conversation to start chatting</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}