import React from 'react';
import { useLocation, Navigate, Link } from 'react-router-dom';
import { CheckCircle, Clock, MessageCircle, ArrowLeft } from 'lucide-react';

interface TransactionDetails {
  amount: number;
  rate: number;
  bankDetails: {
    accountName: string;
    iban: string;
    bankName: string;
  };
  transactionId: string;
  feeAmount?: number;
  netAmount?: number;
}

function Confirmation() {
  const location = useLocation();
  const transaction = location.state?.transaction as TransactionDetails;

  if (!transaction) {
    return <Navigate to="/user" replace />;
  }

  const estimatedTime = '30 minutes';
  const whatsappNumber = '+923001234567'; // Replace with actual support number
  const whatsappMessage = `Hi, I need help with my transaction ${transaction.transactionId}`;
  const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`;

  // Calculate amounts
  const totalAmount = transaction.amount * transaction.rate;
  const netAmount = transaction.netAmount || transaction.amount;
  const netPKR = netAmount * transaction.rate;

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
        <Link to="/user" className="inline-flex items-center text-blue-600 hover:text-blue-800 mb-6">
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Dashboard
        </Link>
        
        <div className="bg-white rounded-lg shadow-sm overflow-hidden">
          {/* Header */}
          <div className="bg-green-50 p-6 flex items-center">
            <CheckCircle className="w-8 h-8 text-green-500 mr-4" />
            <div>
              <h1 className="text-xl font-semibold text-green-900">Transaction Submitted Successfully</h1>
              <p className="text-green-700">Your USDT sale request has been received</p>
            </div>
          </div>

          {/* Transaction Details */}
          <div className="p-6 border-b border-gray-200">
            <h2 className="text-lg font-semibold mb-4">Transaction Details</h2>
            <div className="space-y-4">
              <div className="flex justify-between">
                <span className="text-gray-600">Transaction ID</span>
                <span className="font-medium">{transaction.transactionId}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Amount</span>
                <span className="font-medium">{transaction.amount} USDT</span>
              </div>
              {transaction.feeAmount && transaction.feeAmount > 0 && (
                <div className="flex justify-between">
                  <span className="text-gray-600">Transaction Fee</span>
                  <span className="font-medium text-red-600">-{transaction.feeAmount} USDT</span>
                </div>
              )}
              {transaction.netAmount && transaction.netAmount !== transaction.amount && (
                <div className="flex justify-between">
                  <span className="text-gray-600">Net Amount</span>
                  <span className="font-medium">{transaction.netAmount} USDT</span>
                </div>
              )}
              <div className="flex justify-between">
                <span className="text-gray-600">Rate</span>
                <span className="font-medium">PKR {transaction.rate}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Total Amount (PKR)</span>
                <span className="font-bold text-lg">
                  PKR {netPKR.toFixed(2)}
                </span>
              </div>
            </div>
          </div>

          {/* Bank Details */}
          <div className="p-6 border-b border-gray-200">
            <h2 className="text-lg font-semibold mb-4">Bank Details</h2>
            <div className="space-y-4">
              <div className="flex justify-between">
                <span className="text-gray-600">Account Name</span>
                <span className="font-medium">{transaction.bankDetails.accountName}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Bank Name</span>
                <span className="font-medium">{transaction.bankDetails.bankName}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">IBAN</span>
                <span className="font-medium">{transaction.bankDetails.iban}</span>
              </div>
            </div>
          </div>

          {/* Processing Time */}
          <div className="p-6 border-b border-gray-200 bg-blue-50">
            <div className="flex items-center">
              <Clock className="w-6 h-6 text-blue-500 mr-3" />
              <div>
                <h3 className="font-medium text-blue-900">Estimated Processing Time</h3>
                <p className="text-blue-700">Your payment will be processed within {estimatedTime}</p>
              </div>
            </div>
          </div>

          {/* Support Button */}
          <div className="p-6">
            <a
              href={whatsappUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center w-full bg-green-500 text-white py-3 rounded-lg hover:bg-green-600 transition-colors"
            >
              <MessageCircle className="w-5 h-5 mr-2" />
              Contact Support on WhatsApp
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Confirmation;
