import React, { useState, useEffect } from 'react';
import { Save, RefreshCcw, FileText, Globe, Link as LinkIcon } from 'lucide-react';
import { ref, onValue, get, set } from 'firebase/database';
import { database } from '../../../lib/firebase';
import toast from 'react-hot-toast';

interface SitemapURL {
  loc: string;
  lastmod?: string;
  changefreq?: 'always' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly' | 'never';
  priority?: number;
}

export default function Sitemap() {
  const [loading, setLoading] = useState(true);
  const [generating, setGenerating] = useState(false);
  const [sitemap, setSitemap] = useState<string>('');
  const [baseUrl, setBaseUrl] = useState('https://example.com');
  const [lastGenerated, setLastGenerated] = useState<Date | null>(null);
  const [sitemapUrl, setSitemapUrl] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = subscribeToSettings();
    return () => unsubscribe();
  }, []);

  const subscribeToSettings = () => {
    const settingsRef = ref(database, 'system_settings');
    return onValue(settingsRef, async (snapshot) => {
      try {
        if (snapshot.exists()) {
          const settings = snapshot.val();
          
          // Try to get existing sitemap
          const sitemapRef = ref(database, 'sitemaps/sitemap');
          const sitemapSnapshot = await get(sitemapRef);
          
          if (sitemapSnapshot.exists()) {
            const sitemapData = sitemapSnapshot.val();
            setSitemap(sitemapData.content);
            setSitemapUrl(window.location.origin + '/sitemap.xml');
            setLastGenerated(new Date(sitemapData.generated_at));
          }
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
        toast.error('Failed to load sitemap settings');
      } finally {
        setLoading(false);
      }
    });
  };

  const generateSitemap = async () => {
    setGenerating(true);
    try {
      // Get all published blog posts
      const postsRef = ref(database, 'blog_posts');
      const postsSnapshot = await get(postsRef);
      
      let publishedPosts: any[] = [];
      if (postsSnapshot.exists()) {
        const posts = postsSnapshot.val();
        publishedPosts = Object.values(posts)
          .filter((post: any) => post.status === 'published')
          .map((post: any) => ({
            slug: post.slug,
            updated_at: post.updated_at
          }));
      }

      // Define static pages
      const staticPages: SitemapURL[] = [
        { loc: '/', priority: 1.0, changefreq: 'weekly' },
        { loc: '/blog', priority: 0.8, changefreq: 'daily' },
        { loc: '/user', priority: 0.7, changefreq: 'monthly' }
      ];

      // Add blog posts
      const blogPages: SitemapURL[] = publishedPosts.map((post: any) => ({
        loc: `/blog/${post.slug}`,
        lastmod: post.updated_at,
        priority: 0.6,
        changefreq: 'weekly'
      }));

      // Combine all URLs
      const allUrls = [...staticPages, ...blogPages];

      // Generate XML
      const xml = `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
${allUrls.map(url => `  <url>
    <loc>${baseUrl}${url.loc}</loc>
    ${url.lastmod ? `    <lastmod>${new Date(url.lastmod).toISOString()}</lastmod>\n` : ''}    <changefreq>${url.changefreq}</changefreq>
    <priority>${url.priority}</priority>
  </url>`).join('\n')}
</urlset>`;

      // Store in Realtime Database
      const sitemapRef = ref(database, 'sitemaps/sitemap');
      await set(sitemapRef, {
        content: xml,
        generated_at: new Date().toISOString()
      });
      
      setSitemap(xml);
      setSitemapUrl(window.location.origin + '/sitemap.xml');
      setLastGenerated(new Date());
      
      toast.success('Sitemap generated successfully');
      toast.success(
        <div>
          Sitemap generated! View it at: <br />
          <a 
            href={window.location.origin + '/sitemap.xml'} 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 underline"
          >
            {window.location.origin + '/sitemap.xml'}
          </a>
        </div>,
        { duration: 6000 }
      );
    } catch (error) {
      console.error('Error generating sitemap:', error);
      toast.error('Failed to generate sitemap');
    } finally {
      setGenerating(false);
    }
  };

  if (loading) {
    return (
      <div className="animate-pulse">
        <div className="h-8 bg-gray-200 rounded w-1/4 mb-8"></div>
        <div className="space-y-4">
          <div className="h-24 bg-gray-200 rounded"></div>
          <div className="h-64 bg-gray-200 rounded"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">Sitemap Generator</h1>
        <button
          onClick={generateSitemap}
          disabled={generating}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
        >
          {generating ? (
            <>
              <RefreshCcw className="w-5 h-5 mr-2 animate-spin" />
              Generating...
            </>
          ) : (
            <>
              <Save className="w-5 h-5 mr-2" />
              Generate Sitemap
            </>
          )}
        </button>
      </div>

      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Website Base URL
            </label>
            <div className="flex items-center space-x-2">
              <div className="relative flex-1">
                <Globe className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                <input
                  type="url"
                  value={baseUrl}
                  onChange={(e) => setBaseUrl(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="https://example.com"
                  required
                />
              </div>
              {sitemapUrl && (
                <a
                  href={sitemapUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-2 text-blue-600 hover:text-blue-800"
                >
                  <LinkIcon className="w-5 h-5" />
                </a>
              )}
            </div>
            {lastGenerated && (
              <p className="mt-2 text-sm text-gray-500">
                Last generated: {lastGenerated.toLocaleString()}
              </p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Preview
            </label>
            <div className="relative">
              <FileText className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
              <pre className="w-full h-96 pl-10 pr-4 py-2 bg-gray-50 border border-gray-300 rounded-lg overflow-auto font-mono text-sm">
                {sitemap || 'No sitemap generated yet. Click "Generate Sitemap" to create one.'}
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}