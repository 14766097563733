import React, { useState } from 'react';
import { Video, X } from 'lucide-react';
import { ref, onValue } from 'firebase/database';
import { database } from '../../lib/firebase';

export default function VideoGuide() {
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [isEnabled, setIsEnabled] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  React.useEffect(() => {
    const settingsRef = ref(database, 'system_settings');
    const unsubscribe = onValue(settingsRef, (snapshot) => {
      if (snapshot.exists()) {
        const settings = snapshot.val();
        setIsEnabled(settings.video_guidance_enabled || false);
        setVideoUrl(settings.video_guidance_url || null);
      }
    });

    return () => unsubscribe();
  }, []);

  if (!isEnabled || !videoUrl) return null;

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold flex items-center">
          <Video className="w-5 h-5 mr-2 text-blue-600" />
          How to Sell USDT
        </h2>
        {showVideo && (
          <button
            onClick={() => setShowVideo(false)}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-5 h-5" />
          </button>
        )}
      </div>

      {showVideo ? (
        <div className="relative pt-[56.25%] rounded-lg overflow-hidden bg-gray-100">
          <iframe
            src={videoUrl}
            className="absolute inset-0 w-full h-full"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      ) : (
        <button
          onClick={() => setShowVideo(true)}
          className="w-full py-12 border-2 border-dashed border-gray-300 rounded-lg hover:border-blue-500 hover:bg-blue-50 transition-colors flex flex-col items-center justify-center"
        >
          <Video className="w-8 h-8 text-blue-600 mb-2" />
          <span className="text-blue-600 font-medium">Watch Video Guide</span>
        </button>
      )}
    </div>
  );
}