import React, { useState, useEffect } from 'react';
import { Save, RefreshCcw, Link as LinkIcon, Eye, EyeOff } from 'lucide-react';
import { ref, onValue, update } from 'firebase/database';
import { database } from '../../../lib/firebase';
import toast from 'react-hot-toast';

interface RatesSettings {
  section_title: string;
  buy_rate_label: string;
  sell_rate_label: string;
  show_buy_rate: boolean;
  show_sell_rate: boolean;
  manual_rates: boolean;
  manual_buy_rate: number;
  manual_sell_rate: number;
  api_source: string;
  api_key: string;
}

export default function LiveRates() {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [settings, setSettings] = useState<RatesSettings>({
    section_title: 'Best USDT Rates in Pakistan',
    buy_rate_label: 'Buy Rate',
    sell_rate_label: 'Sell Rate',
    show_buy_rate: true,
    show_sell_rate: true,
    manual_rates: false,
    manual_buy_rate: 285.50,
    manual_sell_rate: 284.50,
    api_source: 'exchangerate-api.com',
    api_key: 'fa47175a9f3fa91907fa7f8d'
  });

  useEffect(() => {
    const unsubscribe = subscribeToSettings();
    return () => unsubscribe();
  }, []);

  const subscribeToSettings = () => {
    const settingsRef = ref(database, 'rates_settings');
    return onValue(settingsRef, (snapshot) => {
      try {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setSettings(data);
        }
      } catch (error) {
        console.error('Error fetching rates settings:', error);
        toast.error('Failed to load rates settings');
      } finally {
        setLoading(false);
      }
    }, (error) => {
      console.error('Error fetching rates settings:', error);
      toast.error('Failed to load rates settings');
      setLoading(false);
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSaving(true);

    try {
      const settingsRef = ref(database, 'rates_settings');
      await update(settingsRef, {
        ...settings,
        updated_at: new Date().toISOString()
      });
      toast.success('Settings saved successfully');
    } catch (error) {
      console.error('Error saving settings:', error);
      toast.error('Failed to save settings');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="animate-pulse">
        <div className="h-8 bg-gray-200 rounded w-1/4 mb-8"></div>
        <div className="space-y-4">
          {[...Array(3)].map((_, i) => (
            <div key={i} className="h-16 bg-gray-200 rounded"></div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">Live Rates Section</h1>
        <button
          onClick={handleSubmit}
          disabled={saving}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
        >
          {saving ? (
            <>
              <RefreshCcw className="w-5 h-5 mr-2 animate-spin" />
              Saving...
            </>
          ) : (
            <>
              <Save className="w-5 h-5 mr-2" />
              Save Changes
            </>
          )}
        </button>
      </div>

      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Section Title
            </label>
            <input
              type="text"
              value={settings.section_title}
              onChange={(e) => setSettings({ ...settings, section_title: e.target.value })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Buy Rate Label
              </label>
              <input
                type="text"
                value={settings.buy_rate_label}
                onChange={(e) => setSettings({ ...settings, buy_rate_label: e.target.value })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Sell Rate Label
              </label>
              <input
                type="text"
                value={settings.sell_rate_label}
                onChange={(e) => setSettings({ ...settings, sell_rate_label: e.target.value })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
              <div>
                <label className="font-medium text-gray-900">Show Buy Rate</label>
                <p className="text-sm text-gray-500">Display buy rate on the website</p>
              </div>
              <button
                onClick={() => setSettings({ ...settings, show_buy_rate: !settings.show_buy_rate })}
                className="text-gray-600 hover:text-gray-900"
              >
                {settings.show_buy_rate ? (
                  <Eye className="w-5 h-5" />
                ) : (
                  <EyeOff className="w-5 h-5" />
                )}
              </button>
            </div>
            <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
              <div>
                <label className="font-medium text-gray-900">Show Sell Rate</label>
                <p className="text-sm text-gray-500">Display sell rate on the website</p>
              </div>
              <button
                onClick={() => setSettings({ ...settings, show_sell_rate: !settings.show_sell_rate })}
                className="text-gray-600 hover:text-gray-900"
              >
                {settings.show_sell_rate ? (
                  <Eye className="w-5 h-5" />
                ) : (
                  <EyeOff className="w-5 h-5" />
                )}
              </button>
            </div>
          </div>

          <div className="border-t border-gray-200 pt-6">
            <div className="flex items-center justify-between mb-6">
              <div>
                <h3 className="text-lg font-medium text-gray-900">Rate Source</h3>
                <p className="text-sm text-gray-500">Choose between manual rates or API</p>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={settings.manual_rates}
                  onChange={(e) => setSettings({ ...settings, manual_rates: e.target.checked })}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                <span className="ml-3 text-sm font-medium text-gray-900">
                  {settings.manual_rates ? 'Manual Rates' : 'API Rates'}
                </span>
              </label>
            </div>

            {settings.manual_rates ? (
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Manual Buy Rate (PKR)
                  </label>
                  <input
                    type="number"
                    step="0.01"
                    value={settings.manual_buy_rate}
                    onChange={(e) => setSettings({ ...settings, manual_buy_rate: parseFloat(e.target.value) })}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Manual Sell Rate (PKR)
                  </label>
                  <input
                    type="number"
                    step="0.01"
                    value={settings.manual_sell_rate}
                    onChange={(e) => setSettings({ ...settings, manual_sell_rate: parseFloat(e.target.value) })}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
            ) : (
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    API Source
                  </label>
                  <div className="flex items-center space-x-2">
                    <input
                      type="text"
                      value={settings.api_source}
                      onChange={(e) => setSettings({ ...settings, api_source: e.target.value })}
                      className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    <a
                      href={`https://${settings.api_source}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="p-2 text-blue-600 hover:text-blue-800"
                    >
                      <LinkIcon className="w-5 h-5" />
                    </a>
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    API Key
                  </label>
                  <input
                    type="password"
                    value={settings.api_key}
                    onChange={(e) => setSettings({ ...settings, api_key: e.target.value })}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}