import React from 'react';
import { Wand2, Info } from 'lucide-react';
import type { SystemSettings } from '../../../types';

interface OpenAISettingsProps {
  settings: SystemSettings;
  onSettingsChange: (settings: SystemSettings) => void;
}

export default function OpenAISettings({ settings, onSettingsChange }: OpenAISettingsProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h2 className="text-lg font-semibold mb-4 flex items-center">
        <Wand2 className="w-5 h-5 mr-2 text-blue-600" />
        OpenAI Settings
      </h2>

      <div className="mb-6 bg-blue-50 border border-blue-200 rounded-lg p-4">
        <div className="flex items-start">
          <Info className="w-5 h-5 text-blue-600 mt-0.5 mr-3 flex-shrink-0" />
          <div className="text-sm text-blue-700">
            <p className="font-medium mb-1">How to set up OpenAI integration:</p>
            <ol className="list-decimal list-inside space-y-1">
              <li>Create an account at <a href="https://platform.openai.com" target="_blank" rel="noopener noreferrer" className="underline">platform.openai.com</a></li>
              <li>Go to API Keys section</li>
              <li>Create a new API key</li>
              <li>Copy and paste the API key below</li>
            </ol>
          </div>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          OpenAI API Key
        </label>
        <input
          type="password"
          value={settings.openai_api_key || ''}
          onChange={(e) => onSettingsChange({ ...settings, openai_api_key: e.target.value })}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="sk-..."
        />
        <p className="mt-1 text-sm text-gray-500">
          Your API key will be used for blog post generation and other AI features.
        </p>
      </div>
    </div>
  );
}