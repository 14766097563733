import React, { useState, useEffect } from 'react';
import { Save, Plus, Trash2, Edit2, X, HelpCircle } from 'lucide-react';
import { ref, onValue, push, update, remove } from 'firebase/database';
import { database } from '../../../lib/firebase';
import toast from 'react-hot-toast';

interface FAQ {
  id: string;
  question: string;
  answer: string;
  sort_order: number;
  is_active: boolean;
}

export default function FAQ() {
  const [faqs, setFaqs] = useState<FAQ[]>([]);
  const [loading, setLoading] = useState(true);
  const [showEditor, setShowEditor] = useState(false);
  const [editingFaq, setEditingFaq] = useState<FAQ | null>(null);
  const [formData, setFormData] = useState({
    question: '',
    answer: '',
    is_active: true
  });

  useEffect(() => {
    const unsubscribe = subscribeToFaqs();
    return () => unsubscribe();
  }, []);

  const subscribeToFaqs = () => {
    const faqsRef = ref(database, 'faqs');
    return onValue(faqsRef, (snapshot) => {
      try {
        if (snapshot.exists()) {
          const faqsData = snapshot.val();
          const faqsArray = Object.entries(faqsData).map(([id, faq]: [string, any]) => ({
            id,
            ...faq
          })).sort((a, b) => a.sort_order - b.sort_order);
          setFaqs(faqsArray);
        } else {
          setFaqs([]);
        }
      } catch (error) {
        console.error('Error processing FAQs:', error);
        toast.error('Failed to load FAQs');
      } finally {
        setLoading(false);
      }
    }, (error) => {
      console.error('Error fetching FAQs:', error);
      toast.error('Failed to load FAQs');
      setLoading(false);
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (editingFaq) {
        const faqRef = ref(database, `faqs/${editingFaq.id}`);
        await update(faqRef, {
          ...formData,
          updated_at: new Date().toISOString()
        });
        toast.success('FAQ updated successfully');
      } else {
        const faqsRef = ref(database, 'faqs');
        const newFaqRef = push(faqsRef);
        await update(newFaqRef, {
          ...formData,
          sort_order: faqs.length,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        });
        toast.success('FAQ added successfully');
      }

      setShowEditor(false);
      resetForm();
    } catch (error: any) {
      console.error('Error saving FAQ:', error);
      toast.error(error.message || 'Failed to save FAQ');
    }
  };

  const handleDelete = async (id: string) => {
    if (!confirm('Are you sure you want to delete this FAQ?')) return;

    try {
      const faqRef = ref(database, `faqs/${id}`);
      await remove(faqRef);
      
      // Update sort order of remaining FAQs
      const remainingFaqs = faqs.filter(f => f.id !== id);
      const updates: { [key: string]: any } = {};
      remainingFaqs.forEach((faq, index) => {
        updates[`faqs/${faq.id}/sort_order`] = index;
      });
      
      if (Object.keys(updates).length > 0) {
        await update(ref(database), updates);
      }

      toast.success('FAQ deleted successfully');
    } catch (error) {
      console.error('Error deleting FAQ:', error);
      toast.error('Failed to delete FAQ');
    }
  };

  const handleEdit = (faq: FAQ) => {
    setEditingFaq(faq);
    setFormData({
      question: faq.question,
      answer: faq.answer,
      is_active: faq.is_active
    });
    setShowEditor(true);
  };

  const resetForm = () => {
    setFormData({
      question: '',
      answer: '',
      is_active: true
    });
    setEditingFaq(null);
  };

  const handleToggleActive = async (faq: FAQ) => {
    try {
      const faqRef = ref(database, `faqs/${faq.id}`);
      await update(faqRef, {
        is_active: !faq.is_active,
        updated_at: new Date().toISOString()
      });
      toast.success(`FAQ ${faq.is_active ? 'disabled' : 'enabled'} successfully`);
    } catch (error) {
      console.error('Error toggling FAQ status:', error);
      toast.error('Failed to update FAQ status');
    }
  };

  if (loading) {
    return (
      <div className="animate-pulse">
        <div className="h-8 bg-gray-200 rounded w-1/4 mb-8"></div>
        <div className="space-y-4">
          {[...Array(3)].map((_, i) => (
            <div key={i} className="h-24 bg-gray-200 rounded"></div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">FAQ Management</h1>
        <button
          onClick={() => {
            resetForm();
            setShowEditor(true);
          }}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          <Plus className="w-5 h-5 mr-2" />
          Add FAQ
        </button>
      </div>

      <div className="grid gap-6">
        {faqs.map((faq) => (
          <div
            key={faq.id}
            className={`bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow ${
              !faq.is_active ? 'opacity-60' : ''
            }`}
          >
            <div className="flex justify-between items-start">
              <div className="flex-1">
                <h2 className="text-xl font-semibold text-gray-900 mb-2 flex items-center">
                  <HelpCircle className="w-5 h-5 text-blue-600 mr-2" />
                  {faq.question}
                </h2>
                <p className="text-gray-600 whitespace-pre-wrap">{faq.answer}</p>
              </div>
              <div className="flex items-center space-x-2 ml-4">
                <button
                  onClick={() => handleToggleActive(faq)}
                  className={`px-3 py-1 rounded-lg text-sm font-medium ${
                    faq.is_active
                      ? 'bg-green-100 text-green-800'
                      : 'bg-gray-100 text-gray-800'
                  }`}
                >
                  {faq.is_active ? 'Active' : 'Inactive'}
                </button>
                <button
                  onClick={() => handleEdit(faq)}
                  className="p-2 text-blue-600 hover:bg-blue-50 rounded-lg"
                >
                  <Edit2 className="w-5 h-5" />
                </button>
                <button
                  onClick={() => handleDelete(faq.id)}
                  className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        ))}

        {faqs.length === 0 && (
          <div className="text-center py-12 bg-white rounded-lg shadow-sm">
            <HelpCircle className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">No FAQs Found</h3>
            <p className="text-gray-600">
              Add your first FAQ to help users understand your service better.
            </p>
          </div>
        )}
      </div>

      {showEditor && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center overflow-y-auto">
          <div className="bg-white rounded-lg max-w-2xl w-full p-6 m-4">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">
                {editingFaq ? 'Edit FAQ' : 'Add FAQ'}
              </h2>
              <button
                onClick={() => setShowEditor(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="w-6 h-6" />
              </button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Question
                </label>
                <input
                  type="text"
                  value={formData.question}
                  onChange={(e) => setFormData({ ...formData, question: e.target.value })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Answer
                </label>
                <textarea
                  value={formData.answer}
                  onChange={(e) => setFormData({ ...formData, answer: e.target.value })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  rows={4}
                  required
                />
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="is_active"
                  checked={formData.is_active}
                  onChange={(e) => setFormData({ ...formData, is_active: e.target.checked })}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label htmlFor="is_active" className="ml-2 block text-sm text-gray-900">
                  Active
                </label>
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setShowEditor(false)}
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  <Save className="w-5 h-5 mr-2" />
                  {editingFaq ? 'Update FAQ' : 'Add FAQ'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}