import React from 'react';
import { LinkIcon } from 'lucide-react';
import type { SystemSettings } from '../../../types';

interface SocialMediaProps {
  settings: SystemSettings;
  onSettingsChange: (settings: SystemSettings) => void;
}

export default function SocialMedia({ settings, onSettingsChange }: SocialMediaProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h2 className="text-lg font-semibold mb-4 flex items-center">
        <LinkIcon className="w-5 h-5 mr-2 text-blue-600" />
        Social Media Links
      </h2>
      <div className="space-y-6">
        {/* Facebook */}
        <div className="flex items-start space-x-4">
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Facebook URL
            </label>
            <input
              type="url"
              value={settings.social_media.facebook.url}
              onChange={(e) => onSettingsChange({
                ...settings,
                social_media: {
                  ...settings.social_media,
                  facebook: {
                    ...settings.social_media.facebook,
                    url: e.target.value
                  }
                }
              })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="https://facebook.com/your-page"
            />
          </div>
          <div className="pt-7">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={settings.social_media.facebook.enabled}
                onChange={(e) => onSettingsChange({
                  ...settings,
                  social_media: {
                    ...settings.social_media,
                    facebook: {
                      ...settings.social_media.facebook,
                      enabled: e.target.checked
                    }
                  }
                })}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
          </div>
        </div>

        {/* Twitter */}
        <div className="flex items-start space-x-4">
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              X (Twitter) URL
            </label>
            <input
              type="url"
              value={settings.social_media.twitter.url}
              onChange={(e) => onSettingsChange({
                ...settings,
                social_media: {
                  ...settings.social_media,
                  twitter: {
                    ...settings.social_media.twitter,
                    url: e.target.value
                  }
                }
              })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="https://twitter.com/your-handle"
            />
          </div>
          <div className="pt-7">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={settings.social_media.twitter.enabled}
                onChange={(e) => onSettingsChange({
                  ...settings,
                  social_media: {
                    ...settings.social_media,
                    twitter: {
                      ...settings.social_media.twitter,
                      enabled: e.target.checked
                    }
                  }
                })}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
          </div>
        </div>

        {/* TikTok */}
        <div className="flex items-start space-x-4">
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              TikTok URL
            </label>
            <input
              type="url"
              value={settings.social_media.tiktok.url}
              onChange={(e) => onSettingsChange({
                ...settings,
                social_media: {
                  ...settings.social_media,
                  tiktok: {
                    ...settings.social_media.tiktok,
                    url: e.target.value
                  }
                }
              })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="https://tiktok.com/@your-handle"
            />
          </div>
          <div className="pt-7">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={settings.social_media.tiktok.enabled}
                onChange={(e) => onSettingsChange({
                  ...settings,
                  social_media: {
                    ...settings.social_media,
                    tiktok: {
                      ...settings.social_media.tiktok,
                      enabled: e.target.checked
                    }
                  }
                })}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
          </div>
        </div>

        {/* Instagram */}
        <div className="flex items-start space-x-4">
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Instagram URL
            </label>
            <input
              type="url"
              value={settings.social_media.instagram.url}
              onChange={(e) => onSettingsChange({
                ...settings,
                social_media: {
                  ...settings.social_media,
                  instagram: {
                    ...settings.social_media.instagram,
                    url: e.target.value
                  }
                }
              })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="https://instagram.com/your-handle"
            />
          </div>
          <div className="pt-7">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={settings.social_media.instagram.enabled}
                onChange={(e) => onSettingsChange({
                  ...settings,
                  social_media: {
                    ...settings.social_media,
                    instagram: {
                      ...settings.social_media.instagram,
                      enabled: e.target.checked
                    }
                  }
                })}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}