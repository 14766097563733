import React from 'react';
import { Tag, Info } from 'lucide-react';
import type { SystemSettings } from '../../../types';

interface GoogleTagManagerProps {
  settings: SystemSettings;
  onSettingsChange: (settings: SystemSettings) => void;
}

export default function GoogleTagManager({ settings, onSettingsChange }: GoogleTagManagerProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h2 className="text-lg font-semibold mb-4 flex items-center">
        <Tag className="w-5 h-5 mr-2 text-blue-600" />
        Google Tag Manager
      </h2>

      <div className="mb-6 bg-blue-50 border border-blue-200 rounded-lg p-4">
        <div className="flex items-start">
          <Info className="w-5 h-5 text-blue-600 mt-0.5 mr-3 flex-shrink-0" />
          <div className="text-sm text-blue-700">
            <p className="font-medium mb-1">How to set up Google Tag Manager:</p>
            <ol className="list-decimal list-inside space-y-1">
              <li>Create a GTM account at <a href="https://tagmanager.google.com" target="_blank" rel="noopener noreferrer" className="underline">tagmanager.google.com</a></li>
              <li>Create a new container for your website</li>
              <li>Copy the Container ID (format: GTM-XXXXXX)</li>
              <li>Paste the Container ID below</li>
            </ol>
          </div>
        </div>
      </div>

      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            GTM Container ID
          </label>
          <input
            type="text"
            value={settings.gtm_id || ''}
            onChange={(e) => onSettingsChange({ ...settings, gtm_id: e.target.value })}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="GTM-XXXXXX"
            pattern="GTM-[A-Z0-9]+"
          />
          <p className="mt-1 text-sm text-gray-500">
            Enter your GTM Container ID (e.g., GTM-XXXXXX)
          </p>
        </div>

        <div className="flex items-center justify-between">
          <div>
            <label className="font-medium text-gray-900">Enable GTM</label>
            <p className="text-sm text-gray-500">
              Enable Google Tag Manager tracking
            </p>
          </div>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={settings.gtm_enabled || false}
              onChange={(e) => onSettingsChange({ ...settings, gtm_enabled: e.target.checked })}
              className="sr-only peer"
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
          </label>
        </div>

        {settings.gtm_enabled && !settings.gtm_id && (
          <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
            <div className="flex">
              <Info className="w-5 h-5 text-yellow-600 mt-0.5 mr-3 flex-shrink-0" />
              <p className="text-sm text-yellow-700">
                Please enter a valid GTM Container ID to enable tracking.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}