import React from 'react';
import { Copy, QrCode, Wallet, CreditCard, ChevronRight } from 'lucide-react';
import type { PaymentMethod } from '../../types';
import toast from 'react-hot-toast';

interface PaymentMethodSelectorProps {
  paymentMethods: PaymentMethod[];
  selectedMethod: PaymentMethod | null;
  onMethodSelect: (method: PaymentMethod) => void;
  disabled?: boolean;
}

export default function PaymentMethodSelector({
  paymentMethods,
  selectedMethod,
  onMethodSelect,
  disabled = false
}: PaymentMethodSelectorProps) {
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success('Copied to clipboard!');
  };

  const getMethodIcon = (type: PaymentMethod['type']) => {
    switch (type) {
      case 'qr':
        return <QrCode className="w-5 h-5" />;
      case 'address':
        return <Wallet className="w-5 h-5" />;
      case 'payid':
      case 'binance_pay':
        return <CreditCard className="w-5 h-5" />;
    }
  };

  if (!selectedMethod) {
    return (
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Select Payment Method
        </label>
        <div className="space-y-3">
          {paymentMethods.map((method) => (
            <button
              key={method.id}
              onClick={() => !disabled && onMethodSelect(method)}
              className={`w-full p-4 border rounded-lg text-left transition-colors flex items-center justify-between ${
                disabled
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:border-blue-300 hover:bg-blue-50'
              }`}
              disabled={disabled}
            >
              <div className="flex items-center space-x-3">
                {getMethodIcon(method.type)}
                <div>
                  <p className="font-medium">{method.name}</p>
                  {method.network && (
                    <span className="text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded-full">
                      {method.network}
                    </span>
                  )}
                </div>
              </div>
              <ChevronRight className="w-5 h-5 text-gray-400" />
            </button>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="mb-6">
      <label className="block text-sm font-medium text-gray-700 mb-2">
        Payment Method
      </label>
      <div className="p-4 border rounded-lg">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            {getMethodIcon(selectedMethod.type)}
            <div>
              <p className="font-medium">{selectedMethod.name}</p>
              {selectedMethod.type === 'qr' ? (
                <div className="mt-2">
                  <img 
                    src={selectedMethod.value}
                    alt="QR Code"
                    className="max-w-[200px] rounded-lg"
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.style.display = 'none';
                      toast.error('Failed to load QR code');
                    }}
                  />
                </div>
              ) : (
                <p className="text-sm text-gray-600 flex items-center">
                  {selectedMethod.value}
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      copyToClipboard(selectedMethod.value);
                    }}
                    className="ml-2 text-blue-600 hover:text-blue-800"
                    disabled={disabled}
                  >
                    <Copy className="w-4 h-4" />
                  </button>
                </p>
              )}
              {selectedMethod.network && (
                <span className="text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded-full">
                  {selectedMethod.network}
                </span>
              )}
            </div>
          </div>
          <button
            onClick={() => onMethodSelect(null)}
            className="text-blue-600 hover:text-blue-800 text-sm"
            disabled={disabled}
          >
            Change
          </button>
        </div>
      </div>
    </div>
  );
}