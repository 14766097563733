import React from 'react';
import CryptoTicker from './components/CryptoTicker';
import Hero from './components/home/Hero';
import LiveRates from './components/home/LiveRates';
import SecurityWarning from './components/home/SecurityWarning';
import HowItWorks from './components/home/HowItWorks';
import ComparisonSection from './components/home/ComparisonSection';
import FAQ from './components/home/FAQ';
import Testimonials from './components/home/Testimonials';
import Footer from './components/Footer';
import UrduSection from './components/home/UrduSection';

function App() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-50 via-white to-indigo-50">
      <CryptoTicker />
      <Hero />
      <LiveRates />
      <UrduSection />
      <SecurityWarning />
      <HowItWorks />
      <ComparisonSection />
      <FAQ />
      <Testimonials />
      <Footer />
    </div>
  );
}

export default App;