import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  LayoutDashboard,
  Settings,
  Users,
  DollarSign,
  Bell,
  BarChart3,
  Shield,
  LogOut,
  PenTool,
  Menu,
  X
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';

interface SidebarItem {
  name: string;
  icon: React.ElementType;
  path: string;
  children?: { name: string; path: string }[];
}

export default function AdminLayout({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  
  const sidebarItems: SidebarItem[] = [
    { name: 'Dashboard', icon: LayoutDashboard, path: '/admin/dashboard' },
    { name: 'Users', icon: Users, path: '/admin/users' },
    { name: 'Transactions', icon: DollarSign, path: '/admin/transactions' },
    { 
      name: 'Content', 
      icon: PenTool, 
      path: '/admin/content',
      children: [
        { name: 'Blog Posts', path: '/admin/content/blog' },
        { name: 'Hero Section', path: '/admin/content/hero' },
        { name: 'Live Rates', path: '/admin/content/rates' },
        { name: 'Urdu Section', path: '/admin/content/urdu' },
        { name: 'Security Warning', path: '/admin/content/security' },
        { name: 'How It Works', path: '/admin/content/how-it-works' },
        { name: 'Comparison', path: '/admin/content/comparison' },
        { name: 'FAQ', path: '/admin/content/faq' },
        { name: 'Testimonials', path: '/admin/content/testimonials' },
        { name: 'Sitemap', path: '/admin/content/sitemap' }
      ]
    },
    { name: 'Notifications', icon: Bell, path: '/admin/notifications' },
    { name: 'Analytics', icon: BarChart3, path: '/admin/analytics' },
    { name: 'Settings', icon: Settings, path: '/admin/settings' },
    { name: 'Security', icon: Shield, path: '/admin/security' }
  ];

  const isActivePath = (path: string) => location.pathname === path;
  const isActiveParent = (item: SidebarItem) => 
    item.children?.some(child => location.pathname === child.path) || isActivePath(item.path);

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/', { replace: true });
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Mobile Header */}
      <div className="md:hidden bg-white shadow-sm p-4 flex items-center justify-between">
        <h1 className="text-xl font-bold text-blue-600">Admin Panel</h1>
        <button onClick={toggleSidebar} className="p-2">
          {isSidebarOpen ? (
            <X className="w-6 h-6 text-gray-600" />
          ) : (
            <Menu className="w-6 h-6 text-gray-600" />
          )}
        </button>
      </div>

      <div className="flex">
        {/* Sidebar */}
        <div className={`
          fixed inset-0 z-40 transform transition-transform duration-300 ease-in-out
          md:relative md:translate-x-0 md:w-64 bg-white shadow-sm min-h-screen flex flex-col
          ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}
        `}>
          <div className="p-4 hidden md:block">
            <h1 className="text-xl font-bold text-blue-600">Admin Panel</h1>
          </div>
          <nav className="flex-1 mt-4 overflow-y-auto">
            {sidebarItems.map((item) => (
              <div key={item.path}>
                <Link
                  to={item.path}
                  onClick={closeSidebar}
                  className={`flex items-center px-4 py-3 text-sm ${
                    isActiveParent(item)
                      ? 'bg-blue-50 text-blue-600 border-r-4 border-blue-600'
                      : 'text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  <item.icon className="w-5 h-5 mr-3" />
                  {item.name}
                </Link>
                {item.children && isActiveParent(item) && (
                  <div className="ml-8 border-l border-gray-200">
                    {item.children.map((child) => (
                      <Link
                        key={child.path}
                        to={child.path}
                        onClick={closeSidebar}
                        className={`block px-4 py-2 text-sm ${
                          isActivePath(child.path)
                            ? 'text-blue-600 font-medium'
                            : 'text-gray-600 hover:text-gray-900'
                        }`}
                      >
                        {child.name}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </nav>
          {/* Sign Out Button */}
          <div className="p-4 border-t border-gray-200">
            <button
              onClick={handleSignOut}
              className="flex items-center w-full px-4 py-2 text-sm text-red-600 hover:bg-red-50 rounded-lg transition-colors"
            >
              <LogOut className="w-5 h-5 mr-3" />
              Sign Out
            </button>
          </div>
        </div>

        {/* Overlay */}
        {isSidebarOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-30 md:hidden"
            onClick={closeSidebar}
          ></div>
        )}

        {/* Main content */}
        <div className="flex-1 overflow-x-hidden">
          <div className="p-4 md:p-8">{children}</div>
        </div>
      </div>
    </div>
  );
}