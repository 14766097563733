import React, { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../../lib/firebase';

interface Testimonial {
  id: string;
  name: string;
  text: string;
  role: string;
  location: string;
  sort_order: number;
  is_active: boolean;
}

export default function Testimonials() {
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const faqsRef = ref(database, 'testimonials');
    const unsubscribe = onValue(faqsRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const testimonialsArray = Object.entries(data).map(([id, testimonial]: [string, any]) => ({
          id,
          ...testimonial
        })).filter(testimonial => testimonial.is_active)
          .sort((a, b) => a.sort_order - b.sort_order);
        setTestimonials(testimonialsArray);
      }
      setLoading(false);
    }, (error) => {
      console.error('Error fetching testimonials:', error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div className="py-20 bg-gradient-to-b from-white to-indigo-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="animate-pulse">
            <div className="h-8 bg-gray-200 rounded w-1/4 mx-auto mb-8"></div>
            <div className="grid md:grid-cols-3 gap-8">
              {[...Array(3)].map((_, i) => (
                <div key={i} className="h-48 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="py-20 bg-gradient-to-b from-white to-indigo-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-12 text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-indigo-600">
          Trusted by Thousands in Pakistan
        </h2>
        
        {testimonials.length > 3 ? (
          <div className="relative overflow-hidden">
            <div className="testimonial-slider">
              <div className="testimonial-track flex animate-testimonial-slide">
                {/* First set of testimonials */}
                {testimonials.map((testimonial) => (
                  <div
                    key={testimonial.id}
                    className="testimonial-item flex-shrink-0 w-full md:w-1/3 px-4"
                  >
                    <div className="bg-white p-8 rounded-xl shadow-sm border border-gray-100 hover:shadow-lg transition-shadow h-full">
                      <p className="text-gray-600 mb-6 italic">"{testimonial.text}"</p>
                      <div>
                        <p className="font-semibold text-blue-900">{testimonial.name}</p>
                        <p className="text-sm text-blue-600">{testimonial.role}</p>
                        <p className="text-sm text-gray-500">{testimonial.location}</p>
                      </div>
                    </div>
                  </div>
                ))}
                {/* Duplicate testimonials for seamless loop */}
                {testimonials.map((testimonial) => (
                  <div
                    key={`${testimonial.id}-duplicate`}
                    className="testimonial-item flex-shrink-0 w-full md:w-1/3 px-4"
                  >
                    <div className="bg-white p-8 rounded-xl shadow-sm border border-gray-100 hover:shadow-lg transition-shadow h-full">
                      <p className="text-gray-600 mb-6 italic">"{testimonial.text}"</p>
                      <div>
                        <p className="font-semibold text-blue-900">{testimonial.name}</p>
                        <p className="text-sm text-blue-600">{testimonial.role}</p>
                        <p className="text-sm text-gray-500">{testimonial.location}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="grid md:grid-cols-3 gap-8">
            {testimonials.map((testimonial) => (
              <div
                key={testimonial.id}
                className="bg-white p-8 rounded-xl shadow-sm border border-gray-100 hover:shadow-lg transition-shadow"
              >
                <p className="text-gray-600 mb-6 italic">"{testimonial.text}"</p>
                <div>
                  <p className="font-semibold text-blue-900">{testimonial.name}</p>
                  <p className="text-sm text-blue-600">{testimonial.role}</p>
                  <p className="text-sm text-gray-500">{testimonial.location}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}