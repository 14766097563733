import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { HelmetProvider } from 'react-helmet-async';
import App from './App.tsx';
import User from './User.tsx';
import Profile from './Profile.tsx';
import Contact from './pages/Contact.tsx';
import Confirmation from './Confirmation.tsx';
import Blog from './Blog.tsx';
import BlogPost from './BlogPost.tsx';
import Terms from './pages/legal/Terms.tsx';
import Privacy from './pages/legal/Privacy.tsx';
import AML from './pages/legal/AML.tsx';
import Dashboard from './pages/admin/Dashboard.tsx';
import Users from './pages/admin/Users.tsx';
import Transactions from './pages/admin/Transactions.tsx';
import Settings from './pages/admin/Settings.tsx';
import Analytics from './pages/admin/Analytics.tsx';
import Notifications from './pages/admin/Notifications.tsx';
import Security from './pages/admin/Security.tsx';
import ContentLayout from './pages/admin/content/ContentLayout.tsx';
import BlogPosts from './pages/admin/content/BlogPosts.tsx';
import FAQ from './pages/admin/content/FAQ.tsx';
import HeroSection from './pages/admin/content/HeroSection.tsx';
import LiveRates from './pages/admin/content/LiveRates.tsx';
import UrduSection from './pages/admin/content/UrduSection.tsx';
import SecurityWarning from './pages/admin/content/SecurityWarning.tsx';
import HowItWorks from './pages/admin/content/HowItWorks.tsx';
import Comparison from './pages/admin/content/Comparison.tsx';
import Testimonials from './pages/admin/content/Testimonials.tsx';
import Sitemap from './pages/admin/content/Sitemap.tsx';
import { RateProvider } from './context/RateContext';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute.tsx';
import Header from './components/Header.tsx';
import GTMScript from './components/GTMScript.tsx';
import './index.css';

// Only enable security measures in production with a more permissive approach
if (process.env.NODE_ENV === 'production') {
  // Detect DevTools in a less aggressive way
  const detectDevTools = () => {
    // Only check on non-admin pages
    if (!window.location.pathname.startsWith('/admin')) {
      // Check if console is being used extensively
      let consoleUsageCount = 0;
      const originalConsole = { ...console };
      
      Object.keys(console).forEach(key => {
        console[key] = (...args) => {
          consoleUsageCount++;
          // Only take action if console is being used suspiciously
          if (consoleUsageCount > 50) {
            console = originalConsole; // Restore original console
            consoleUsageCount = 0;
          }
          originalConsole[key](...args);
        };
      });
    }
  };

  // Run checks less frequently and only on non-mobile devices
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  if (!isMobile) {
    setInterval(detectDevTools, 5000);
  }

  // Prevent right-click only on sensitive elements
  document.addEventListener('contextmenu', (e) => {
    const target = e.target as HTMLElement;
    if (target.matches('img, video, canvas')) {
      e.preventDefault();
    }
  });

  // Only prevent certain keyboard shortcuts
  document.addEventListener('keydown', (e) => {
    // Allow most keyboard shortcuts
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const isCtrlOrCmd = isMac ? e.metaKey : e.ctrlKey;

    // Only prevent specific harmful shortcuts
    if (
      (isCtrlOrCmd && e.shiftKey && e.key === 'I') || // Inspect Element
      (isCtrlOrCmd && e.shiftKey && e.key === 'C') || // Console
      (isCtrlOrCmd && e.key === 'U') // View Source
    ) {
      e.preventDefault();
    }
  });
}

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthProvider>
        <Header />
        <App />
      </AuthProvider>
    ),
  },
  {
    path: "/contact",
    element: (
      <AuthProvider>
        <Header />
        <Contact />
      </AuthProvider>
    ),
  },
  {
    path: "/user",
    element: (
      <AuthProvider>
        <ProtectedRoute>
          <Header />
          <User />
        </ProtectedRoute>
      </AuthProvider>
    ),
  },
  {
    path: "/profile",
    element: (
      <AuthProvider>
        <ProtectedRoute>
          <Header />
          <Profile />
        </ProtectedRoute>
      </AuthProvider>
    ),
  },
  {
    path: "/legal/terms",
    element: (
      <AuthProvider>
        <Header />
        <Terms />
      </AuthProvider>
    ),
  },
  {
    path: "/legal/privacy",
    element: (
      <AuthProvider>
        <Header />
        <Privacy />
      </AuthProvider>
    ),
  },
  {
    path: "/legal/aml",
    element: (
      <AuthProvider>
        <Header />
        <AML />
      </AuthProvider>
    ),
  },
  {
    path: "/admin",
    element: (
      <AuthProvider>
        <ProtectedRoute requireAdmin={true}>
          <Dashboard />
        </ProtectedRoute>
      </AuthProvider>
    ),
  },
  {
    path: "/admin/dashboard",
    element: (
      <AuthProvider>
        <ProtectedRoute requireAdmin={true}>
          <Dashboard />
        </ProtectedRoute>
      </AuthProvider>
    ),
  },
  {
    path: "/admin/users",
    element: (
      <AuthProvider>
        <ProtectedRoute requireAdmin={true}>
          <Users />
        </ProtectedRoute>
      </AuthProvider>
    ),
  },
  {
    path: "/admin/transactions",
    element: (
      <AuthProvider>
        <ProtectedRoute requireAdmin={true}>
          <Transactions />
        </ProtectedRoute>
      </AuthProvider>
    ),
  },
  {
    path: "/admin/notifications",
    element: (
      <AuthProvider>
        <ProtectedRoute requireAdmin={true}>
          <Notifications />
        </ProtectedRoute>
      </AuthProvider>
    ),
  },
  {
    path: "/admin/analytics",
    element: (
      <AuthProvider>
        <ProtectedRoute requireAdmin={true}>
          <Analytics />
        </ProtectedRoute>
      </AuthProvider>
    ),
  },
  {
    path: "/admin/settings",
    element: (
      <AuthProvider>
        <ProtectedRoute requireAdmin={true}>
          <Settings />
        </ProtectedRoute>
      </AuthProvider>
    ),
  },
  {
    path: "/admin/security",
    element: (
      <AuthProvider>
        <ProtectedRoute requireAdmin={true}>
          <Security />
        </ProtectedRoute>
      </AuthProvider>
    ),
  },
  {
    path: "/admin/content",
    element: (
      <AuthProvider>
        <ProtectedRoute requireAdmin={true}>
          <ContentLayout />
        </ProtectedRoute>
      </AuthProvider>
    ),
    children: [
      {
        path: "blog",
        element: <BlogPosts />
      },
      {
        path: "hero",
        element: <HeroSection />
      },
      {
        path: "rates",
        element: <LiveRates />
      },
      {
        path: "urdu",
        element: <UrduSection />
      },
      {
        path: "security",
        element: <SecurityWarning />
      },
      {
        path: "how-it-works",
        element: <HowItWorks />
      },
      {
        path: "comparison",
        element: <Comparison />
      },
      {
        path: "faq",
        element: <FAQ />
      },
      {
        path: "testimonials",
        element: <Testimonials />
      },
      {
        path: "sitemap",
        element: <Sitemap />
      }
    ]
  },
  {
    path: "/confirmation",
    element: (
      <AuthProvider>
        <ProtectedRoute>
          <Header />
          <Confirmation />
        </ProtectedRoute>
      </AuthProvider>
    ),
  },
  {
    path: "/blog",
    element: (
      <AuthProvider>
        <Header />
        <Blog />
      </AuthProvider>
    ),
  },
  {
    path: "/blog/:id",
    element: (
      <AuthProvider>
        <Header />
        <BlogPost />
      </AuthProvider>
    ),
  },
]);

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <HelmetProvider>
      <RateProvider>
        <GTMScript />
        <RouterProvider router={router} />
        <Toaster position="top-right" />
      </RateProvider>
    </HelmetProvider>
  </StrictMode>
);
