import React, { useState, useEffect } from 'react';
import { useRate } from '../../context/RateContext';
import { ArrowRight, AlertCircle, ArrowRightCircle } from 'lucide-react';
import AuthModal from '../auth/AuthModal';
import toast from 'react-hot-toast';

interface ExchangeCalculatorProps {
  user: any;
  navigate: (path: string) => void;
}

export default function ExchangeCalculator({ user, navigate }: ExchangeCalculatorProps) {
  const { currentRate, isOnline } = useRate();
  const [amount, setAmount] = useState<string>('');
  const [convertedAmount, setConvertedAmount] = useState<string>('');
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [currentStep, setCurrentStep] = useState<'amount' | 'proceed'>('amount');

  useEffect(() => {
    if (amount) {
      const numAmount = parseFloat(amount);
      if (!isNaN(numAmount) && numAmount > 0) {
        setConvertedAmount((numAmount * currentRate).toFixed(2));
        setCurrentStep('proceed');
      } else {
        setConvertedAmount('');
        setCurrentStep('amount');
      }
    } else {
      setConvertedAmount('');
      setCurrentStep('amount');
    }
  }, [amount, currentRate]);

  const handleAmountChange = (value: string) => {
    // Allow only numbers and one decimal point
    if (value === '' || /^\d*\.?\d{0,2}$/.test(value)) {
      setAmount(value);
    }
  };

  const handleProceedClick = () => {
    if (!amount || isNaN(parseFloat(amount)) || parseFloat(amount) <= 0) {
      toast.error('Please enter a valid amount');
      return;
    }

    if (user) {
      navigate('/user');
    } else {
      setShowAuthModal(true);
    }
  };

  return (
    <>
      <div className="max-w-md mx-auto bg-white rounded-2xl shadow-xl p-8 backdrop-blur-lg bg-white/90 mb-8">
        <div className="space-y-6">
          <div className="guide-step relative" data-step="amount">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              USDT Amount
            </label>
            <div className="relative">
              <input
                type="text"
                value={amount}
                onChange={(e) => handleAmountChange(e.target.value)}
                className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 shadow-sm pr-24"
                placeholder="Enter amount"
              />
              <div className="absolute inset-y-0 right-0 flex items-center">
                <span className="px-4 text-gray-500 font-medium">USDT</span>
                {currentStep === 'amount' && (
                  <div className="guide-arrow visible">
                    <ArrowRightCircle className="w-6 h-6 text-blue-600 mr-2" />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-center">
            <div className="w-8 h-8 bg-blue-50 rounded-full flex items-center justify-center">
              <ArrowRight className="w-5 h-5 text-blue-600" />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              PKR Amount
            </label>
            <div className="relative">
              <input
                type="text"
                value={convertedAmount}
                readOnly
                className="w-full px-4 py-3 bg-gray-50 border border-gray-200 rounded-lg shadow-sm pr-24"
                placeholder="Converted amount"
              />
              <span className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-500 font-medium">
                PKR
              </span>
            </div>
          </div>

          <div className="bg-blue-50 rounded-lg p-4 flex items-start space-x-2">
            <AlertCircle className="w-5 h-5 text-blue-600 flex-shrink-0 mt-0.5" />
            <p className="text-sm text-blue-700">
              Current rate: 1 USDT = {currentRate.toFixed(2)} PKR
            </p>
          </div>

          <div className="guide-step relative" data-step="proceed">
            <div className="relative">
              <button
                onClick={handleProceedClick}
                disabled={!amount || isNaN(parseFloat(amount)) || parseFloat(amount) <= 0}
                className={`w-full py-3 px-4 rounded-lg font-semibold flex items-center justify-center transition-all duration-200 btn-reflection ${
                  !amount || isNaN(parseFloat(amount)) || parseFloat(amount) <= 0
                    ? 'bg-gray-400 text-white cursor-not-allowed'
                    : 'bg-gradient-to-r from-red-600 to-red-700 hover:from-red-700 hover:to-red-800 text-white shadow-lg hover:shadow-xl'
                }`}
              >
                {isOnline ? (
                  <>
                    Proceed to Sell
                    <ArrowRight className="w-5 h-5 ml-2" />
                  </>
                ) : (
                  'Service Currently Offline'
                )}
              </button>
              {currentStep === 'proceed' && amount && !isNaN(parseFloat(amount)) && parseFloat(amount) > 0 && (
                <div className="guide-arrow visible absolute -right-8 top-1/2 transform -translate-y-1/2">
                  <ArrowRightCircle className="w-6 h-6 text-blue-600" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <AuthModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        defaultTab="login"
      />
    </>
  );
}