import React, { useState, useEffect, useRef } from 'react';
import { Send, Loader, BadgeCheck, Image as ImageIcon, X, Check, Info } from 'lucide-react';
import { format } from 'date-fns';
import { ref, onValue, push, update, query, orderByChild } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { database, storage } from '../../lib/firebase';
import { useAuth } from '../../context/AuthContext';
import type { ChatMessage } from '../../types';
import toast from 'react-hot-toast';

interface ChatWindowProps {
  conversationId: string;
  recipientName: string;
  onClose?: () => void;
}

export default function ChatWindow({ conversationId, recipientName, onClose }: ChatWindowProps) {
  const { user, profile } = useAuth();
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [conversationStatus, setConversationStatus] = useState<'active' | 'completed'>('active');
  const [completedBy, setCompletedBy] = useState<string | null>(null);
  const [completedAt, setCompletedAt] = useState<string | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const isAdmin = profile?.role === 'admin';

  useEffect(() => {
    if (!user || !conversationId) return;

    // Subscribe to conversation status
    const conversationRef = ref(database, `chat_conversations/${conversationId}`);
    const conversationUnsubscribe = onValue(conversationRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setConversationStatus(data.status || 'active');
        setCompletedBy(data.completed_by || null);
        setCompletedAt(data.completed_at || null);
      }
    });

    // Subscribe to messages
    const messagesRef = ref(database, `chat_messages/${conversationId}`);
    const messagesQuery = query(messagesRef, orderByChild('created_at'));
    
    const messagesUnsubscribe = onValue(messagesQuery, (snapshot) => {
      try {
        if (snapshot.exists()) {
          const messagesData = snapshot.val();
          const messagesArray = Object.entries(messagesData)
            .map(([id, message]: [string, any]) => ({
              id,
              ...message
            }))
            .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
          
          setMessages(messagesArray);
          
          // Mark messages as read if they're not from the current user
          messagesArray.forEach(message => {
            if (message.sender_id !== user.uid && !message.is_read) {
              markMessageAsRead(message.id);
            }
          });
        } else {
          setMessages([]);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error processing messages:', error);
        toast.error('Failed to load messages');
        setLoading(false);
      }
    });

    return () => {
      conversationUnsubscribe();
      messagesUnsubscribe();
    };
  }, [user, conversationId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, conversationStatus]);

  const markMessageAsRead = async (messageId: string) => {
    try {
      const messageRef = ref(database, `chat_messages/${conversationId}/${messageId}`);
      await update(messageRef, { is_read: true });
    } catch (error) {
      console.error('Error marking message as read:', error);
    }
  };

  const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Validate file
    if (!file.type.startsWith('image/')) {
      toast.error('Please select an image file');
      return;
    }

    if (file.size > 5 * 1024 * 1024) { // 5MB limit
      toast.error('Image size should be less than 5MB');
      return;
    }

    setSelectedImage(file);

    // Create preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const removeImage = () => {
    setSelectedImage(null);
    setImagePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const sendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || (!newMessage.trim() && !selectedImage) || sending) return;

    setSending(true);
    try {
      const messagesRef = ref(database, `chat_messages/${conversationId}`);
      let imageUrl = '';

      // Upload image if selected
      if (selectedImage) {
        const imageRef = storageRef(storage, `chat_images/${conversationId}/${Date.now()}_${selectedImage.name}`);
        await uploadBytes(imageRef, selectedImage);
        imageUrl = await getDownloadURL(imageRef);
      }
      
      const messageData = {
        conversation_id: conversationId,
        sender_id: user.uid,
        content: newMessage.trim(),
        image_url: imageUrl,
        is_read: false,
        created_at: new Date().toISOString()
      };

      await push(messagesRef, messageData);
      
      // Update conversation's last message and status
      const conversationRef = ref(database, `chat_conversations/${conversationId}`);
      await update(conversationRef, {
        last_message: imageUrl ? '📷 Image' : newMessage.trim(),
        last_message_at: new Date().toISOString(),
        status: 'active' // Reset status to active when new message is sent
      });

      setNewMessage('');
      setSelectedImage(null);
      setImagePreview(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Failed to send message');
    } finally {
      setSending(false);
    }
  };

  const markConversationComplete = async () => {
    if (!user) return;

    try {
      const conversationRef = ref(database, `chat_conversations/${conversationId}`);
      const completionTime = new Date().toISOString();
      
      // Update conversation status
      await update(conversationRef, {
        status: 'completed',
        completed_at: completionTime,
        completed_by: user.uid
      });

      // Add completion message
      const messagesRef = ref(database, `chat_messages/${conversationId}`);
      await push(messagesRef, {
        conversation_id: conversationId,
        sender_id: 'system',
        content: '🏁 Conversation marked as complete by support team',
        is_read: true,
        created_at: completionTime,
        is_system_message: true
      });

      toast.success('Conversation marked as complete');
    } catch (error) {
      console.error('Error completing conversation:', error);
      toast.error('Failed to complete conversation');
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loader className="w-6 h-6 text-blue-600 animate-spin" />
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      {/* Chat Header */}
      <div className="flex items-center justify-between px-4 py-3 bg-blue-600 text-white">
        <div className="flex items-center">
          <h3 className="font-semibold">{recipientName}</h3>
          {recipientName === 'Support' && (
            <BadgeCheck className="w-5 h-5 ml-1 text-yellow-300" />
          )}
        </div>
        <div className="flex items-center space-x-2">
          {isAdmin && conversationStatus === 'active' && (
            <button
              onClick={markConversationComplete}
              className="flex items-center px-2 py-1 bg-green-500 text-white rounded hover:bg-green-600"
              title="Mark conversation as complete"
            >
              <Check className="w-4 h-4 mr-1" />
              Complete
            </button>
          )}
          {onClose && (
            <button
              onClick={onClose}
              className="text-white hover:text-blue-100"
            >
              ×
            </button>
          )}
        </div>
      </div>

      {/* Messages */}
      <div className="flex-1 overflow-y-auto p-4 space-y-4 bg-gray-50">
        {messages.map((message) => {
          const isOwnMessage = message.sender_id === user?.uid;
          const isSystemMessage = message.is_system_message;

          if (isSystemMessage) {
            return (
              <div key={message.id} className="flex justify-center">
                <div className="bg-blue-50 text-blue-800 px-4 py-2 rounded-lg flex items-center">
                  <Info className="w-4 h-4 mr-2" />
                  {message.content}
                </div>
              </div>
            );
          }

          return (
            <div
              key={message.id}
              className={`flex ${isOwnMessage ? 'justify-end' : 'justify-start'}`}
            >
              <div
                className={`max-w-[70%] rounded-lg px-4 py-2 ${
                  isOwnMessage
                    ? 'bg-blue-600 text-white'
                    : 'bg-white text-gray-900 shadow-sm'
                }`}
              >
                {message.image_url && (
                  <a 
                    href={message.image_url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="block mb-2"
                  >
                    <img 
                      src={message.image_url} 
                      alt="Shared" 
                      className="max-w-full rounded-lg"
                      loading="lazy"
                    />
                  </a>
                )}
                {message.content && (
                  <p className="break-words">{message.content}</p>
                )}
                <p className={`text-xs mt-1 ${
                  isOwnMessage ? 'text-blue-100' : 'text-gray-500'
                }`}>
                  {format(new Date(message.created_at), 'HH:mm')}
                </p>
              </div>
            </div>
          );
        })}

        {conversationStatus === 'completed' && completedAt && (
          <div className="flex justify-center">
            <div className="bg-green-50 text-green-800 px-4 py-2 rounded-lg text-sm">
              <p className="flex items-center">
                <Check className="w-4 h-4 mr-2" />
                Conversation completed on {format(new Date(completedAt), 'MMM d, yyyy HH:mm')}
              </p>
            </div>
          </div>
        )}

        <div ref={messagesEndRef} />
      </div>

      {/* Message Input */}
      <form onSubmit={sendMessage} className="p-4 bg-white border-t">
        {imagePreview && (
          <div className="mb-4 relative inline-block">
            <img 
              src={imagePreview} 
              alt="Preview" 
              className="max-h-32 rounded-lg"
            />
            <button
              type="button"
              onClick={removeImage}
              className="absolute -top-2 -right-2 p-1 bg-red-100 text-red-600 rounded-full hover:bg-red-200"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        )}
        <div className="flex space-x-2">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type a message..."
            className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            disabled={sending}
          />
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleImageSelect}
            accept="image/*"
            className="hidden"
            disabled={sending}
          />
          <button
            type="button"
            onClick={() => fileInputRef.current?.click()}
            className="px-4 py-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-lg disabled:opacity-50"
            disabled={sending}
          >
            <ImageIcon className="w-5 h-5" />
          </button>
          <button
            type="submit"
            disabled={(!newMessage.trim() && !selectedImage) || sending}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {sending ? (
              <Loader className="w-5 h-5 animate-spin" />
            ) : (
              <Send className="w-5 h-5" />
            )}
          </button>
        </div>
        {conversationStatus === 'completed' && (
          <div className="mt-2 bg-green-50 text-green-800 px-4 py-2 rounded-lg text-sm">
            <p className="flex items-center">
              <Info className="w-4 h-4 mr-2" />
              This conversation has been marked as complete. You can continue chatting or start a new conversation if needed.
            </p>
          </div>
        )}
      </form>
    </div>
  );
}