import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import ExchangeCalculator from './ExchangeCalculator';
import SupportedBanks from './SupportedBanks';
import { ref, onValue } from 'firebase/database';
import { database } from '../../lib/firebase';
import { Loader } from 'lucide-react';

interface HeroContent {
  main_heading: string;
  sub_heading: string;
  background_image: string;
}

export default function Hero() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [content, setContent] = useState<HeroContent>({
    main_heading: '#1 Trusted Platform to Sell USDT in Pakistan',
    sub_heading: 'Convert your USDT to PKR instantly with Pakistan\'s most secure and reliable crypto exchange platform',
    background_image: 'https://images.unsplash.com/photo-1639322537228-f710d846310a'
  });

  useEffect(() => {
    let mounted = true;
    const contentRef = ref(database, 'content/hero');
    
    const unsubscribe = onValue(contentRef, (snapshot) => {
      if (!mounted) return;
      
      try {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setContent({
            main_heading: data.main_heading || content.main_heading,
            sub_heading: data.sub_heading || content.sub_heading,
            background_image: data.background_image || content.background_image
          });
        }
        setError(null);
      } catch (error: any) {
        console.error('Error processing hero content:', error);
        setError('Failed to load content');
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    }, (error) => {
      console.error('Error subscribing to hero content:', error);
      if (mounted) {
        setError('Failed to load content');
        setLoading(false);
      }
    });

    // Set a timeout to prevent infinite loading
    const timeout = setTimeout(() => {
      if (mounted) {
        setLoading(false);
        if (!content) {
          setError('Content loading timeout');
        }
      }
    }, 5000);

    return () => {
      mounted = false;
      unsubscribe();
      clearTimeout(timeout);
    };
  }, []);

  if (loading) {
    return (
      <div className="min-h-[600px] bg-gray-50 flex items-center justify-center">
        <Loader className="w-8 h-8 text-blue-600 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-[600px] bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <p className="text-red-600 mb-4">{error}</p>
          <button 
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="relative overflow-hidden">
      <div className="absolute inset-0 z-0">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 mix-blend-multiply" />
        <picture>
          {/* Provide multiple sizes for responsive images */}
          <source
            media="(min-width: 1024px)"
            srcSet={`${content.background_image}?auto=format&fit=crop&w=1920&q=80 1920w`}
            sizes="1920px"
            type="image/jpeg"
          />
          <source
            media="(min-width: 768px)"
            srcSet={`${content.background_image}?auto=format&fit=crop&w=1280&q=80 1280w`}
            sizes="1280px"
            type="image/jpeg"
          />
          <source
            srcSet={`${content.background_image}?auto=format&fit=crop&w=800&q=80 800w`}
            sizes="800px"
            type="image/jpeg"
          />
          <img
            src={`${content.background_image}?auto=format&fit=crop&w=800&q=80`}
            alt="Secure USDT Exchange in Pakistan"
            className="w-full h-full object-cover opacity-20"
            width="800"
            height="600"
            loading="eager"
            decoding="async"
            fetchpriority="high"
          />
        </picture>
      </div>
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-24">
        <div className="text-center">
          <h1 className="text-5xl md:text-7xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600 mb-6">
            {content.main_heading}
          </h1>
          <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
            {content.sub_heading}
          </p>
          <div className="flex flex-wrap justify-center gap-4 mb-12">
            <div className="flex items-center space-x-2 bg-gradient-to-r from-green-50 to-emerald-50 px-6 py-3 rounded-full shadow-sm">
              <span className="text-green-800 font-medium">24/7 Support</span>
            </div>
            <div className="flex items-center space-x-2 bg-gradient-to-r from-blue-50 to-indigo-50 px-6 py-3 rounded-full shadow-sm">
              <span className="text-blue-800 font-medium">100% Secure</span>
            </div>
            <div className="flex items-center space-x-2 bg-gradient-to-r from-purple-50 to-pink-50 px-6 py-3 rounded-full shadow-sm">
              <span className="text-purple-800 font-medium">Instant Transfers</span>
            </div>
          </div>

          <ExchangeCalculator user={user} navigate={navigate} />
          <SupportedBanks />
        </div>
      </div>
    </div>
  );
}