import React from 'react';
import { Outlet } from 'react-router-dom';
import AdminLayout from '../../../components/admin/AdminLayout';

export default function ContentLayout() {
  return (
    <AdminLayout>
      <Outlet />
    </AdminLayout>
  );
}