import React, { createContext, useContext, useEffect, useState } from 'react';
import { 
  User,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  sendPasswordResetEmail
} from 'firebase/auth';
import { ref, set, get, update, onValue } from 'firebase/database';
import { auth, database, trackUserActivity } from '../lib/firebase';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

interface Profile {
  id: string;
  full_name: string | null;
  phone_number: string | null;
  kyc_status: 'pending' | 'verified' | 'rejected';
  role: 'user' | 'admin';
  is_active: boolean;
  created_at: string;
  updated_at: string;
}

interface AuthContextType {
  user: User | null;
  profile: Profile | null;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, fullName: string, phoneNumber: string) => Promise<void>;
  signOut: () => Promise<void>;
  updateProfile: (data: Partial<Profile>) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const navigate = useNavigate();

  const fetchProfile = async (userId: string) => {
    try {
      const userRef = ref(database, `users/${userId}`);
      const snapshot = await get(userRef);

      let userProfile: Profile;
      
      if (snapshot.exists()) {
        const userData = snapshot.val();
        userProfile = {
          id: userId,
          full_name: userData.full_name,
          phone_number: userData.phone_number,
          kyc_status: userData.kyc_status || 'pending',
          role: userData.role || 'user',
          is_active: userData.is_active !== false,
          created_at: userData.created_at || new Date().toISOString(),
          updated_at: userData.updated_at || new Date().toISOString()
        };
      } else {
        // Create default profile
        userProfile = {
          id: userId,
          full_name: user?.email?.split('@')[0] || null,
          phone_number: null,
          kyc_status: 'pending',
          role: 'user',
          is_active: true,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        };
        
        await set(userRef, userProfile);
      }

      // Force admin role for nav@gmail.com
      if (user?.email === 'nav@gmail.com' && userProfile.role !== 'admin') {
        userProfile.role = 'admin';
        await update(userRef, { role: 'admin' });
      }

      setProfile(userProfile);

      await trackUserActivity(
        'profile_fetch',
        userId,
        'success',
        { timestamp: new Date().toISOString() }
      );
    } catch (error) {
      console.error('Error fetching profile:', error);
      setProfile(null);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      
      if (user) {
        await fetchProfile(user.uid);
        
        await trackUserActivity(
          'auth_state_change',
          user.uid,
          'success',
          { event: 'SIGNED_IN', timestamp: new Date().toISOString() }
        );
      } else {
        setProfile(null);
      }
      
      setLoading(false);
      setInitialLoad(false);
    });

    return () => unsubscribe();
  }, []);

  const signIn = async (email: string, password: string) => {
    try {
      setLoading(true);
      const result = await signInWithEmailAndPassword(auth, email, password);
      
      // Special handling for nav@gmail.com
      if (email === 'nav@gmail.com') {
        const userRef = ref(database, `users/${result.user.uid}`);
        await update(userRef, {
          role: 'admin',
          updated_at: new Date().toISOString()
        });
      }
      
      await fetchProfile(result.user.uid);
      toast.success('Successfully signed in');
      
      await trackUserActivity(
        'sign_in',
        result.user.uid,
        'success',
        { timestamp: new Date().toISOString() }
      );

      // Redirect admin users to admin dashboard
      if (email === 'nav@gmail.com') {
        navigate('/admin/dashboard');
      } else {
        navigate('/user');
      }
    } catch (error: any) {
      await trackUserActivity(
        'sign_in_failed',
        null,
        'failed',
        { 
          error: error.message,
          timestamp: new Date().toISOString()
        }
      );
      
      toast.error(error.message || 'Failed to sign in');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const signUp = async (email: string, password: string, fullName: string, phoneNumber: string) => {
    try {
      setLoading(true);
      const result = await createUserWithEmailAndPassword(auth, email, password);
      
      const userRef = ref(database, `users/${result.user.uid}`);
      const userProfile = {
        id: result.user.uid,
        full_name: fullName,
        email: email,
        phone_number: phoneNumber,
        kyc_status: 'pending',
        role: email === 'nav@gmail.com' ? 'admin' : 'user',
        is_active: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      };
      
      await set(userRef, userProfile);
      setProfile(userProfile);

      await trackUserActivity(
        'sign_up',
        result.user.uid,
        'success',
        { timestamp: new Date().toISOString() }
      );

      toast.success('Successfully signed up!');
    } catch (error: any) {
      await trackUserActivity(
        'sign_up_failed',
        null,
        'failed',
        { 
          error: error.message,
          timestamp: new Date().toISOString()
        }
      );
      
      toast.error(error.message || 'Failed to sign up');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const signOut = async () => {
    try {
      setLoading(true);
      if (user) {
        await trackUserActivity(
          'sign_out',
          user.uid,
          'success',
          { timestamp: new Date().toISOString() }
        );
      }

      await firebaseSignOut(auth);
      
      // Clear all storage except environment variables
      Object.keys(localStorage).forEach(key => {
        if (!key.startsWith('VITE_')) {
          localStorage.removeItem(key);
        }
      });
      sessionStorage.clear();

      // Navigate to home page
      navigate('/', { replace: true });
      
      toast.success('Successfully signed out');
    } catch (error: any) {
      console.error('Error signing out:', error);
      
      // Force cleanup even if error occurs
      setUser(null);
      setProfile(null);
      Object.keys(localStorage).forEach(key => {
        if (!key.startsWith('VITE_')) {
          localStorage.removeItem(key);
        }
      });
      sessionStorage.clear();
      navigate('/', { replace: true });
    } finally {
      setLoading(false);
    }
  };

  const updateProfile = async (data: Partial<Profile>) => {
    try {
      setLoading(true);
      if (!user?.uid) throw new Error('No user logged in');

      const userRef = ref(database, `users/${user.uid}`);
      const updates = {
        ...data,
        updated_at: new Date().toISOString()
      };
      
      await update(userRef, updates);
      await fetchProfile(user.uid);
      
      await trackUserActivity(
        'profile_update',
        user.uid,
        'success',
        { 
          updated_fields: Object.keys(data),
          timestamp: new Date().toISOString()
        }
      );

      toast.success('Profile updated successfully');
    } catch (error: any) {
      toast.error(error.message || 'Failed to update profile');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (email: string) => {
    try {
      setLoading(true);
      await sendPasswordResetEmail(auth, email);
      toast.success('Password reset instructions sent to your email');
    } catch (error: any) {
      toast.error(error.message || 'Failed to send reset instructions');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  if (initialLoad) {
    return null;
  }

  const value = {
    user,
    profile,
    loading,
    signIn,
    signUp,
    signOut,
    updateProfile,
    resetPassword
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}