import React, { useState, useEffect } from 'react';
import { Save, Plus, Trash2, Edit2, X, Shield } from 'lucide-react';
import { ref, onValue, push, update, remove } from 'firebase/database';
import { database } from '../../../lib/firebase';
import toast from 'react-hot-toast';

interface SecurityWarning {
  id: string;
  title: string;
  description: string;
  icon: string;
  category: 'scams' | 'security' | 'protection';
  sort_order: number;
  is_active: boolean;
}

export default function SecurityWarning() {
  const [warnings, setWarnings] = useState<SecurityWarning[]>([]);
  const [loading, setLoading] = useState(true);
  const [showEditor, setShowEditor] = useState(false);
  const [editingWarning, setEditingWarning] = useState<SecurityWarning | null>(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    icon: '',
    category: 'scams' as SecurityWarning['category'],
    is_active: true
  });

  useEffect(() => {
    const unsubscribe = subscribeToWarnings();
    return () => unsubscribe();
  }, []);

  const subscribeToWarnings = () => {
    const warningsRef = ref(database, 'security_warnings');
    return onValue(warningsRef, (snapshot) => {
      try {
        if (snapshot.exists()) {
          const warningsData = snapshot.val();
          const warningsArray = Object.entries(warningsData).map(([id, warning]: [string, any]) => ({
            id,
            ...warning
          })).sort((a, b) => a.sort_order - b.sort_order);
          setWarnings(warningsArray);
        } else {
          setWarnings([]);
        }
      } catch (error) {
        console.error('Error processing security warnings:', error);
        toast.error('Failed to load security warnings');
      } finally {
        setLoading(false);
      }
    }, (error) => {
      console.error('Error fetching security warnings:', error);
      toast.error('Failed to load security warnings');
      setLoading(false);
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (editingWarning) {
        const warningRef = ref(database, `security_warnings/${editingWarning.id}`);
        await update(warningRef, {
          ...formData,
          updated_at: new Date().toISOString()
        });
        toast.success('Security warning updated successfully');
      } else {
        const warningsRef = ref(database, 'security_warnings');
        const newWarningRef = push(warningsRef);
        await update(newWarningRef, {
          ...formData,
          sort_order: warnings.length,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        });
        toast.success('Security warning added successfully');
      }

      setShowEditor(false);
      resetForm();
    } catch (error: any) {
      console.error('Error saving security warning:', error);
      toast.error(error.message || 'Failed to save security warning');
    }
  };

  const handleDelete = async (id: string) => {
    if (!confirm('Are you sure you want to delete this security warning?')) return;

    try {
      const warningRef = ref(database, `security_warnings/${id}`);
      await remove(warningRef);
      
      // Update sort order of remaining warnings
      const remainingWarnings = warnings.filter(w => w.id !== id);
      const updates: { [key: string]: any } = {};
      remainingWarnings.forEach((warning, index) => {
        updates[`security_warnings/${warning.id}/sort_order`] = index;
      });
      
      if (Object.keys(updates).length > 0) {
        await update(ref(database), updates);
      }

      toast.success('Security warning deleted successfully');
    } catch (error) {
      console.error('Error deleting security warning:', error);
      toast.error('Failed to delete security warning');
    }
  };

  const handleEdit = (warning: SecurityWarning) => {
    setEditingWarning(warning);
    setFormData({
      title: warning.title,
      description: warning.description,
      icon: warning.icon,
      category: warning.category,
      is_active: warning.is_active
    });
    setShowEditor(true);
  };

  const resetForm = () => {
    setFormData({
      title: '',
      description: '',
      icon: '',
      category: 'scams',
      is_active: true
    });
    setEditingWarning(null);
  };

  const handleToggleActive = async (warning: SecurityWarning) => {
    try {
      const warningRef = ref(database, `security_warnings/${warning.id}`);
      await update(warningRef, {
        is_active: !warning.is_active,
        updated_at: new Date().toISOString()
      });
      toast.success(`Security warning ${warning.is_active ? 'disabled' : 'enabled'} successfully`);
    } catch (error) {
      console.error('Error toggling security warning status:', error);
      toast.error('Failed to update security warning status');
    }
  };

  if (loading) {
    return (
      <div className="animate-pulse">
        <div className="h-8 bg-gray-200 rounded w-1/4 mb-8"></div>
        <div className="space-y-4">
          {[...Array(3)].map((_, i) => (
            <div key={i} className="h-24 bg-gray-200 rounded"></div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">Security Warning Management</h1>
        <button
          onClick={() => {
            resetForm();
            setShowEditor(true);
          }}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          <Plus className="w-5 h-5 mr-2" />
          Add Warning
        </button>
      </div>

      <div className="grid gap-6">
        {warnings.map((warning) => (
          <div
            key={warning.id}
            className={`bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow ${
              !warning.is_active ? 'opacity-60' : ''
            }`}
          >
            <div className="flex justify-between items-start">
              <div className="flex-1">
                <h2 className="text-xl font-semibold text-gray-900 mb-2 flex items-center">
                  <Shield className="w-5 h-5 text-red-500 mr-2" />
                  {warning.title}
                </h2>
                <p className="text-gray-600 mb-4">{warning.description}</p>
                <div className="flex items-center space-x-4 text-sm text-gray-500">
                  <span className="font-medium">Icon: {warning.icon}</span>
                  <span className="font-medium capitalize">Category: {warning.category}</span>
                </div>
              </div>
              <div className="flex items-center space-x-2 ml-4">
                <button
                  onClick={() => handleToggleActive(warning)}
                  className={`px-3 py-1 rounded-lg text-sm font-medium ${
                    warning.is_active
                      ? 'bg-green-100 text-green-800'
                      : 'bg-gray-100 text-gray-800'
                  }`}
                >
                  {warning.is_active ? 'Active' : 'Inactive'}
                </button>
                <button
                  onClick={() => handleEdit(warning)}
                  className="p-2 text-blue-600 hover:bg-blue-50 rounded-lg"
                >
                  <Edit2 className="w-5 h-5" />
                </button>
                <button
                  onClick={() => handleDelete(warning.id)}
                  className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        ))}

        {warnings.length === 0 && (
          <div className="text-center py-12 bg-white rounded-lg shadow-sm">
            <Shield className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">No Security Warnings Found</h3>
            <p className="text-gray-600">
              Add your first security warning to help users stay safe.
            </p>
          </div>
        )}
      </div>

      {showEditor && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center overflow-y-auto">
          <div className="bg-white rounded-lg max-w-2xl w-full p-6 m-4">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">
                {editingWarning ? 'Edit Security Warning' : 'Add Security Warning'}
              </h2>
              <button
                onClick={() => setShowEditor(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="w-6 h-6" />
              </button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Title
                </label>
                <input
                  type="text"
                  value={formData.title}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Description (comma-separated list)
                </label>
                <textarea
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  rows={4}
                  required
                  placeholder="Item 1, Item 2, Item 3"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Icon Name
                </label>
                <input
                  type="text"
                  value={formData.icon}
                  onChange={(e) => setFormData({ ...formData, icon: e.target.value })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                  placeholder="e.g., Shield, AlertTriangle, UserCheck"
                />
                <p className="mt-1 text-sm text-gray-500">
                  Enter a valid Lucide icon name. See <a href="https://lucide.dev/icons" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">Lucide Icons</a> for available icons.
                </p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Category
                </label>
                <select
                  value={formData.category}
                  onChange={(e) => setFormData({ ...formData, category: e.target.value as SecurityWarning['category'] })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="scams">Scams</option>
                  <option value="security">Security</option>
                  <option value="protection">Protection</option>
                </select>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="is_active"
                  checked={formData.is_active}
                  onChange={(e) => setFormData({ ...formData, is_active: e.target.checked })}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label htmlFor="is_active" className="ml-2 block text-sm text-gray-900">
                  Active
                </label>
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setShowEditor(false)}
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  <Save className="w-5 h-5 mr-2" />
                  {editingWarning ? 'Update Warning' : 'Add Warning'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}