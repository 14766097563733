import React, { useState } from 'react';
import { DollarSign, Plus, Trash2, QrCode, Wallet, CreditCard, X, Settings as SettingsIcon } from 'lucide-react';
import type { PaymentMethod, SystemSettings } from '../../../types';
import toast from 'react-hot-toast';

interface PaymentMethodsProps {
  settings: SystemSettings;
  onSettingsChange: (settings: SystemSettings) => void;
}

export default function PaymentMethods({ settings, onSettingsChange }: PaymentMethodsProps) {
  const [showModal, setShowModal] = useState(false);
  const [editingMethod, setEditingMethod] = useState<PaymentMethod | null>(null);
  const [newMethod, setNewMethod] = useState<Partial<PaymentMethod>>({
    name: '',
    type: 'address',
    value: '',
    network: '',
    isActive: true
  });

  const handleAddMethod = () => {
    if (!newMethod.name || !newMethod.type || !newMethod.value) {
      toast.error('Please fill in all required fields');
      return;
    }

    const method: PaymentMethod = {
      id: Date.now().toString(),
      name: newMethod.name,
      type: newMethod.type as PaymentMethod['type'],
      value: newMethod.value,
      network: newMethod.network,
      isActive: true,
      createdAt: new Date().toISOString()
    };

    onSettingsChange({
      ...settings,
      payment_methods: [...(settings.payment_methods || []), method]
    });

    resetForm();
  };

  const handleUpdateMethod = () => {
    if (!editingMethod || !newMethod.name || !newMethod.type || !newMethod.value) {
      toast.error('Please fill in all required fields');
      return;
    }

    const updatedMethod: PaymentMethod = {
      ...editingMethod,
      name: newMethod.name,
      type: newMethod.type as PaymentMethod['type'],
      value: newMethod.value,
      network: newMethod.network,
      isActive: newMethod.isActive ?? true
    };

    onSettingsChange({
      ...settings,
      payment_methods: settings.payment_methods.map(method =>
        method.id === editingMethod.id ? updatedMethod : method
      )
    });

    resetForm();
  };

  const handleDeleteMethod = (id: string) => {
    if (!confirm('Are you sure you want to delete this payment method?')) return;

    onSettingsChange({
      ...settings,
      payment_methods: settings.payment_methods.filter(method => method.id !== id)
    });
  };

  const toggleMethodStatus = (id: string) => {
    onSettingsChange({
      ...settings,
      payment_methods: settings.payment_methods.map(method =>
        method.id === id ? { ...method, isActive: !method.isActive } : method
      )
    });
  };

  const resetForm = () => {
    setNewMethod({
      name: '',
      type: 'address',
      value: '',
      network: '',
      isActive: true
    });
    setEditingMethod(null);
    setShowModal(false);
  };

  const getMethodIcon = (type: PaymentMethod['type']) => {
    switch (type) {
      case 'qr':
        return <QrCode className="w-5 h-5 text-gray-600" />;
      case 'address':
        return <Wallet className="w-5 h-5 text-gray-600" />;
      case 'payid':
      case 'binance_pay':
        return <CreditCard className="w-5 h-5 text-gray-600" />;
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold flex items-center">
          <DollarSign className="w-5 h-5 mr-2 text-blue-600" />
          Payment Methods
        </h2>
        <button
          type="button"
          onClick={() => {
            resetForm();
            setShowModal(true);
          }}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          <Plus className="w-5 h-5 mr-2" />
          Add Method
        </button>
      </div>

      <div className="space-y-4">
        {settings.payment_methods?.map((method) => (
          <div
            key={method.id}
            className={`p-4 bg-gray-50 rounded-lg ${!method.isActive ? 'opacity-60' : ''}`}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                {getMethodIcon(method.type)}
                <div>
                  <h3 className="font-medium">{method.name}</h3>
                  <p className="text-sm text-gray-600">{method.value}</p>
                  {method.network && (
                    <span className="text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded-full">
                      {method.network}
                    </span>
                  )}
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <button
                  type="button"
                  onClick={() => toggleMethodStatus(method.id)}
                  className={`px-3 py-1 rounded-lg text-sm font-medium ${
                    method.isActive
                      ? 'bg-green-100 text-green-800'
                      : 'bg-gray-100 text-gray-800'
                  }`}
                >
                  {method.isActive ? 'Active' : 'Inactive'}
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setEditingMethod(method);
                    setNewMethod({
                      name: method.name,
                      type: method.type,
                      value: method.value,
                      network: method.network,
                      isActive: method.isActive
                    });
                    setShowModal(true);
                  }}
                  className="p-2 text-blue-600 hover:bg-blue-50 rounded-lg"
                >
                  <SettingsIcon className="w-5 h-5" />
                </button>
                <button
                  type="button"
                  onClick={() => handleDeleteMethod(method.id)}
                  className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        ))}

        {(!settings.payment_methods || settings.payment_methods.length === 0) && (
          <div className="text-center py-8">
            <DollarSign className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <p className="text-gray-500">No payment methods added yet</p>
          </div>
        )}
      </div>

      {/* Payment Method Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">
                {editingMethod ? 'Edit Payment Method' : 'Add Payment Method'}
              </h2>
              <button
                type="button"
                onClick={resetForm}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="w-6 h-6" />
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Method Name
                </label>
                <input
                  type="text"
                  value={newMethod.name}
                  onChange={(e) => setNewMethod({ ...newMethod, name: e.target.value })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="e.g., USDT TRC20"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Method Type
                </label>
                <select
                  value={newMethod.type}
                  onChange={(e) => setNewMethod({ ...newMethod, type: e.target.value as PaymentMethod['type'] })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="address">Wallet Address</option>
                  <option value="qr">QR Code</option>
                  <option value="payid">Pay ID</option>
                  <option value="binance_pay">Binance Pay</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {newMethod.type === 'qr' ? 'QR Code URL' : 'Value'}
                </label>
                <input
                  type="text"
                  value={newMethod.value}
                  onChange={(e) => setNewMethod({ ...newMethod, value: e.target.value })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder={newMethod.type === 'qr' ? 'Enter QR code URL' : 'Enter value'}
                  required
                />
                {newMethod.type === 'qr' && newMethod.value && (
                  <img
                    src={newMethod.value}
                    alt="QR Code Preview"
                    className="mt-2 max-w-[200px] mx-auto"
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.style.display = 'none';
                    }}
                  />
                )}
              </div>

              {newMethod.type === 'address' && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Network
                  </label>
                  <input
                    type="text"
                    value={newMethod.network}
                    onChange={(e) => setNewMethod({ ...newMethod, network: e.target.value })}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="e.g., TRC20, ERC20"
                  />
                </div>
              )}

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="is_active"
                  checked={newMethod.isActive}
                  onChange={(e) => setNewMethod({ ...newMethod, isActive: e.target.checked })}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label htmlFor="is_active" className="ml-2 block text-sm text-gray-900">
                  Active
                </label>
              </div>

              <div className="flex justify-end space-x-3 mt-6">
                <button
                  type="button"
                  onClick={resetForm}
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={editingMethod ? handleUpdateMethod : handleAddMethod}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  {editingMethod ? 'Update Method' : 'Add Method'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}