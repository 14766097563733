import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import { Save, RefreshCcw, AlertCircle } from 'lucide-react';
import { ref, onValue, update } from 'firebase/database';
import { database } from '../../lib/firebase';
import toast from 'react-hot-toast';
import type { SystemSettings } from '../../types';
import CompanyInfo from '../../components/admin/settings/CompanyInfo';
import SystemStatus from '../../components/admin/settings/SystemStatus';
import TransactionSettings from '../../components/admin/settings/TransactionSettings';
import SupportSettings from '../../components/admin/settings/SupportSettings';
import SocialMedia from '../../components/admin/settings/SocialMedia';
import GoogleTagManager from '../../components/admin/settings/GoogleTagManager';
import OpenAISettings from '../../components/admin/settings/OpenAISettings';
import VideoGuidance from '../../components/admin/settings/VideoGuidance';
import PaymentMethods from '../../components/admin/settings/PaymentMethods';

export default function Settings() {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [settings, setSettings] = useState<SystemSettings>({
    id: 1,
    maintenance_mode: false,
    min_transaction_amount: 100,
    max_transaction_amount: 100000,
    transaction_fee_enabled: false,
    transaction_fee_type: 'percentage',
    transaction_fee_percentage: 1,
    transaction_fee_flat: 1,
    email_notifications: true,
    support_email: 'support@example.com',
    support_phone: '+923001234567',
    support_hours: '24/7',
    kyc_required: true,
    auto_approve_verified_users: false,
    company_name: 'USDT Exchange',
    company_logo: '',
    company_favicon: '',
    company_description: 'Pakistan\'s most trusted USDT exchange platform',
    meta_title: 'USDT Exchange Pakistan - Buy & Sell USDT Securely',
    meta_description: 'Convert your USDT to PKR instantly with Pakistan\'s most secure and reliable crypto exchange platform',
    openai_api_key: '',
    payment_methods: [],
    social_media: {
      facebook: { url: '', enabled: false },
      twitter: { url: '', enabled: false },
      tiktok: { url: '', enabled: false },
      instagram: { url: '', enabled: false }
    },
    gtm_id: '',
    gtm_enabled: false,
    video_guidance_enabled: false,
    video_guidance_url: '',
    support_online: true
  });

  useEffect(() => {
    const unsubscribe = subscribeToSettings();
    return () => unsubscribe();
  }, []);

  const subscribeToSettings = () => {
    const settingsRef = ref(database, 'system_settings');
    return onValue(settingsRef, (snapshot) => {
      try {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setSettings(data);
        } else {
          // Initialize settings if they don't exist
          update(settingsRef, settings);
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
        setError('Failed to load settings. Please try again.');
        toast.error('Failed to load settings');
      } finally {
        setLoading(false);
      }
    }, (error) => {
      console.error('Error subscribing to settings:', error);
      setError('Failed to load settings. Please try again.');
      toast.error('Failed to load settings');
      setLoading(false);
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (saving) return;

    try {
      setSaving(true);
      setError(null);

      // Validate social media URLs
      const urlFields = [
        { name: 'Facebook', url: settings.social_media.facebook.url, enabled: settings.social_media.facebook.enabled },
        { name: 'Twitter', url: settings.social_media.twitter.url, enabled: settings.social_media.twitter.enabled },
        { name: 'TikTok', url: settings.social_media.tiktok.url, enabled: settings.social_media.tiktok.enabled },
        { name: 'Instagram', url: settings.social_media.instagram.url, enabled: settings.social_media.instagram.enabled }
      ];

      for (const field of urlFields) {
        if (field.enabled && field.url) {
          try {
            new URL(field.url);
          } catch (e) {
            throw new Error(`Please enter a valid URL for ${field.name}`);
          }
        }
      }

      const settingsRef = ref(database, 'system_settings');
      await update(settingsRef, {
        ...settings,
        updated_at: new Date().toISOString()
      });

      toast.success('Settings updated successfully');
    } catch (error: any) {
      console.error('Error saving settings:', error);
      setError(error.message || 'Failed to save settings');
      toast.error(error.message || 'Failed to save settings');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <AdminLayout>
        <div className="animate-pulse">
          <div className="h-8 bg-gray-200 rounded w-1/4 mb-8"></div>
          <div className="space-y-4">
            {[...Array(5)].map((_, i) => (
              <div key={i} className="h-16 bg-gray-200 rounded"></div>
            ))}
          </div>
        </div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <div className="space-y-8">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-gray-900">System Settings</h1>
          <button
            onClick={() => subscribeToSettings()}
            className="flex items-center px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
            disabled={loading || saving}
          >
            <RefreshCcw className={`w-5 h-5 mr-2 ${loading ? 'animate-spin' : ''}`} />
            Refresh
          </button>
        </div>

        {error && (
          <div className="bg-red-50 border border-red-200 rounded-lg p-4 flex items-start">
            <AlertCircle className="w-5 h-5 text-red-500 mt-0.5 mr-3 flex-shrink-0" />
            <p className="text-red-700">{error}</p>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-8">
          <CompanyInfo settings={settings} onSettingsChange={setSettings} />
          <SystemStatus settings={settings} onSettingsChange={setSettings} />
          <TransactionSettings settings={settings} onSettingsChange={setSettings} />
          <PaymentMethods settings={settings} onSettingsChange={setSettings} />
          <SupportSettings settings={settings} onSettingsChange={setSettings} />
          <SocialMedia settings={settings} onSettingsChange={setSettings} />
          <GoogleTagManager settings={settings} onSettingsChange={setSettings} />
          <OpenAISettings settings={settings} onSettingsChange={setSettings} />
          <VideoGuidance settings={settings} onSettingsChange={setSettings} />

          <div className="flex justify-end">
            <button
              type="submit"
              disabled={saving || loading}
              className="flex items-center px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
            >
              {saving ? (
                <>
                  <RefreshCcw className="w-5 h-5 mr-2 animate-spin" />
                  Saving...
                </>
              ) : (
                <>
                  <Save className="w-5 h-5 mr-2" />
                  Save Changes
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </AdminLayout>
  );
}