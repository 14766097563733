import React from 'react';
import { Video, Info } from 'lucide-react';
import type { SystemSettings } from '../../../types';

interface VideoGuidanceProps {
  settings: SystemSettings;
  onSettingsChange: (settings: SystemSettings) => void;
}

export default function VideoGuidance({ settings, onSettingsChange }: VideoGuidanceProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h2 className="text-lg font-semibold mb-4 flex items-center">
        <Video className="w-5 h-5 mr-2 text-blue-600" />
        Video Guidance
      </h2>

      <div className="mb-6 bg-blue-50 border border-blue-200 rounded-lg p-4">
        <div className="flex items-start">
          <Info className="w-5 h-5 text-blue-600 mt-0.5 mr-3 flex-shrink-0" />
          <div className="text-sm text-blue-700">
            <p className="font-medium mb-1">Video Guidance Settings:</p>
            <ul className="list-disc list-inside space-y-1">
              <li>Enable/disable video guidance for users</li>
              <li>Add video URL from YouTube or other platforms</li>
              <li>Video will be shown in user dashboard</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <div>
            <label className="font-medium text-gray-900">Enable Video Guidance</label>
            <p className="text-sm text-gray-500">
              Show video guidance in user dashboard
            </p>
          </div>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={settings.video_guidance_enabled || false}
              onChange={(e) => onSettingsChange({ 
                ...settings, 
                video_guidance_enabled: e.target.checked 
              })}
              className="sr-only peer"
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
          </label>
        </div>

        <div className={settings.video_guidance_enabled ? 'opacity-100' : 'opacity-50 pointer-events-none'}>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Video URL
          </label>
          <input
            type="url"
            value={settings.video_guidance_url || ''}
            onChange={(e) => onSettingsChange({ 
              ...settings, 
              video_guidance_url: e.target.value 
            })}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="https://www.youtube.com/embed/..."
            disabled={!settings.video_guidance_enabled}
          />
          <p className="mt-1 text-sm text-gray-500">
            Enter the embed URL of your guidance video (e.g., YouTube embed URL)
          </p>
        </div>

        {settings.video_guidance_enabled && settings.video_guidance_url && (
          <div className="mt-4">
            <h3 className="text-sm font-medium text-gray-700 mb-2">Preview</h3>
            <div className="relative pt-[56.25%] rounded-lg overflow-hidden bg-gray-100">
              <iframe
                src={settings.video_guidance_url}
                className="absolute inset-0 w-full h-full"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}