import React from 'react';
import { Shield } from 'lucide-react';

export default function Privacy() {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-sm p-8">
          <div className="flex items-center mb-8">
            <Shield className="w-8 h-8 text-blue-600 mr-4" />
            <h1 className="text-3xl font-bold text-gray-900">Privacy Policy</h1>
          </div>

          <div className="prose prose-blue max-w-none">
            <h2>1. Information We Collect</h2>
            <p>
              We collect information that you provide directly to us, including:
            </p>
            <ul>
              <li>Personal identification information (name, email address, phone number)</li>
              <li>Bank account details for transactions</li>
              <li>Transaction history and activity on our platform</li>
              <li>Communication records when you contact our support team</li>
            </ul>

            <h2>2. How We Use Your Information</h2>
            <p>
              We use the collected information for:
            </p>
            <ul>
              <li>Processing your transactions</li>
              <li>Providing customer support</li>
              <li>Sending important updates about our services</li>
              <li>Improving our platform and user experience</li>
              <li>Complying with legal obligations</li>
            </ul>

            <h2>3. Data Security</h2>
            <p>
              We implement strict security measures to protect your personal information:
            </p>
            <ul>
              <li>Encryption of sensitive data</li>
              <li>Regular security audits</li>
              <li>Secure data storage practices</li>
              <li>Access controls and monitoring</li>
            </ul>

            <h2>4. Information Sharing</h2>
            <p>
              We do not sell your personal information. We may share your information only:
            </p>
            <ul>
              <li>When required by law</li>
              <li>With service providers who assist in our operations</li>
              <li>To protect against fraud or security threats</li>
            </ul>

            <h2>5. Your Rights</h2>
            <p>
              You have the right to:
            </p>
            <ul>
              <li>Access your personal information</li>
              <li>Correct inaccurate data</li>
              <li>Request deletion of your data</li>
              <li>Opt-out of marketing communications</li>
            </ul>

            <h2>6. Cookies and Tracking</h2>
            <p>
              We use cookies and similar technologies to improve your experience and analyze platform usage.
            </p>

            <h2>7. Changes to Privacy Policy</h2>
            <p>
              We may update this privacy policy periodically. We will notify you of any material changes.
            </p>

            <h2>8. Contact Us</h2>
            <p>
              If you have questions about our privacy practices, please contact our support team.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}