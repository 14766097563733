import React, { useState, useEffect } from 'react';
import { Shield, Clock, CheckCircle, Loader } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import AuthModal from '../auth/AuthModal';
import { ref, onValue } from 'firebase/database';
import { database } from '../../lib/firebase';

interface UrduContent {
  main_heading: string;
  main_content: string;
  button_text: string;
}

export default function UrduSection() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [content, setContent] = useState<UrduContent>({
    main_heading: 'محفوظ اور قابلِ اعتماد USDT ٹریڈنگ – بینک ڈسپیوٹ سے نجات پائیں!',
    main_content: 'پاکستان میں Binance P2P پر USDT فروخت کرتے ہوئے بہت سے صارفین کو بینک ڈسپیوٹ اور اکاؤنٹ فریز ہونے جیسے مسائل کا سامنا کرنا پڑتا ہے...',
    button_text: 'ٹریڈ شروع کرنے کے لیے ابھی ہمارے پلیٹ فارم پر رجسٹر کریں!'
  });

  useEffect(() => {
    let mounted = true;
    const contentRef = ref(database, 'content/urdu');
    
    const unsubscribe = onValue(contentRef, (snapshot) => {
      if (!mounted) return;
      
      try {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setContent({
            main_heading: data.main_heading || content.main_heading,
            main_content: data.main_content || content.main_content,
            button_text: data.button_text || content.button_text
          });
        }
        setError(null);
      } catch (error: any) {
        console.error('Error processing Urdu content:', error);
        setError('Failed to load content');
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    }, (error) => {
      console.error('Error subscribing to Urdu content:', error);
      if (mounted) {
        setError('Failed to load content');
        setLoading(false);
      }
    });

    // Set a timeout to prevent infinite loading
    const timeout = setTimeout(() => {
      if (mounted) {
        setLoading(false);
        if (!content) {
          setError('Content loading timeout');
        }
      }
    }, 5000);

    return () => {
      mounted = false;
      unsubscribe();
      clearTimeout(timeout);
    };
  }, []);

  const handleButtonClick = () => {
    if (user) {
      navigate('/user');
    } else {
      setShowAuthModal(true);
    }
  };

  if (loading) {
    return (
      <div className="min-h-[400px] bg-gray-50 flex items-center justify-center">
        <Loader className="w-8 h-8 text-blue-600 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-[400px] bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <p className="text-red-600 mb-4">{error}</p>
          <button 
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="py-16 bg-gradient-to-b from-white to-indigo-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-2xl shadow-xl p-8 border border-indigo-100">
          <h2 className="text-3xl font-bold text-center mb-12 font-['Noto_Nastaliq_Urdu'] leading-[2] text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-indigo-600 py-2" dir="rtl">
            {content.main_heading}
          </h2>

          <div className="space-y-6 text-right" dir="rtl">
            <p className="text-lg font-['Noto_Nastaliq_Urdu'] leading-[2.2] text-gray-800">
              {content.main_content}
            </p>

            <div className="mt-12">
              <h3 className="text-2xl font-['Noto_Nastaliq_Urdu'] mb-8 text-blue-900 leading-[2]">
                ہماری سروس کیوں بہترین ہے؟
              </h3>
              <div className="grid md:grid-cols-3 gap-6">
                <div className="bg-gradient-to-br from-green-50 to-emerald-50 p-6 rounded-xl">
                  <div className="flex justify-start mb-4">
                    <Shield className="w-8 h-8 text-green-600" />
                  </div>
                  <p className="text-lg font-['Noto_Nastaliq_Urdu'] text-green-900 leading-[2]">
                    ✅ 100% محفوظ ٹرانزیکشنز – آپ کی رقم بینک ڈسپیوٹ کے خطرے سے محفوظ ہے۔
                  </p>
                </div>
                <div className="bg-gradient-to-br from-blue-50 to-indigo-50 p-6 rounded-xl">
                  <div className="flex justify-start mb-4">
                    <Clock className="w-8 h-8 text-blue-600" />
                  </div>
                  <p className="text-lg font-['Noto_Nastaliq_Urdu'] text-blue-900 leading-[2]">
                    ✅ فوری ادائیگی – بغیر کسی تاخیر کے رقم براہِ راست آپ کے اکاؤنٹ میں۔
                  </p>
                </div>
                <div className="bg-gradient-to-br from-purple-50 to-pink-50 p-6 rounded-xl">
                  <div className="flex justify-start mb-4">
                    <CheckCircle className="w-8 h-8 text-purple-600" />
                  </div>
                  <p className="text-lg font-['Noto_Nastaliq_Urdu'] text-purple-900 leading-[2]">
                    ✅ قابلِ اعتماد پلیٹ فارم – شفاف اور محفوظ ٹریڈنگ کا بہترین حل۔
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-12 text-center">
              <p className="text-xl font-['Noto_Nastaliq_Urdu'] mb-8 text-gray-800 leading-[2]">
                اب Binance P2P پر ہونے والے مسائل سے بچیں اور اپنی USDT کو محفوظ اور آسان طریقے سے کیش کریں!
              </p>
              <button
                onClick={handleButtonClick}
                className="bg-gradient-to-r from-blue-600 to-indigo-600 text-white px-8 py-4 rounded-xl font-['Noto_Nastaliq_Urdu'] text-xl hover:from-blue-700 hover:to-indigo-700 transition-all shadow-lg hover:shadow-xl relative overflow-hidden btn-reflection leading-[2]"
              >
                {content.button_text}
              </button>
            </div>
          </div>
        </div>
      </div>

      <AuthModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        defaultTab="register"
      />
    </div>
  );
}