import OpenAI from 'openai';
import { ref, get } from 'firebase/database';
import { database } from './firebase';

let openaiInstance: OpenAI | null = null;

export async function getOpenAIInstance() {
  if (!openaiInstance) {
    // Get API key from system settings
    const settingsRef = ref(database, 'system_settings');
    const snapshot = await get(settingsRef);
    const settings = snapshot.val();

    if (!settings?.openai_api_key) {
      throw new Error('OpenAI API key not configured in system settings');
    }

    openaiInstance = new OpenAI({
      apiKey: settings.openai_api_key,
      dangerouslyAllowBrowser: true // Only for development, use server-side in production
    });
  }

  return openaiInstance;
}

export async function generateBlogPost(topic: string) {
  try {
    const openai = await getOpenAIInstance();

    const completion = await openai.chat.completions.create({
      model: "gpt-4",
      messages: [
        {
          role: "system",
          content: "You are a professional content writer specializing in cryptocurrency and USDT trading in Pakistan. Write engaging, informative content that is SEO optimized. Format your response as a blog post with title, meta title, meta description, excerpt, main content, and relevant tags."
        },
        {
          role: "user",
          content: `Write a blog post about: ${topic}`
        }
      ]
    });

    // Parse the response content
    const content = completion.choices[0].message.content;
    if (!content) throw new Error('No content generated');

    // Extract sections using regex
    const titleMatch = content.match(/Title:\s*(.+?)(?=\n|$)/i);
    const metaTitleMatch = content.match(/Meta Title:\s*(.+?)(?=\n|$)/i);
    const metaDescMatch = content.match(/Meta Description:\s*(.+?)(?=\n|$)/i);
    const excerptMatch = content.match(/Excerpt:\s*(.+?)(?=\n|$)/i);
    const tagsMatch = content.match(/Tags:\s*(.+?)(?=\n|$)/i);
    
    // Extract main content (everything after the metadata)
    const mainContent = content.split(/Content:\s*/i)[1]?.trim() || '';

    // Default image if none provided
    const defaultImage = 'https://images.unsplash.com/photo-1621761191319-c6fb62004040?auto=format&fit=crop&q=80';

    return {
      title: titleMatch?.[1]?.trim() || topic,
      meta_title: metaTitleMatch?.[1]?.trim() || `${topic} - USDT Exchange Pakistan`,
      meta_description: metaDescMatch?.[1]?.trim() || `Learn about ${topic} in Pakistan's crypto market`,
      excerpt: excerptMatch?.[1]?.trim() || mainContent.slice(0, 150) + '...',
      content: mainContent || 'Content generation failed',
      tags: tagsMatch?.[1]?.split(',').map(tag => tag.trim()) || ['USDT', 'Cryptocurrency', 'Pakistan'],
      image: defaultImage
    };
  } catch (error: any) {
    console.error('Error generating blog post:', error);
    throw new Error(error.message || 'Failed to generate blog post');
  }
}

export async function optimizeSEO(content: string) {
  try {
    const openai = await getOpenAIInstance();

    const completion = await openai.chat.completions.create({
      model: "gpt-4",
      messages: [
        {
          role: "system",
          content: "You are an SEO expert. Analyze content and provide optimization suggestions."
        },
        {
          role: "user",
          content: `Analyze this content and suggest SEO improvements: ${content}`
        }
      ]
    });

    return completion.choices[0].message.content;
  } catch (error: any) {
    console.error('Error optimizing SEO:', error);
    throw new Error(error.message || 'Failed to optimize SEO');
  }
}

export async function generateMetaTags(content: string) {
  try {
    const openai = await getOpenAIInstance();

    const completion = await openai.chat.completions.create({
      model: "gpt-4",
      messages: [
        {
          role: "system",
          content: "You are an SEO expert. Generate optimized meta tags for content."
        },
        {
          role: "user",
          content: `Generate meta title and meta description for this content: ${content}`
        }
      ]
    });

    const response = completion.choices[0].message.content;
    
    // Parse the response to extract meta title and description
    const metaTitleMatch = response.match(/Meta Title:\s*(.+?)(?=\n|$)/i);
    const metaDescMatch = response.match(/Meta Description:\s*(.+?)(?=\n|$)/i);

    return {
      meta_title: metaTitleMatch?.[1]?.trim() || '',
      meta_description: metaDescMatch?.[1]?.trim() || ''
    };
  } catch (error: any) {
    console.error('Error generating meta tags:', error);
    throw new Error(error.message || 'Failed to generate meta tags');
  }
}