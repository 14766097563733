import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft, Clock, User, Tag, Share2 } from 'lucide-react';
import { Helmet } from 'react-helmet-async';

export default function BlogPost() {
  const { id } = useParams();

  const posts = {
    'usdt-trading-pakistan-guide': {
      title: 'Complete Guide to USDT Trading in Pakistan: Tips, Tricks, and Best Practices',
      content: `
        <h2>Introduction to USDT Trading in Pakistan</h2>
        <p>USDT (Tether) has become the go-to stablecoin for cryptocurrency traders in Pakistan. This comprehensive guide will walk you through everything you need to know about trading USDT safely and efficiently.</p>

        <h2>Why Choose USDT?</h2>
        <p>USDT offers several advantages for Pakistani traders:</p>
        <ul>
          <li>Stable value pegged to USD</li>
          <li>Wide acceptance across trading platforms</li>
          <li>Fast and efficient transfers</li>
          <li>Lower volatility compared to other cryptocurrencies</li>
        </ul>

        <h2>Best Practices for USDT Trading</h2>
        <p>Follow these essential practices to ensure safe and profitable trading:</p>
        <ol>
          <li>Always use reputable exchanges</li>
          <li>Verify seller/buyer credentials</li>
          <li>Keep detailed transaction records</li>
          <li>Use secure payment methods</li>
        </ol>

        <h2>Understanding the Risks</h2>
        <p>While USDT trading offers many benefits, it's important to be aware of potential risks:</p>
        <ul>
          <li>Market volatility</li>
          <li>Regulatory changes</li>
          <li>Scam attempts</li>
          <li>Technical risks</li>
        </ul>

        <p>For the safest and most reliable USDT trading experience in Pakistan, consider using our <a href="/" className="text-blue-600 hover:text-blue-800">trusted exchange platform</a>.</p>
      `,
      author: 'Crypto Expert',
      date: 'March 14, 2024',
      readTime: '8 min read',
      tags: ['USDT', 'Trading', 'Pakistan', 'Cryptocurrency'],
      image: 'https://images.unsplash.com/photo-1621761191319-c6fb62004040?auto=format&fit=crop&q=80',
      metaTitle: 'Complete Guide to USDT Trading in Pakistan (2024) | Safe Trading Tips',
      metaDescription: 'Master USDT trading in Pakistan with our comprehensive guide. Learn about secure platforms, best practices, regulations, and how to get the best rates in 2024.'
    },
    'crypto-regulations-pakistan': {
      title: 'Understanding Cryptocurrency Regulations in Pakistan: Current Status and Future Outlook',
      content: `
        <h2>Current Regulatory Landscape</h2>
        <p>Pakistan's stance on cryptocurrency has evolved significantly over the years. This article provides a detailed overview of the current regulatory framework and what it means for traders.</p>

        <h2>Legal Status of Cryptocurrency</h2>
        <p>Understanding the legal status of cryptocurrency in Pakistan:</p>
        <ul>
          <li>Current government stance</li>
          <li>SBP's position</li>
          <li>Tax implications</li>
          <li>Compliance requirements</li>
        </ul>

        <h2>Future Outlook</h2>
        <p>What to expect in the coming years:</p>
        <ul>
          <li>Potential regulatory changes</li>
          <li>Government initiatives</li>
          <li>International compliance</li>
          <li>Market impact</li>
        </ul>

        <p>Stay compliant and secure by using our <a href="/" className="text-blue-600 hover:text-blue-800">regulated exchange platform</a> for all your USDT transactions.</p>
      `,
      author: 'Legal Analyst',
      date: 'March 13, 2024',
      readTime: '6 min read',
      tags: ['Regulations', 'Pakistan', 'Cryptocurrency', 'Legal'],
      image: 'https://images.unsplash.com/photo-1631603090989-93f9ef6f9d80?auto=format&fit=crop&q=80',
      metaTitle: 'Cryptocurrency Regulations in Pakistan 2024 | Legal Status & Guidelines',
      metaDescription: 'Get the latest updates on cryptocurrency regulations in Pakistan. Understand the legal framework, compliance requirements, and future outlook for digital assets.'
    },
    'secure-crypto-transactions': {
      title: 'How to Secure Your Cryptocurrency Transactions: Essential Security Measures',
      content: `
        <h2>Essential Security Measures</h2>
        <p>Protecting your cryptocurrency transactions is crucial. Learn about the essential security measures every trader should implement.</p>

        <h2>Common Security Threats</h2>
        <p>Be aware of these common security threats:</p>
        <ul>
          <li>Phishing attempts</li>
          <li>Fake exchanges</li>
          <li>Social engineering</li>
          <li>Malware attacks</li>
        </ul>

        <h2>Best Practices for Security</h2>
        <p>Follow these security best practices:</p>
        <ol>
          <li>Use strong passwords</li>
          <li>Enable 2FA</li>
          <li>Verify transactions</li>
          <li>Use secure networks</li>
        </ol>

        <p>Trade securely on our <a href="/" className="text-blue-600 hover:text-blue-800">platform</a> with advanced security features and protection measures.</p>
      `,
      author: 'Security Specialist',
      date: 'March 12, 2024',
      readTime: '7 min read',
      tags: ['Security', 'Cryptocurrency', 'Protection', 'Best Practices'],
      image: 'https://images.unsplash.com/photo-1639762681485-074b7f938ba0?auto=format&fit=crop&q=80',
      metaTitle: 'Cryptocurrency Security Guide 2024 | Protect Your Digital Assets',
      metaDescription: 'Learn essential security measures for cryptocurrency transactions. Protect your digital assets with our expert guide on best practices and scam prevention.'
    }
  };

  const post = posts[id as keyof typeof posts];

  if (!post) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900">Post not found</h1>
          <Link to="/blog" className="text-blue-600 hover:text-blue-800 mt-4 inline-block">
            Back to Blog
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>{post.metaTitle}</title>
        <meta name="description" content={post.metaDescription} />
        <meta name="keywords" content={post.tags.join(', ')} />
        <meta property="og:title" content={post.metaTitle} />
        <meta property="og:description" content={post.metaDescription} />
        <meta property="og:image" content={post.image} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.metaTitle} />
        <meta name="twitter:description" content={post.metaDescription} />
        <meta name="twitter:image" content={post.image} />
      </Helmet>

      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <Link to="/blog" className="inline-flex items-center text-blue-600 hover:text-blue-800 mb-8">
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Blog
        </Link>

        <article>
          <img
            src={post.image}
            alt={post.title}
            className="w-full h-64 object-cover rounded-lg mb-8"
          />

          <div className="flex flex-wrap gap-2 mb-4">
            {post.tags.map((tag) => (
              <span
                key={tag}
                className="text-sm bg-blue-100 text-blue-800 px-3 py-1 rounded-full"
              >
                <Tag className="w-4 h-4 inline-block mr-1" />
                {tag}
              </span>
            ))}
          </div>

          <h1 className="text-3xl font-bold text-gray-900 mb-4">
            {post.title}
          </h1>

          <div className="flex items-center text-gray-500 mb-8">
            <User className="w-4 h-4 mr-1" />
            <span className="mr-4">{post.author}</span>
            <Clock className="w-4 h-4 mr-1" />
            <span className="mr-4">{post.readTime}</span>
            <span>{post.date}</span>
          </div>

          <div 
            className="prose prose-blue max-w-none"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />

          <div className="mt-8 pt-8 border-t border-gray-200">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <img
                  src={`https://ui-avatars.com/api/?name=${encodeURIComponent(post.author)}&background=random`}
                  alt={post.author}
                  className="w-12 h-12 rounded-full"
                />
                <div>
                  <p className="font-medium text-gray-900">{post.author}</p>
                  <p className="text-sm text-gray-500">Cryptocurrency Expert</p>
                </div>
              </div>
              <button
                onClick={() => navigator.share({
                  title: post.title,
                  text: post.metaDescription,
                  url: window.location.href
                })}
                className="text-blue-600 hover:text-blue-800"
              >
                <Share2 className="w-5 h-5" />
              </button>
            </div>
          </div>
        </article>
      </div>
    </div>
  );
}