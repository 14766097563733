import React from 'react';
import { Mail } from 'lucide-react';
import type { SystemSettings } from '../../../types';

interface SupportSettingsProps {
  settings: SystemSettings;
  onSettingsChange: (settings: SystemSettings) => void;
}

export default function SupportSettings({ settings, onSettingsChange }: SupportSettingsProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h2 className="text-lg font-semibold mb-4 flex items-center">
        <Mail className="w-5 h-5 mr-2 text-blue-600" />
        Support Settings
      </h2>
      <div className="grid md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Support Email
          </label>
          <input
            type="email"
            value={settings.support_email}
            onChange={(e) => onSettingsChange({ ...settings, support_email: e.target.value })}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Support Phone
          </label>
          <input
            type="tel"
            value={settings.support_phone}
            onChange={(e) => onSettingsChange({ ...settings, support_phone: e.target.value })}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Support Hours
          </label>
          <input
            type="text"
            value={settings.support_hours}
            onChange={(e) => onSettingsChange({ ...settings, support_hours: e.target.value })}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
      </div>
    </div>
  );
}