import React, { useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../lib/firebase';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export default function GTMScript() {
  useEffect(() => {
    // Initialize dataLayer
    window.dataLayer = window.dataLayer || [];

    // Subscribe to system settings to get GTM configuration
    const settingsRef = ref(database, 'system_settings');
    const unsubscribe = onValue(settingsRef, (snapshot) => {
      if (snapshot.exists()) {
        const settings = snapshot.val();
        if (settings.gtm_enabled && settings.gtm_id) {
          // Load GTM script
          const script = document.createElement('script');
          script.innerHTML = `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${settings.gtm_id}');
          `;
          document.head.appendChild(script);

          // Add noscript iframe
          const noscript = document.createElement('noscript');
          const iframe = document.createElement('iframe');
          iframe.src = `https://www.googletagmanager.com/ns.html?id=${settings.gtm_id}`;
          iframe.height = '0';
          iframe.width = '0';
          iframe.style.display = 'none';
          iframe.style.visibility = 'hidden';
          noscript.appendChild(iframe);
          document.body.appendChild(noscript);
        }

        // Update meta tags and favicon
        updateMetaTags(settings);
      }
    });

    return () => {
      unsubscribe();
      // Clean up GTM scripts when component unmounts
      const scripts = document.querySelectorAll('script[src*="googletagmanager.com"]');
      scripts.forEach(script => script.remove());
      const noscripts = document.querySelectorAll('noscript iframe[src*="googletagmanager.com"]');
      noscripts.forEach(noscript => noscript.remove());
    };
  }, []);

  const updateMetaTags = (settings: any) => {
    // Update title
    document.title = settings.meta_title || 'USDT Exchange Pakistan';

    // Update meta description
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement('meta');
      metaDescription.setAttribute('name', 'description');
      document.head.appendChild(metaDescription);
    }
    metaDescription.setAttribute('content', settings.meta_description || '');

    // Update Open Graph meta tags
    const ogTags = {
      'og:title': settings.meta_title,
      'og:description': settings.meta_description,
      'og:site_name': settings.company_name,
      'og:type': 'website'
    };

    Object.entries(ogTags).forEach(([property, content]) => {
      let tag = document.querySelector(`meta[property="${property}"]`);
      if (!tag) {
        tag = document.createElement('meta');
        tag.setAttribute('property', property);
        document.head.appendChild(tag);
      }
      tag.setAttribute('content', content || '');
    });

    // Update Twitter meta tags
    const twitterTags = {
      'twitter:title': settings.meta_title,
      'twitter:description': settings.meta_description,
      'twitter:card': 'summary_large_image'
    };

    Object.entries(twitterTags).forEach(([name, content]) => {
      let tag = document.querySelector(`meta[name="${name}"]`);
      if (!tag) {
        tag = document.createElement('meta');
        tag.setAttribute('name', name);
        document.head.appendChild(tag);
      }
      tag.setAttribute('content', content || '');
    });

    // Update favicon
    if (settings.company_favicon) {
      const faviconLink = document.getElementById('dynamic-favicon') as HTMLLinkElement;
      if (faviconLink) {
        faviconLink.href = settings.company_favicon;
      }
    }
  };

  return null;
}