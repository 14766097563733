import React, { useState, useEffect } from 'react';
import { HelpCircle } from 'lucide-react';
import { ref, onValue } from 'firebase/database';
import { database } from '../../lib/firebase';

interface FAQ {
  id: string;
  question: string;
  answer: string;
  sort_order: number;
  is_active: boolean;
}

export default function FAQ() {
  const [faqs, setFaqs] = useState<FAQ[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const faqsRef = ref(database, 'faqs');
    const unsubscribe = onValue(faqsRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const faqsArray = Object.entries(data).map(([id, faq]: [string, any]) => ({
          id,
          ...faq
        })).filter(faq => faq.is_active)
          .sort((a, b) => a.sort_order - b.sort_order);
        setFaqs(faqsArray);
      }
      setLoading(false);
    }, (error) => {
      console.error('Error fetching FAQs:', error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div className="py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="animate-pulse">
            <div className="h-8 bg-gray-200 rounded w-1/4 mx-auto mb-8"></div>
            <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
              {[...Array(4)].map((_, i) => (
                <div key={i} className="h-32 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id="faq" className="py-20 scroll-mt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-indigo-600">
            Frequently Asked Questions
          </h2>
          <p className="text-gray-600">Everything you need to know about selling USDT in Pakistan</p>
        </div>
        <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          {faqs.map((faq) => (
            <div key={faq.id} className="bg-white p-6 rounded-xl shadow-sm border border-gray-100 hover:shadow-md transition-shadow">
              <h3 className="text-lg font-semibold mb-2 flex items-center text-blue-900">
                <HelpCircle className="w-5 h-5 text-blue-600 mr-2" />
                {faq.question}
              </h3>
              <p className="text-gray-600 whitespace-pre-wrap">
                {faq.answer}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}