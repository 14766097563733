import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import {
  BarChart3,
  TrendingUp,
  Users,
  DollarSign,
  Calendar,
  ArrowUpRight,
  ArrowDownRight,
  Clock,
  RefreshCcw,
  Loader
} from 'lucide-react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { ref, onValue, query, orderByChild, startAt, endAt } from 'firebase/database';
import { database } from '../../lib/firebase';
import toast from 'react-hot-toast';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface AnalyticsData {
  totalVolume: number;
  totalTransactions: number;
  totalUsers: number;
  averageTransactionSize: number;
  volumeChange: number;
  userChange: number;
  transactionChange: number;
  processingTimeChange: number;
  dailyVolumes: {
    date: string;
    volume: number;
  }[];
  transactionsByStatus: {
    status: string;
    count: number;
  }[];
  hourlyActivity: {
    hour: number;
    count: number;
  }[];
}

export default function Analytics() {
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [timeframe, setTimeframe] = useState<'7d' | '30d' | '90d'>('30d');
  const [analytics, setAnalytics] = useState<AnalyticsData>({
    totalVolume: 0,
    totalTransactions: 0,
    totalUsers: 0,
    averageTransactionSize: 0,
    volumeChange: 0,
    userChange: 0,
    transactionChange: 0,
    processingTimeChange: 0,
    dailyVolumes: [],
    transactionsByStatus: [],
    hourlyActivity: []
  });

  useEffect(() => {
    const unsubscribe = subscribeToAnalytics();
    return () => unsubscribe();
  }, [timeframe]);

  const subscribeToAnalytics = () => {
    try {
      setRefreshing(true);
      
      // Get date range
      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(endDate.getDate() - (timeframe === '7d' ? 7 : timeframe === '30d' ? 30 : 90));

      // Subscribe to orders
      const ordersRef = ref(database, 'orders');
      const ordersQuery = query(
        ordersRef,
        orderByChild('created_at'),
        startAt(startDate.toISOString()),
        endAt(endDate.toISOString())
      );
      
      // Subscribe to users
      const usersRef = ref(database, 'users');
      const usersQuery = query(
        usersRef,
        orderByChild('created_at'),
        startAt(startDate.toISOString()),
        endAt(endDate.toISOString())
      );
      
      // Subscribe to orders
      const ordersUnsubscribe = onValue(ordersQuery, (ordersSnapshot) => {
        try {
          const orders = ordersSnapshot.val() || {};
          const currentOrders = Object.values(orders) as any[];

          // Subscribe to users
          const usersUnsubscribe = onValue(usersQuery, (usersSnapshot) => {
            try {
              const users = usersSnapshot.val() || {};
              const currentUsers = Object.values(users) as any[];

              // Calculate metrics
              const totalVolume = currentOrders
                .filter(order => order.status === 'completed')
                .reduce((sum, order) => sum + (order.amount || 0), 0);

              const totalTransactions = currentOrders.length;
              const averageTransactionSize = totalTransactions > 0 ? totalVolume / totalTransactions : 0;

              // Calculate daily volumes
              const dailyVolumes = currentOrders.reduce((acc: any[], order) => {
                const date = new Date(order.created_at).toLocaleDateString();
                const existing = acc.find(item => item.date === date);
                if (existing) {
                  existing.volume += order.amount || 0;
                } else {
                  acc.push({ date, volume: order.amount || 0 });
                }
                return acc;
              }, []);

              // Calculate transactions by status
              const transactionsByStatus = Object.entries(
                currentOrders.reduce((acc: any, order) => {
                  acc[order.status] = (acc[order.status] || 0) + 1;
                  return acc;
                }, {})
              ).map(([status, count]) => ({ status, count }));

              // Calculate hourly activity
              const hourlyActivity = currentOrders.reduce((acc: any[], order) => {
                const hour = new Date(order.created_at).getHours();
                const existing = acc.find(item => item.hour === hour);
                if (existing) {
                  existing.count++;
                } else {
                  acc.push({ hour, count: 1 });
                }
                return acc.sort((a, b) => a.hour - b.hour);
              }, []);

              // Calculate changes from previous period
              const previousStartDate = new Date(startDate);
              previousStartDate.setDate(previousStartDate.getDate() - (timeframe === '7d' ? 7 : timeframe === '30d' ? 30 : 90));

              const previousOrders = Object.values(orders).filter((order: any) => {
                const orderDate = new Date(order.created_at);
                return orderDate >= previousStartDate && orderDate < startDate;
              });

              const previousUsers = Object.values(users).filter((user: any) => {
                const userDate = new Date(user.created_at);
                return userDate >= previousStartDate && userDate < startDate;
              });

              const previousVolume = previousOrders
                .filter((order: any) => order.status === 'completed')
                .reduce((sum, order: any) => sum + (order.amount || 0), 0);

              const volumeChange = previousVolume > 0 
                ? ((totalVolume - previousVolume) / previousVolume) * 100 
                : 0;

              const userChange = previousUsers.length > 0 
                ? ((currentUsers.length - previousUsers.length) / previousUsers.length) * 100 
                : 0;

              const transactionChange = previousOrders.length > 0 
                ? ((totalTransactions - previousOrders.length) / previousOrders.length) * 100 
                : 0;

              setAnalytics({
                totalVolume,
                totalTransactions,
                totalUsers: currentUsers.length,
                averageTransactionSize,
                volumeChange,
                userChange,
                transactionChange,
                processingTimeChange: 0, // Default value
                dailyVolumes,
                transactionsByStatus,
                hourlyActivity
              });
            } catch (error) {
              console.error('Error processing analytics:', error);
              toast.error('Failed to process analytics data');
            } finally {
              setLoading(false);
              setRefreshing(false);
            }
          });

          return () => usersUnsubscribe();
        } catch (error) {
          console.error('Error processing orders:', error);
          toast.error('Failed to process orders data');
          setLoading(false);
          setRefreshing(false);
        }
      });

      return () => ordersUnsubscribe();
    } catch (error) {
      console.error('Error setting up analytics:', error);
      toast.error('Failed to load analytics');
      setLoading(false);
      setRefreshing(false);
      return () => {}; // Return empty cleanup function
    }
  };

  const volumeChartData = {
    labels: analytics.dailyVolumes.map(item => item.date),
    datasets: [
      {
        label: 'Daily Volume (USDT)',
        data: analytics.dailyVolumes.map(item => item.volume),
        fill: true,
        borderColor: 'rgb(59, 130, 246)',
        backgroundColor: 'rgba(59, 130, 246, 0.1)',
        tension: 0.4
      }
    ]
  };

  const statusChartData = {
    labels: analytics.transactionsByStatus.map(item => item.status),
    datasets: [
      {
        label: 'Transactions by Status',
        data: analytics.transactionsByStatus.map(item => item.count),
        backgroundColor: [
          'rgba(34, 197, 94, 0.6)',  // green for completed
          'rgba(234, 179, 8, 0.6)',  // yellow for pending
          'rgba(59, 130, 246, 0.6)', // blue for processing
          'rgba(239, 68, 68, 0.6)',  // red for failed
        ]
      }
    ]
  };

  const activityChartData = {
    labels: analytics.hourlyActivity.map(item => `${item.hour}:00`),
    datasets: [
      {
        label: 'Transactions per Hour',
        data: analytics.hourlyActivity.map(item => item.count),
        backgroundColor: 'rgba(59, 130, 246, 0.6)'
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  if (loading) {
    return (
      <AdminLayout>
        <div className="flex items-center justify-center min-h-[200px]">
          <Loader className="w-8 h-8 text-blue-600 animate-spin" />
        </div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <div className="space-y-8">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-gray-900">Analytics Dashboard</h1>
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <select
                value={timeframe}
                onChange={(e) => setTimeframe(e.target.value as '7d' | '30d' | '90d')}
                className="px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="7d">Last 7 days</option>
                <option value="30d">Last 30 days</option>
                <option value="90d">Last 90 days</option>
              </select>
              <button
                onClick={() => subscribeToAnalytics()}
                disabled={refreshing}
                className="flex items-center px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
              >
                <RefreshCcw className={`w-5 h-5 mr-2 ${refreshing ? 'animate-spin' : ''}`} />
                Refresh
              </button>
            </div>
          </div>
        </div>

        {/* Key Metrics */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-600 mb-1">Total Volume</p>
                <h3 className="text-2xl font-bold text-gray-900">{analytics.totalVolume.toFixed(2)} USDT</h3>
                <div className={`flex items-center mt-2 ${
                  analytics.volumeChange >= 0 ? 'text-green-600' : 'text-red-600'
                }`}>
                  {analytics.volumeChange >= 0 ? (
                    <ArrowUpRight className="w-4 h-4 mr-1" />
                  ) : (
                    <ArrowDownRight className="w-4 h-4 mr-1" />
                  )}
                  <span className="text-sm font-medium">
                    {Math.abs(analytics.volumeChange).toFixed(1)}% from last period
                  </span>
                </div>
              </div>
              <div className="p-3 bg-blue-50 rounded-full">
                <DollarSign className="w-6 h-6 text-blue-600" />
              </div>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-sm">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-600 mb-1">Total Users</p>
                <h3 className="text-2xl font-bold text-gray-900">{analytics.totalUsers}</h3>
                <div className={`flex items-center mt-2 ${
                  analytics.userChange >= 0 ? 'text-green-600' : 'text-red-600'
                }`}>
                  {analytics.userChange >= 0 ? (
                    <ArrowUpRight className="w-4 h-4 mr-1" />
                  ) : (
                    <ArrowDownRight className="w-4 h-4 mr-1" />
                  )}
                  <span className="text-sm font-medium">
                    {Math.abs(analytics.userChange).toFixed(1)}% from last period
                  </span>
                </div>
              </div>
              <div className="p-3 bg-green-50 rounded-full">
                <Users className="w-6 h-6 text-green-600" />
              </div>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-sm">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-600 mb-1">Total Transactions</p>
                <h3 className="text-2xl font-bold text-gray-900">{analytics.totalTransactions}</h3>
                <div className={`flex items-center mt-2 ${
                  analytics.transactionChange >= 0 ? 'text-green-600' : 'text-red-600'
                }`}>
                  {analytics.transactionChange >= 0 ? (
                    <ArrowUpRight className="w-4 h-4 mr-1" />
                  ) : (
                    <ArrowDownRight className="w-4 h-4 mr-1" />
                  )}
                  <span className="text-sm font-medium">
                    {Math.abs(analytics.transactionChange).toFixed(1)}% from last period
                  </span>
                </div>
              </div>
              <div className="p-3 bg-purple-50 rounded-full">
                <BarChart3 className="w-6 h-6 text-purple-600" />
              </div>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-sm">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-600 mb-1">Average Transaction</p>
                <h3 className="text-2xl font-bold text-gray-900">
                  {analytics.averageTransactionSize.toFixed(2)} USDT
                </h3>
                <div className="flex items-center mt-2 text-gray-600">
                  <Clock className="w-4 h-4 mr-1" />
                  <span className="text-sm">Per transaction</span>
                </div>
              </div>
              <div className="p-3 bg-orange-50 rounded-full">
                <TrendingUp className="w-6 h-6 text-orange-600" />
              </div>
            </div>
          </div>
        </div>

        {/* Charts */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Volume Trend */}
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold mb-4">Volume Trend</h2>
            <Line data={volumeChartData} options={chartOptions} />
          </div>

          {/* Transaction Status Distribution */}
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold mb-4">Transaction Status Distribution</h2>
            <Bar data={statusChartData} options={chartOptions} />
          </div>

          {/* Hourly Activity */}
          <div className="bg-white p-6 rounded-lg shadow-sm lg:col-span-2">
            <h2 className="text-lg font-semibold mb-4">Hourly Activity</h2>
            <Bar data={activityChartData} options={chartOptions} />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}