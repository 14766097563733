import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import TransactionHeader from '../../components/admin/transactions/TransactionHeader';
import TransactionFilters from '../../components/admin/transactions/TransactionFilters';
import TransactionTable from '../../components/admin/transactions/TransactionTable';
import TransactionDetailsModal from '../../components/admin/transactions/TransactionDetailsModal';
import type { Transaction } from '../../types';
import { ref, onValue, query, orderByChild, get } from 'firebase/database';
import { database } from '../../lib/firebase';
import toast from 'react-hot-toast';

export default function Transactions() {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<'all' | 'pending' | 'processing' | 'completed' | 'failed'>('all');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction | null>(null);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const unsubscribe = subscribeToTransactions();
    return () => unsubscribe();
  }, []);

  const subscribeToTransactions = () => {
    try {
      const ordersRef = ref(database, 'orders');
      const ordersQuery = query(ordersRef, orderByChild('created_at'));
      
      return onValue(ordersQuery, async (snapshot) => {
        try {
          if (snapshot.exists()) {
            const ordersData = snapshot.val();
            const ordersPromises = Object.entries(ordersData).map(async ([id, order]: [string, any]) => {
              try {
                // Fetch user details
                const userRef = ref(database, `users/${order.user_id}`);
                const userSnapshot = await get(userRef);
                const userData = userSnapshot.val();
                
                return {
                  id,
                  ...order,
                  user_name: userData?.full_name || 'Unknown User',
                  user_email: userData?.email || 'No email'
                };
              } catch (error) {
                console.error('Error processing order:', error);
                // Return null for failed orders - they'll be filtered out later
                return null;
              }
            });
            
            const ordersArray = await Promise.all(ordersPromises);
            // Filter out any null values from failed user fetches
            const validOrders = ordersArray.filter(Boolean) as Transaction[];
            
            setTransactions(validOrders);
          } else {
            setTransactions([]);
          }
        } catch (error) {
          console.error('Error processing transactions:', error);
          toast.error('Failed to process transactions data');
        } finally {
          setLoading(false);
        }
      });
    } catch (error) {
      console.error('Error setting up transaction subscription:', error);
      toast.error('Failed to initialize transactions');
      setLoading(false);
      return () => {}; // Return empty cleanup function
    }
  };

  const filteredTransactions = transactions
    .filter(tx => {
      const searchLower = searchTerm.toLowerCase();
      const matchesSearch = 
        (tx.transaction_id || '').toLowerCase().includes(searchLower) ||
        (tx.user_name || '').toLowerCase().includes(searchLower) ||
        (tx.user_email || '').toLowerCase().includes(searchLower);
      
      const matchesStatus = statusFilter === 'all' || tx.status === statusFilter;

      return matchesSearch && matchesStatus;
    })
    .sort((a, b) => {
      const dateA = new Date(a.created_at).getTime();
      const dateB = new Date(b.created_at).getTime();
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });

  return (
    <AdminLayout>
      <div className="space-y-8">
        <TransactionHeader transactions={transactions} />

        <TransactionFilters
          searchTerm={searchTerm}
          onSearchChange={setSearchTerm}
          statusFilter={statusFilter}
          onStatusChange={setStatusFilter}
          sortOrder={sortOrder}
          onSortChange={setSortOrder}
        />

        <TransactionTable
          transactions={filteredTransactions}
          loading={loading}
          onViewDetails={(tx) => {
            setSelectedTransaction(tx);
            setShowDetails(true);
          }}
        />

        {showDetails && selectedTransaction && (
          <TransactionDetailsModal
            transaction={selectedTransaction}
            onClose={() => {
              setShowDetails(false);
              setSelectedTransaction(null);
            }}
          />
        )}
      </div>
    </AdminLayout>
  );
}