import React, { useState, useEffect } from 'react';
import { Save, Plus, Trash2, Edit2, X, Star } from 'lucide-react';
import { ref, onValue, push, update, remove } from 'firebase/database';
import { database } from '../../../lib/firebase';
import toast from 'react-hot-toast';

interface Testimonial {
  id: string;
  name: string;
  text: string;
  role: string;
  location: string;
  sort_order: number;
  is_active: boolean;
}

export default function Testimonials() {
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const [loading, setLoading] = useState(true);
  const [showEditor, setShowEditor] = useState(false);
  const [editingTestimonial, setEditingTestimonial] = useState<Testimonial | null>(null);
  const [formData, setFormData] = useState({
    name: '',
    text: '',
    role: '',
    location: '',
    is_active: true
  });

  useEffect(() => {
    const unsubscribe = subscribeToTestimonials();
    return () => unsubscribe();
  }, []);

  const subscribeToTestimonials = () => {
    const testimonialsRef = ref(database, 'testimonials');
    return onValue(testimonialsRef, (snapshot) => {
      try {
        if (snapshot.exists()) {
          const testimonialsData = snapshot.val();
          const testimonialsArray = Object.entries(testimonialsData).map(([id, testimonial]: [string, any]) => ({
            id,
            ...testimonial
          })).sort((a, b) => a.sort_order - b.sort_order);
          setTestimonials(testimonialsArray);
        } else {
          setTestimonials([]);
        }
      } catch (error) {
        console.error('Error processing testimonials:', error);
        toast.error('Failed to load testimonials');
      } finally {
        setLoading(false);
      }
    }, (error) => {
      console.error('Error fetching testimonials:', error);
      toast.error('Failed to load testimonials');
      setLoading(false);
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (editingTestimonial) {
        const testimonialRef = ref(database, `testimonials/${editingTestimonial.id}`);
        await update(testimonialRef, {
          ...formData,
          updated_at: new Date().toISOString()
        });
        toast.success('Testimonial updated successfully');
      } else {
        const testimonialsRef = ref(database, 'testimonials');
        const newTestimonialRef = push(testimonialsRef);
        await update(newTestimonialRef, {
          ...formData,
          sort_order: testimonials.length,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        });
        toast.success('Testimonial added successfully');
      }

      setShowEditor(false);
      resetForm();
    } catch (error: any) {
      console.error('Error saving testimonial:', error);
      toast.error(error.message || 'Failed to save testimonial');
    }
  };

  const handleDelete = async (id: string) => {
    if (!confirm('Are you sure you want to delete this testimonial?')) return;

    try {
      const testimonialRef = ref(database, `testimonials/${id}`);
      await remove(testimonialRef);
      
      // Update sort order of remaining testimonials
      const remainingTestimonials = testimonials.filter(t => t.id !== id);
      const updates: { [key: string]: any } = {};
      remainingTestimonials.forEach((testimonial, index) => {
        updates[`testimonials/${testimonial.id}/sort_order`] = index;
      });
      
      if (Object.keys(updates).length > 0) {
        await update(ref(database), updates);
      }

      toast.success('Testimonial deleted successfully');
    } catch (error) {
      console.error('Error deleting testimonial:', error);
      toast.error('Failed to delete testimonial');
    }
  };

  const handleEdit = (testimonial: Testimonial) => {
    setEditingTestimonial(testimonial);
    setFormData({
      name: testimonial.name,
      text: testimonial.text,
      role: testimonial.role,
      location: testimonial.location,
      is_active: testimonial.is_active
    });
    setShowEditor(true);
  };

  const resetForm = () => {
    setFormData({
      name: '',
      text: '',
      role: '',
      location: '',
      is_active: true
    });
    setEditingTestimonial(null);
  };

  const handleToggleActive = async (testimonial: Testimonial) => {
    try {
      const testimonialRef = ref(database, `testimonials/${testimonial.id}`);
      await update(testimonialRef, {
        is_active: !testimonial.is_active,
        updated_at: new Date().toISOString()
      });
      toast.success(`Testimonial ${testimonial.is_active ? 'disabled' : 'enabled'} successfully`);
    } catch (error) {
      console.error('Error toggling testimonial status:', error);
      toast.error('Failed to update testimonial status');
    }
  };

  if (loading) {
    return (
      <div className="animate-pulse">
        <div className="h-8 bg-gray-200 rounded w-1/4 mb-8"></div>
        <div className="space-y-4">
          {[...Array(3)].map((_, i) => (
            <div key={i} className="h-24 bg-gray-200 rounded"></div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">Testimonials Management</h1>
        <button
          onClick={() => {
            resetForm();
            setShowEditor(true);
          }}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          <Plus className="w-5 h-5 mr-2" />
          Add Testimonial
        </button>
      </div>

      <div className="grid gap-6">
        {testimonials.map((testimonial) => (
          <div
            key={testimonial.id}
            className={`bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow ${
              !testimonial.is_active ? 'opacity-60' : ''
            }`}
          >
            <div className="flex justify-between items-start">
              <div className="flex-1">
                <h2 className="text-xl font-semibold text-gray-900 mb-2 flex items-center">
                  <Star className="w-5 h-5 text-yellow-500 mr-2" />
                  {testimonial.name}
                </h2>
                <p className="text-gray-600 mb-4">{testimonial.text}</p>
                <div className="text-sm text-gray-500">
                  <span className="font-medium">{testimonial.role}</span>
                  <span className="mx-2">•</span>
                  <span>{testimonial.location}</span>
                </div>
              </div>
              <div className="flex items-center space-x-2 ml-4">
                <button
                  onClick={() => handleToggleActive(testimonial)}
                  className={`px-3 py-1 rounded-lg text-sm font-medium ${
                    testimonial.is_active
                      ? 'bg-green-100 text-green-800'
                      : 'bg-gray-100 text-gray-800'
                  }`}
                >
                  {testimonial.is_active ? 'Active' : 'Inactive'}
                </button>
                <button
                  onClick={() => handleEdit(testimonial)}
                  className="p-2 text-blue-600 hover:bg-blue-50 rounded-lg"
                >
                  <Edit2 className="w-5 h-5" />
                </button>
                <button
                  onClick={() => handleDelete(testimonial.id)}
                  className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        ))}

        {testimonials.length === 0 && (
          <div className="text-center py-12 bg-white rounded-lg shadow-sm">
            <Star className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">No Testimonials Found</h3>
            <p className="text-gray-600">
              Add your first testimonial to showcase customer feedback.
            </p>
          </div>
        )}
      </div>

      {showEditor && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center overflow-y-auto">
          <div className="bg-white rounded-lg max-w-2xl w-full p-6 m-4">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">
                {editingTestimonial ? 'Edit Testimonial' : 'Add Testimonial'}
              </h2>
              <button
                onClick={() => setShowEditor(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="w-6 h-6" />
              </button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Name
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Testimonial
                </label>
                <textarea
                  value={formData.text}
                  onChange={(e) => setFormData({ ...formData, text: e.target.value })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  rows={4}
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Role
                </label>
                <input
                  type="text"
                  value={formData.role}
                  onChange={(e) => setFormData({ ...formData, role: e.target.value })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Location
                </label>
                <input
                  type="text"
                  value={formData.location}
                  onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="is_active"
                  checked={formData.is_active}
                  onChange={(e) => setFormData({ ...formData, is_active: e.target.checked })}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label htmlFor="is_active" className="ml-2 block text-sm text-gray-900">
                  Active
                </label>
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setShowEditor(false)}
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  <Save className="w-5 h-5 mr-2" />
                  {editingTestimonial ? 'Update Testimonial' : 'Add Testimonial'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}