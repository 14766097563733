import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRate } from './context/RateContext';
import { useAuth } from './context/AuthContext';
import { Loader, Power } from 'lucide-react';
import toast from 'react-hot-toast';
import type { PaymentMethod, BankAccount, TransactionProof } from './types';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { ref as dbRef, push, set, onValue } from 'firebase/database';
import { storage, database } from './lib/firebase';
import SellForm from './components/user/SellForm';
import BankDetailsForm from './components/user/BankDetailsForm';
import RecentOrders from './components/user/RecentOrders';
import Chat from './components/user/Chat';
import VideoGuide from './components/user/VideoGuide';

function User() {
  const navigate = useNavigate();
  const { currentRate } = useRate();
  const { user, loading: authLoading } = useAuth();
  const [amount, setAmount] = useState('');
  const [selectedMethod, setSelectedMethod] = useState<PaymentMethod | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<BankAccount | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [proof, setProof] = useState<TransactionProof | null>(null);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [supportOnline, setSupportOnline] = useState(true);
  const [transactionFee, setTransactionFee] = useState(0);
  const [feeType, setFeeType] = useState<'percentage' | 'flat'>('percentage');
  const [feeEnabled, setFeeEnabled] = useState(false);

  useEffect(() => {
    if (!authLoading && !user) {
      navigate('/', { replace: true });
    }
  }, [authLoading, user, navigate]);

  // Subscribe to payment methods, support status, and fee settings
  useEffect(() => {
    const settingsRef = dbRef(database, 'system_settings');
    const unsubscribe = onValue(settingsRef, (snapshot) => {
      if (snapshot.exists()) {
        const settings = snapshot.val();
        setPaymentMethods(settings.payment_methods?.filter((m: PaymentMethod) => m.isActive) || []);
        setSupportOnline(settings.support_online ?? true);
        setFeeEnabled(settings.transaction_fee_enabled || false);
        setFeeType(settings.transaction_fee_type || 'percentage');
        if (settings.transaction_fee_type === 'flat') {
          setTransactionFee(settings.transaction_fee_flat || 0);
        } else {
          setTransactionFee(settings.transaction_fee_percentage || 0);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!amount || !selectedMethod || !selectedAccount || !user || !proof) {
      toast.error('Please fill in all required fields and upload proof of payment');
      return;
    }

    const numAmount = parseFloat(amount);
    if (isNaN(numAmount) || numAmount <= 0) {
      toast.error('Please enter a valid amount');
      return;
    }

    setSubmitting(true);
    try {
      const transactionId = `TX${Date.now()}`;
      
      // Calculate fee and net amount
      const feeAmount = feeEnabled
        ? feeType === 'percentage'
          ? (numAmount * transactionFee) / 100
          : transactionFee
        : 0;
      const netAmount = numAmount - feeAmount;
      
      // Upload proof image to Firebase Storage
      const fileExtension = proof.file.name.split('.').pop();
      const proofPath = `transaction-proofs/${user.uid}/${transactionId}.${fileExtension}`;
      const proofStorageRef = storageRef(storage, proofPath);
      
      await uploadBytes(proofStorageRef, proof.file);
      const proofUrl = await getDownloadURL(proofStorageRef);

      // Create transaction record in Realtime Database
      const orderRef = push(dbRef(database, 'orders'));
      await set(orderRef, {
        user_id: user.uid,
        amount: numAmount,
        fee_amount: feeAmount,
        net_amount: netAmount,
        rate: currentRate,
        status: 'pending',
        payment_method: selectedMethod,
        bank_details: {
          account_name: selectedAccount.account_name,
          iban: selectedAccount.iban,
          bank_name: selectedAccount.bank_name
        },
        transaction_id: transactionId,
        proof_url: proofUrl,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      });

      navigate('/confirmation', {
        state: {
          transaction: {
            amount: numAmount,
            feeAmount,
            netAmount,
            rate: currentRate,
            bankDetails: {
              accountName: selectedAccount.account_name,
              iban: selectedAccount.iban,
              bankName: selectedAccount.bank_name
            },
            transactionId,
            paymentMethod: selectedMethod
          }
        }
      });
    } catch (error: any) {
      console.error('Error submitting transaction:', error);
      toast.error(error.message || 'Failed to submit transaction. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  if (authLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <Loader className="w-8 h-8 text-blue-600 animate-spin mx-auto mb-4" />
          <p className="text-gray-600">Loading your dashboard...</p>
        </div>
      </div>
    );
  }

  if (!user) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">User Dashboard</h1>
          <div className="flex items-center space-x-2">
            <div className={`w-2 h-2 rounded-full ${supportOnline ? 'bg-green-500' : 'bg-red-500'}`} />
            <span className={`text-sm font-medium ${supportOnline ? 'text-green-700' : 'text-red-700'}`}>
              Support {supportOnline ? 'Online' : 'Offline'}
            </span>
          </div>
        </div>
        
        <VideoGuide />

        <div className="grid md:grid-cols-2 gap-8">
          <SellForm
            amount={amount}
            onAmountChange={setAmount}
            selectedMethod={selectedMethod}
            onMethodSelect={setSelectedMethod}
            paymentMethods={paymentMethods}
            onSubmit={handleSubmit}
            proof={proof}
            onProofChange={setProof}
            submitting={submitting}
          />
          
          <BankDetailsForm
            selectedAccount={selectedAccount}
            onAccountSelect={setSelectedAccount}
          />
        </div>

        <RecentOrders />
      </div>

      <Chat />
    </div>
  );
}

export default User;
