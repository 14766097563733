import React, { useState, useRef } from 'react';
import { useAuth } from './context/AuthContext';
import { UserCircle, Phone, Shield, ArrowLeft, Upload, X, Camera, CheckCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { ref as dbRef, update } from 'firebase/database';
import { storage, database } from './lib/firebase';
import toast from 'react-hot-toast';

export default function Profile() {
  const { profile, updateProfile } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    full_name: profile?.full_name || '',
    phone_number: profile?.phone_number || ''
  });
  const [cnicFront, setCnicFront] = useState<File | null>(null);
  const [cnicBack, setCnicBack] = useState<File | null>(null);
  const [cnicFrontPreview, setCnicFrontPreview] = useState<string | null>(null);
  const [cnicBackPreview, setCnicBackPreview] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const frontInputRef = useRef<HTMLInputElement>(null);
  const backInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await updateProfile(formData);
      setIsEditing(false);
      toast.success('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Failed to update profile');
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'front' | 'back') => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Validate file type
    if (!file.type.startsWith('image/')) {
      toast.error('Please upload an image file');
      return;
    }

    // Validate file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      toast.error('File size should be less than 5MB');
      return;
    }

    // Create preview
    const reader = new FileReader();
    reader.onloadend = () => {
      if (type === 'front') {
        setCnicFront(file);
        setCnicFrontPreview(reader.result as string);
      } else {
        setCnicBack(file);
        setCnicBackPreview(reader.result as string);
      }
    };
    reader.readAsDataURL(file);
  };

  const handleUploadCnic = async () => {
    if (!cnicFront || !cnicBack || !profile) {
      toast.error('Please select both front and back images of your CNIC');
      return;
    }

    try {
      setUploading(true);

      // Upload front image
      const frontRef = storageRef(storage, `kyc/${profile.id}/cnic_front.jpg`);
      await uploadBytes(frontRef, cnicFront);
      const frontUrl = await getDownloadURL(frontRef);

      // Upload back image
      const backRef = storageRef(storage, `kyc/${profile.id}/cnic_back.jpg`);
      await uploadBytes(backRef, cnicBack);
      const backUrl = await getDownloadURL(backRef);

      // Update profile with CNIC images and set KYC status to pending
      const userRef = dbRef(database, `profiles/${profile.id}`);
      await update(userRef, {
        cnic_front_url: frontUrl,
        cnic_back_url: backUrl,
        kyc_status: 'pending',
        updated_at: new Date().toISOString()
      });

      toast.success('CNIC images uploaded successfully. Your KYC verification is pending.');

      // Reset file inputs
      setCnicFront(null);
      setCnicBack(null);
      setCnicFrontPreview(null);
      setCnicBackPreview(null);
      if (frontInputRef.current) frontInputRef.current.value = '';
      if (backInputRef.current) backInputRef.current.value = '';
    } catch (error) {
      console.error('Error uploading CNIC:', error);
      toast.error('Failed to upload CNIC images. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <Link to="/user" className="inline-flex items-center text-blue-600 hover:text-blue-800 mb-6">
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Dashboard
        </Link>

        <div className="bg-white rounded-lg shadow-sm overflow-hidden">
          {/* Header */}
          <div className="bg-gradient-to-r from-blue-600 to-indigo-600 p-6">
            <div className="flex items-center">
              <UserCircle className="w-16 h-16 text-white opacity-80" />
              <div className="ml-4">
                <h1 className="text-2xl font-bold text-white">Profile Settings</h1>
                <p className="text-blue-100">Manage your account information</p>
              </div>
            </div>
          </div>

          {/* Profile Status */}
          <div className="p-6 border-b border-gray-200 bg-blue-50">
            <div className="flex items-center">
              <Shield className="w-6 h-6 text-blue-600 mr-3" />
              <div>
                <h3 className="font-medium text-blue-900">Account Status</h3>
                <p className="text-blue-700">
                  KYC Status: <span className="font-medium capitalize">{profile?.kyc_status}</span>
                </p>
              </div>
            </div>
          </div>

          {/* Profile Form */}
          <div className="p-6 space-y-8">
            <form onSubmit={handleSubmit}>
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Full Name
                  </label>
                  {isEditing ? (
                    <input
                      type="text"
                      value={formData.full_name}
                      onChange={(e) => setFormData({ ...formData, full_name: e.target.value })}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Enter your full name"
                    />
                  ) : (
                    <p className="text-gray-900">{profile?.full_name || 'Not set'}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Phone Number
                  </label>
                  {isEditing ? (
                    <div className="relative">
                      <Phone className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                      <input
                        type="tel"
                        value={formData.phone_number}
                        onChange={(e) => setFormData({ ...formData, phone_number: e.target.value })}
                        className="w-full pl-12 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="+92 300 1234567"
                      />
                    </div>
                  ) : (
                    <p className="text-gray-900">{profile?.phone_number || 'Not set'}</p>
                  )}
                </div>

                <div className="flex justify-end space-x-4">
                  {isEditing ? (
                    <>
                      <button
                        type="button"
                        onClick={() => setIsEditing(false)}
                        className="px-4 py-2 text-gray-700 hover:text-gray-900"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="px-6 py-2 bg-blue-600 text-white rounded-lg font-semibold hover:bg-blue-700 transition-colors"
                      >
                        Save Changes
                      </button>
                    </>
                  ) : (
                    <button
                      type="button"
                      onClick={() => setIsEditing(true)}
                      className="px-6 py-2 bg-blue-600 text-white rounded-lg font-semibold hover:bg-blue-700 transition-colors"
                    >
                      Edit Profile
                    </button>
                  )}
                </div>
              </div>
            </form>

            {/* KYC Section */}
            <div className="border-t pt-8">
              <h2 className="text-lg font-semibold mb-4 flex items-center">
                <Shield className="w-5 h-5 mr-2 text-blue-600" />
                KYC Verification
              </h2>

              {profile?.kyc_status === 'pending' ? (
                <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
                  <p className="text-yellow-800">
                    Your KYC verification is pending. Our team will review your documents and update your status soon.
                  </p>
                </div>
              ) : profile?.kyc_status === 'verified' ? (
                <div className="bg-green-50 border border-green-200 rounded-lg p-4">
                  <p className="text-green-800 flex items-center">
                    <CheckCircle className="w-5 h-5 mr-2" />
                    Your account is verified. You can now use all platform features.
                  </p>
                </div>
              ) : profile?.kyc_status === 'rejected' ? (
                <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-4">
                  <p className="text-red-800">
                    Your KYC verification was rejected. Please upload clear images of your CNIC and try again.
                  </p>
                </div>
              ) : null}

              {profile?.kyc_status !== 'verified' && (
                <div className="mt-4 space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      CNIC Front
                    </label>
                    <div className="mt-1 flex items-center space-x-4">
                      {cnicFrontPreview ? (
                        <div className="relative">
                          <img
                            src={cnicFrontPreview}
                            alt="CNIC Front"
                            className="max-h-40 rounded-lg"
                          />
                          <button
                            type="button"
                            onClick={() => {
                              setCnicFront(null);
                              setCnicFrontPreview(null);
                              if (frontInputRef.current) frontInputRef.current.value = '';
                            }}
                            className="absolute -top-2 -right-2 p-1 bg-red-100 text-red-600 rounded-full hover:bg-red-200"
                          >
                            <X className="w-4 h-4" />
                          </button>
                        </div>
                      ) : (
                        <div>
                          <input
                            type="file"
                            ref={frontInputRef}
                            onChange={(e) => handleFileChange(e, 'front')}
                            accept="image/*"
                            className="hidden"
                          />
                          <button
                            type="button"
                            onClick={() => frontInputRef.current?.click()}
                            className="flex items-center px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
                          >
                            <Camera className="w-5 h-5 mr-2 text-gray-400" />
                            Upload Front
                          </button>
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      CNIC Back
                    </label>
                    <div className="mt-1 flex items-center space-x-4">
                      {cnicBackPreview ? (
                        <div className="relative">
                          <img
                            src={cnicBackPreview}
                            alt="CNIC Back"
                            className="max-h-40 rounded-lg"
                          />
                          <button
                            type="button"
                            onClick={() => {
                              setCnicBack(null);
                              setCnicBackPreview(null);
                              if (backInputRef.current) backInputRef.current.value = '';
                            }}
                            className="absolute -top-2 -right-2 p-1 bg-red-100 text-red-600 rounded-full hover:bg-red-200"
                          >
                            <X className="w-4 h-4" />
                          </button>
                        </div>
                      ) : (
                        <div>
                          <input
                            type="file"
                            ref={backInputRef}
                            onChange={(e) => handleFileChange(e, 'back')}
                            accept="image/*"
                            className="hidden"
                          />
                          <button
                            type="button"
                            onClick={() => backInputRef.current?.click()}
                            className="flex items-center px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
                          >
                            <Camera className="w-5 h-5 mr-2 text-gray-400" />
                            Upload Back
                          </button>
                        </div>
                      )}
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={handleUploadCnic}
                    disabled={!cnicFront || !cnicBack || uploading}
                    className="w-full mt-4 flex items-center justify-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {uploading ? (
                      <>
                        <Upload className="w-5 h-5 mr-2 animate-spin" />
                        Uploading...
                      </>
                    ) : (
                      <>
                        <Upload className="w-5 h-5 mr-2" />
                        Submit for Verification
                      </>
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}