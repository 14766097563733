import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, Clock, User, Tag } from 'lucide-react';
import { Helmet } from 'react-helmet-async';

export default function Blog() {
  const posts = [
    {
      id: 'usdt-trading-pakistan-guide',
      title: 'Complete Guide to USDT Trading in Pakistan: Tips, Tricks, and Best Practices',
      excerpt: 'Learn everything you need to know about safely trading USDT in Pakistan. From choosing the right platform to understanding regulations and maximizing your returns.',
      author: 'Crypto Expert',
      date: 'March 14, 2024',
      readTime: '8 min read',
      tags: ['USDT', 'Trading', 'Pakistan', 'Cryptocurrency'],
      image: 'https://images.unsplash.com/photo-1621761191319-c6fb62004040?auto=format&fit=crop&q=80',
      metaTitle: 'Complete Guide to USDT Trading in Pakistan (2024) | Safe Trading Tips',
      metaDescription: 'Master USDT trading in Pakistan with our comprehensive guide. Learn about secure platforms, best practices, regulations, and how to get the best rates in 2024.'
    },
    {
      id: 'crypto-regulations-pakistan',
      title: 'Understanding Cryptocurrency Regulations in Pakistan: Current Status and Future Outlook',
      excerpt: 'Stay informed about the latest cryptocurrency regulations in Pakistan. Learn about the legal framework, compliance requirements, and what it means for traders.',
      author: 'Legal Analyst',
      date: 'March 13, 2024',
      readTime: '6 min read',
      tags: ['Regulations', 'Pakistan', 'Cryptocurrency', 'Legal'],
      image: 'https://images.unsplash.com/photo-1631603090989-93f9ef6f9d80?auto=format&fit=crop&q=80',
      metaTitle: 'Cryptocurrency Regulations in Pakistan 2024 | Legal Status & Guidelines',
      metaDescription: 'Get the latest updates on cryptocurrency regulations in Pakistan. Understand the legal framework, compliance requirements, and future outlook for digital assets.'
    },
    {
      id: 'secure-crypto-transactions',
      title: 'How to Secure Your Cryptocurrency Transactions: Essential Security Measures',
      excerpt: 'Protect your crypto assets with our comprehensive security guide. Learn about best practices, common scams to avoid, and tools to keep your transactions safe.',
      author: 'Security Specialist',
      date: 'March 12, 2024',
      readTime: '7 min read',
      tags: ['Security', 'Cryptocurrency', 'Protection', 'Best Practices'],
      image: 'https://images.unsplash.com/photo-1639762681485-074b7f938ba0?auto=format&fit=crop&q=80',
      metaTitle: 'Cryptocurrency Security Guide 2024 | Protect Your Digital Assets',
      metaDescription: 'Learn essential security measures for cryptocurrency transactions. Protect your digital assets with our expert guide on best practices and scam prevention.'
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>Crypto Blog | Latest USDT Trading Insights and News</title>
        <meta 
          name="description" 
          content="Stay informed with the latest cryptocurrency news, trading insights, and expert analysis. Learn about USDT trading, regulations, and security in Pakistan."
        />
        <meta 
          name="keywords" 
          content="cryptocurrency blog, USDT trading, crypto news Pakistan, digital assets, crypto security"
        />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <Link to="/" className="inline-flex items-center text-blue-600 hover:text-blue-800 mb-8">
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Home
        </Link>

        <h1 className="text-4xl font-bold text-gray-900 mb-8">Latest Articles</h1>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {posts.map((post) => (
            <Link 
              key={post.id}
              to={`/blog/${post.id}`}
              className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-shadow"
            >
              <img
                src={post.image}
                alt={post.title}
                className="w-full h-48 object-cover"
                loading="lazy"
              />
              <div className="p-6">
                <div className="flex flex-wrap gap-2 mb-3">
                  {post.tags.map((tag) => (
                    <span
                      key={tag}
                      className="text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded-full"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
                <h2 className="text-xl font-semibold text-gray-900 mb-2">
                  {post.title}
                </h2>
                <p className="text-gray-600 mb-4">
                  {post.excerpt}
                </p>
                <div className="flex items-center text-sm text-gray-500">
                  <User className="w-4 h-4 mr-1" />
                  <span className="mr-4">{post.author}</span>
                  <Clock className="w-4 h-4 mr-1" />
                  <span className="mr-4">{post.readTime}</span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}