import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LogIn, LogOut, User as UserIcon, LayoutDashboard, Menu, X } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { ref, onValue } from 'firebase/database';
import { database } from '../lib/firebase';
import AuthModal from './auth/AuthModal';
import type { SystemSettings } from '../types';

export default function Header() {
  const { user, profile, signOut } = useAuth();
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [settings, setSettings] = useState<SystemSettings>({
    id: 1,
    maintenance_mode: false,
    min_transaction_amount: 100,
    max_transaction_amount: 100000,
    transaction_fee_enabled: false,
    transaction_fee_type: 'percentage',
    transaction_fee_percentage: 1,
    transaction_fee_flat: 1,
    email_notifications: true,
    support_email: '',
    support_phone: '',
    support_hours: '',
    kyc_required: true,
    auto_approve_verified_users: false,
    company_name: '',
    company_logo: '',
    company_favicon: '',
    company_description: '',
    meta_title: '',
    meta_description: '',
    openai_api_key: '',
    payment_methods: [],
    social_media: {
      facebook: { url: '', enabled: false },
      twitter: { url: '', enabled: false },
      tiktok: { url: '', enabled: false },
      instagram: { url: '', enabled: false }
    }
  });

  useEffect(() => {
    const settingsRef = ref(database, 'system_settings');
    const unsubscribe = onValue(settingsRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setSettings(data);

        // Update document title and meta tags
        document.title = data.meta_title;
        updateMetaTags(data);

        // Update favicon if provided
        if (data.company_favicon) {
          const faviconLink = document.getElementById('dynamic-favicon') as HTMLLinkElement;
          if (faviconLink) {
            faviconLink.href = data.company_favicon;
          }
        }
      }
    }, (error) => {
      console.error('Error fetching settings:', error);
    });

    return () => unsubscribe();
  }, []);

  const updateMetaTags = (settings: SystemSettings) => {
    // Update meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', settings.meta_description);
    }

    // Update Open Graph meta tags
    const ogTitle = document.querySelector('meta[property="og:title"]');
    if (ogTitle) {
      ogTitle.setAttribute('content', settings.meta_title);
    }

    const ogDescription = document.querySelector('meta[property="og:description"]');
    if (ogDescription) {
      ogDescription.setAttribute('content', settings.meta_description);
    }

    // Update Twitter meta tags
    const twitterTitle = document.querySelector('meta[property="twitter:title"]');
    if (twitterTitle) {
      twitterTitle.setAttribute('content', settings.meta_title);
    }

    const twitterDescription = document.querySelector('meta[property="twitter:description"]');
    if (twitterDescription) {
      twitterDescription.setAttribute('content', settings.meta_description);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/', { replace: true });
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const isActivePath = (path: string) => location.pathname === path;
  const isAdmin = profile?.role === 'admin';

  return (
    <header className="bg-white shadow-sm sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              {settings.company_logo ? (
                <img 
                  src="https://ik.imagekit.io/vv0raaunt/Exchange/pak-exchange-logo.png?tr=w-200,h-50,q-90,f-webp"
                  alt={settings.company_name}
                  className="h-12 w-auto"
                  width="200"
                  height="50"
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.src = '';
                  }}
                />
              ) : (
                <span className="text-xl font-bold text-blue-600">
                  {settings.company_name}
                </span>
              )}
            </Link>
          </div>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center space-x-4">
            {user && (
              <>
                <Link
                  to="/user"
                  className={`px-3 py-2 rounded-md text-sm font-medium flex items-center ${
                    isActivePath('/user')
                      ? 'text-blue-600 bg-blue-50'
                      : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                  }`}
                >
                  <LayoutDashboard className="w-4 h-4 mr-1.5" />
                  Dashboard
                </Link>
                {isAdmin && (
                  <Link
                    to="/admin/dashboard"
                    className={`px-3 py-2 rounded-md text-sm font-medium flex items-center ${
                      location.pathname.startsWith('/admin')
                        ? 'text-blue-600 bg-blue-50'
                        : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    <LayoutDashboard className="w-4 h-4 mr-1.5" />
                    Admin
                  </Link>
                )}
              </>
            )}
          </nav>

          {/* User Actions */}
          <div className="hidden md:flex items-center space-x-4">
            {user ? (
              <div className="flex items-center space-x-4">
                <Link
                  to="/profile"
                  className={`flex items-center text-sm font-medium px-3 py-2 rounded-md ${
                    isActivePath('/profile')
                      ? 'text-blue-600 bg-blue-50'
                      : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                  }`}
                >
                  <UserIcon className="w-4 h-4 mr-1.5" />
                  Profile
                </Link>
                <button
                  onClick={handleSignOut}
                  className="flex items-center text-sm font-medium px-3 py-2 rounded-md text-gray-500 hover:text-gray-700 hover:bg-gray-50"
                >
                  <LogOut className="w-4 h-4 mr-1.5" />
                  Sign Out
                </button>
              </div>
            ) : (
              <button
                onClick={() => setIsAuthModalOpen(true)}
                className="flex items-center text-sm font-medium px-4 py-2 rounded-md bg-blue-600 text-white hover:bg-blue-700 transition-colors btn-reflection"
              >
                <LogIn className="w-4 h-4 mr-1.5" />
                Sign In
              </button>
            )}
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="p-2 rounded-md text-gray-500 hover:text-gray-700 hover:bg-gray-100"
            >
              {isMobileMenuOpen ? (
                <X className="w-6 h-6" />
              ) : (
                <Menu className="w-6 h-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden border-t border-gray-200">
          <div className="px-2 pt-2 pb-3 space-y-1">
            {user && (
              <>
                <Link
                  to="/user"
                  className={`block px-3 py-2 rounded-md text-base font-medium flex items-center ${
                    isActivePath('/user')
                      ? 'text-blue-600 bg-blue-50'
                      : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                  }`}
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  <LayoutDashboard className="w-5 h-5 mr-2" />
                  Dashboard
                </Link>
                {isAdmin && (
                  <Link
                    to="/admin/dashboard"
                    className={`block px-3 py-2 rounded-md text-base font-medium flex items-center ${
                      location.pathname.startsWith('/admin')
                        ? 'text-blue-600 bg-blue-50'
                        : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                    }`}
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    <LayoutDashboard className="w-5 h-5 mr-2" />
                    Admin
                  </Link>
                )}
              </>
            )}

            {user ? (
              <>
                <Link
                  to="/profile"
                  className={`block px-3 py-2 rounded-md text-base font-medium flex items-center ${
                    isActivePath('/profile')
                      ? 'text-blue-600 bg-blue-50'
                      : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                  }`}
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  <UserIcon className="w-5 h-5 mr-2" />
                  Profile
                </Link>
                <button
                  onClick={() => {
                    handleSignOut();
                    setIsMobileMenuOpen(false);
                  }}
                  className="w-full text-left px-3 py-2 rounded-md text-base font-medium flex items-center text-gray-500 hover:text-gray-700 hover:bg-gray-50"
                >
                  <LogOut className="w-5 h-5 mr-2" />
                  Sign Out
                </button>
              </>
            ) : (
              <button
                onClick={() => {
                  setIsAuthModalOpen(true);
                  setIsMobileMenuOpen(false);
                }}
                className="w-full px-3 py-2 rounded-md text-base font-medium flex items-center text-blue-600 hover:text-blue-700 hover:bg-blue-50 btn-reflection"
              >
                <LogIn className="w-5 h-5 mr-2" />
                Sign In
              </button>
            )}
          </div>
        </div>
      )}

      <AuthModal
        isOpen={isAuthModalOpen}
        onClose={() => setIsAuthModalOpen(false)}
      />
    </header>
  );
}