import React from 'react';
import { DollarSign, Ban as Bank, Lock, ArrowRight } from 'lucide-react';

export default function HowItWorks() {
  const steps = [
    {
      icon: DollarSign,
      title: 'Enter Amount',
      description: 'Enter the amount of USDT you want to sell',
      color: 'blue',
    },
    {
      icon: Bank,
      title: 'Bank Details',
      description: 'Provide your Pakistani bank account details',
      color: 'purple',
    },
    {
      icon: Lock,
      title: 'Secure Transfer',
      description: 'Send USDT through our secure platform',
      color: 'green',
    },
    {
      icon: ArrowRight,
      title: 'Receive PKR',
      description: 'Get instant PKR transfer to your bank',
      color: 'orange',
    },
  ];

  return (
    <div className="py-20 bg-gradient-to-b from-indigo-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-12 text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-indigo-600">
          How to Sell USDT in Pakistan
        </h2>
        <div className="grid md:grid-cols-4 gap-8">
          {steps.map((step, index) => (
            <div key={index} className="text-center transform hover:scale-105 transition-transform duration-200">
              <div className={`w-16 h-16 bg-gradient-to-br from-${step.color}-100 to-${step.color === 'orange' ? 'amber' : step.color}-100 rounded-2xl flex items-center justify-center mx-auto mb-4 shadow-lg`}>
                <step.icon className={`w-8 h-8 text-${step.color}-600`} />
              </div>
              <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
              <p className="text-gray-600">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}