import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import {
  Shield,
  Lock,
  Key,
  AlertTriangle,
  CheckCircle,
  XCircle,
  Clock,
  Search,
  Filter,
  RefreshCcw
} from 'lucide-react';
import { ref, onValue, query, orderByChild } from 'firebase/database';
import { database } from '../../lib/firebase';
import toast from 'react-hot-toast';

interface SecurityLog {
  id: string;
  event_type: string;
  user_id: string | null;
  ip_address: string;
  user_agent: string;
  status: 'success' | 'failed';
  details: any;
  created_at: string;
}

interface SecurityStats {
  totalEvents: number;
  failedAttempts: number;
  successfulLogins: number;
  suspiciousActivities: number;
}

export default function Security() {
  const [logs, setLogs] = useState<SecurityLog[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [eventFilter, setEventFilter] = useState<'all' | 'login' | 'password_reset' | 'suspicious'>('all');
  const [stats, setStats] = useState<SecurityStats>({
    totalEvents: 0,
    failedAttempts: 0,
    successfulLogins: 0,
    suspiciousActivities: 0
  });

  useEffect(() => {
    const unsubscribe = subscribeToSecurityLogs();
    return () => unsubscribe();
  }, []);

  const subscribeToSecurityLogs = () => {
    const logsRef = ref(database, 'security_logs');
    const logsQuery = query(logsRef, orderByChild('created_at'));
    
    return onValue(logsQuery, (snapshot) => {
      try {
        if (snapshot.exists()) {
          const logsData = snapshot.val();
          const logsArray = Object.entries(logsData).map(([id, log]: [string, any]) => ({
            id,
            ...log
          })).sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

          setLogs(logsArray);

          // Calculate stats
          const totalEvents = logsArray.length;
          const failedAttempts = logsArray.filter(log => log.status === 'failed').length;
          const successfulLogins = logsArray.filter(log => 
            log.event_type === 'login' && log.status === 'success'
          ).length;
          const suspiciousActivities = logsArray.filter(log => 
            log.event_type === 'suspicious_activity'
          ).length;

          setStats({
            totalEvents,
            failedAttempts,
            successfulLogins,
            suspiciousActivities
          });
        } else {
          setLogs([]);
          setStats({
            totalEvents: 0,
            failedAttempts: 0,
            successfulLogins: 0,
            suspiciousActivities: 0
          });
        }
      } catch (error) {
        console.error('Error processing security logs:', error);
        toast.error('Failed to load security logs');
      } finally {
        setLoading(false);
      }
    }, (error) => {
      console.error('Error fetching security logs:', error);
      toast.error('Failed to load security logs');
      setLoading(false);
    });
  };

  const getEventIcon = (eventType: string) => {
    switch (eventType) {
      case 'login':
        return <Key className="w-5 h-5 text-blue-500" />;
      case 'password_reset':
        return <Lock className="w-5 h-5 text-orange-500" />;
      case 'suspicious_activity':
        return <AlertTriangle className="w-5 h-5 text-red-500" />;
      default:
        return <Shield className="w-5 h-5 text-gray-500" />;
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'success':
        return <CheckCircle className="w-5 h-5 text-green-500" />;
      case 'failed':
        return <XCircle className="w-5 h-5 text-red-500" />;
      default:
        return <Clock className="w-5 h-5 text-yellow-500" />;
    }
  };

  const filteredLogs = logs.filter(log => {
    const matchesSearch = 
      (log.user_id?.toString() || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (log.ip_address || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (log.event_type || '').toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesFilter = eventFilter === 'all' || (log.event_type || '').includes(eventFilter);

    return matchesSearch && matchesFilter;
  });

  if (loading) {
    return (
      <AdminLayout>
        <div className="animate-pulse">
          <div className="h-8 bg-gray-200 rounded w-1/4 mb-8"></div>
          <div className="grid grid-cols-4 gap-4 mb-8">
            {[...Array(4)].map((_, i) => (
              <div key={i} className="h-32 bg-gray-200 rounded"></div>
            ))}
          </div>
          <div className="space-y-4">
            {[...Array(5)].map((_, i) => (
              <div key={i} className="h-24 bg-gray-200 rounded"></div>
            ))}
          </div>
        </div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <div className="space-y-8">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-gray-900">Security Monitoring</h1>
          <button
            onClick={() => subscribeToSecurityLogs()}
            className="flex items-center px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
          >
            <RefreshCcw className="w-5 h-5 mr-2" />
            Refresh
          </button>
        </div>

        {/* Security Stats */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-600 mb-1">Total Events</p>
                <h3 className="text-2xl font-bold text-gray-900">{stats.totalEvents}</h3>
              </div>
              <div className="p-3 bg-blue-50 rounded-full">
                <Shield className="w-6 h-6 text-blue-600" />
              </div>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-sm">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-600 mb-1">Failed Attempts</p>
                <h3 className="text-2xl font-bold text-gray-900">{stats.failedAttempts}</h3>
              </div>
              <div className="p-3 bg-red-50 rounded-full">
                <XCircle className="w-6 h-6 text-red-600" />
              </div>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-sm">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-600 mb-1">Successful Logins</p>
                <h3 className="text-2xl font-bold text-gray-900">{stats.successfulLogins}</h3>
              </div>
              <div className="p-3 bg-green-50 rounded-full">
                <CheckCircle className="w-6 h-6 text-green-600" />
              </div>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-sm">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-600 mb-1">Suspicious Activities</p>
                <h3 className="text-2xl font-bold text-gray-900">{stats.suspiciousActivities}</h3>
              </div>
              <div className="p-3 bg-yellow-50 rounded-full">
                <AlertTriangle className="w-6 h-6 text-yellow-600" />
              </div>
            </div>
          </div>
        </div>

        {/* Security Logs */}
        <div className="bg-white rounded-lg shadow-sm">
          <div className="p-6 border-b border-gray-200">
            <div className="flex flex-wrap gap-4">
              <div className="flex-1">
                <div className="relative">
                  <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                  <input
                    type="text"
                    placeholder="Search logs..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <Filter className="w-5 h-5 text-gray-400" />
                <select
                  value={eventFilter}
                  onChange={(e) => setEventFilter(e.target.value as any)}
                  className="px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="all">All Events</option>
                  <option value="login">Login Events</option>
                  <option value="password_reset">Password Resets</option>
                  <option value="suspicious">Suspicious Activity</option>
                </select>
              </div>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Event
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    User ID
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    IP Address
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date & Time
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Details
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredLogs.map((log) => (
                  <tr key={log.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        {getEventIcon(log.event_type)}
                        <span className="ml-2 text-sm font-medium text-gray-900">
                          {log.event_type}
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {log.user_id || 'N/A'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {log.ip_address}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        {getStatusIcon(log.status)}
                        <span className={`ml-2 text-sm font-medium ${
                          log.status === 'success' ? 'text-green-800' : 'text-red-800'
                        }`}>
                          {log.status}
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {new Date(log.created_at).toLocaleString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <pre className="font-mono">
                        {JSON.stringify(log.details, null, 2)}
                      </pre>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}