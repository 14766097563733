import React, { useState, useEffect } from 'react';
import { AlertTriangle, Shield, UserCheck } from 'lucide-react';
import { ref, onValue } from 'firebase/database';
import { database } from '../../lib/firebase';
import * as icons from 'lucide-react';

interface SecurityWarning {
  id: string;
  title: string;
  description: string;
  icon: string;
  category: 'scams' | 'security' | 'protection';
  sort_order: number;
  is_active: boolean;
}

export default function SecurityWarning() {
  const [warnings, setWarnings] = useState<SecurityWarning[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const warningsRef = ref(database, 'security_warnings');
    const unsubscribe = onValue(warningsRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const warningsArray = Object.entries(data).map(([id, warning]: [string, any]) => ({
          id,
          ...warning
        })).filter(warning => warning.is_active)
          .sort((a, b) => a.sort_order - b.sort_order);
        setWarnings(warningsArray);
      }
      setLoading(false);
    }, (error) => {
      console.error('Error fetching security warnings:', error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const getIcon = (iconName: string) => {
    const Icon = (icons as any)[iconName];
    return Icon ? <Icon className="w-12 h-12" /> : null;
  };

  const getCategoryColor = (category: SecurityWarning['category']) => {
    switch (category) {
      case 'scams':
        return {
          bg: 'from-red-50 to-orange-50',
          text: 'text-red-500',
          border: 'border-red-100'
        };
      case 'security':
        return {
          bg: 'from-green-50 to-emerald-50',
          text: 'text-green-500',
          border: 'border-green-100'
        };
      case 'protection':
        return {
          bg: 'from-blue-50 to-indigo-50',
          text: 'text-blue-500',
          border: 'border-blue-100'
        };
    }
  };

  if (loading) {
    return (
      <div className="py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="animate-pulse">
            <div className="h-8 bg-gray-200 rounded w-1/4 mx-auto mb-8"></div>
            <div className="grid md:grid-cols-3 gap-8">
              {[...Array(3)].map((_, i) => (
                <div key={i} className="h-48 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id="security" className="py-20 scroll-mt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-red-600 to-orange-600">
            Stay Safe from USDT Scams
          </h2>
          <p className="text-gray-600">
            Learn how to protect yourself from common cryptocurrency scams in Pakistan
          </p>
        </div>
        <div className="grid md:grid-cols-3 gap-8">
          {warnings.map((warning) => {
            const colors = getCategoryColor(warning.category);
            return (
              <div
                key={warning.id}
                className={`bg-gradient-to-br ${colors.bg} p-8 rounded-xl shadow-sm border ${colors.border}`}
              >
                <div className={`flex justify-start mb-4 ${colors.text}`}>
                  {getIcon(warning.icon)}
                </div>
                <h3 className="text-xl font-semibold mb-4 text-gray-900">
                  {warning.title}
                </h3>
                <ul className="text-gray-700 space-y-3">
                  {warning.description.split(',').map((item, index) => (
                    <li key={index} className="flex items-center">
                      <span className={`w-1.5 h-1.5 rounded-full mr-2 ${colors.text.replace('text', 'bg')}`} />
                      {item.trim()}
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}