import React, { useState, useEffect, useMemo } from 'react';
import { TrendingUp, TrendingDown } from 'lucide-react';

interface CryptoPrice {
  symbol: string;
  price: string;
  priceChange: string;
  icon: string;
}

const CryptoTicker: React.FC = () => {
  const [prices, setPrices] = useState<CryptoPrice[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fallbackPrices: CryptoPrice[] = useMemo(() => [
    { symbol: 'BTC', price: '43000.00', priceChange: '2.5', icon: getCryptoIcon('BTCUSDT') },
    { symbol: 'ETH', price: '2200.00', priceChange: '1.8', icon: getCryptoIcon('ETHUSDT') },
    { symbol: 'USDT', price: '1.00', priceChange: '0.0', icon: getCryptoIcon('USDTUSDT') },
    { symbol: 'BNB', price: '320.00', priceChange: '1.2', icon: getCryptoIcon('BNBUSDT') },
    { symbol: 'SOL', price: '95.00', priceChange: '3.5', icon: getCryptoIcon('SOLUSDT') }
  ], []);

  useEffect(() => {
    const controller = new AbortController();
    let retryCount = 0;
    const maxRetries = 3;
    const retryDelay = 1000;

    const fetchPrices = async () => {
      try {
        const response = await fetch(
          'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,tether,binancecoin,solana&vs_currencies=usd&include_24hr_change=true',
          {
            signal: controller.signal,
            headers: {
              'Accept': 'application/json',
            },
            cache: 'no-store'
          }
        );
        
        if (!response.ok) {
          if (response.status === 429) {
            throw new Error('Rate limit exceeded');
          }
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        const cryptoMapping = {
          'bitcoin': { symbol: 'BTC', icon: 'BTCUSDT' },
          'ethereum': { symbol: 'ETH', icon: 'ETHUSDT' },
          'tether': { symbol: 'USDT', icon: 'USDTUSDT' },
          'binancecoin': { symbol: 'BNB', icon: 'BNBUSDT' },
          'solana': { symbol: 'SOL', icon: 'SOLUSDT' }
        };

        const formattedPrices = Object.entries(data).map(([id, details]: [string, any]) => ({
          symbol: cryptoMapping[id as keyof typeof cryptoMapping].symbol,
          price: details.usd.toFixed(2),
          priceChange: details.usd_24h_change?.toFixed(2) || '0.00',
          icon: getCryptoIcon(cryptoMapping[id as keyof typeof cryptoMapping].icon)
        }));

        setPrices(formattedPrices);
        setError(false);
        retryCount = 0;
      } catch (error: any) {
        if (error.name === 'AbortError') {
          return;
        }

        console.error('Error fetching prices:', error);
        
        if (retryCount < maxRetries) {
          retryCount++;
          console.log(`Retry attempt ${retryCount} of ${maxRetries}`);
          setTimeout(fetchPrices, retryDelay * retryCount);
          return;
        }

        console.log('Using fallback prices after all retries failed');
        setPrices(fallbackPrices);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchPrices();
    const interval = setInterval(fetchPrices, 30000);

    return () => {
      controller.abort();
      clearInterval(interval);
    };
  }, [fallbackPrices]);

  function getCryptoIcon(symbol: string): string {
    const icons: { [key: string]: string } = {
      'BTCUSDT': 'https://cryptologos.cc/logos/bitcoin-btc-logo.svg',
      'ETHUSDT': 'https://cryptologos.cc/logos/ethereum-eth-logo.svg',
      'USDTUSDT': 'https://cryptologos.cc/logos/tether-usdt-logo.svg',
      'BNBUSDT': 'https://cryptologos.cc/logos/bnb-bnb-logo.svg',
      'SOLUSDT': 'https://cryptologos.cc/logos/solana-sol-logo.svg'
    };
    return icons[symbol] || '';
  }

  if (loading) {
    return (
      <div className="w-full bg-gray-900 py-3">
        <div className="max-w-7xl mx-auto px-4">
          <div className="animate-pulse flex justify-between">
            {[...Array(5)].map((_, i) => (
              <div key={i} className="h-8 w-32 bg-gray-700 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full bg-gray-900 py-3">
      <div className="relative overflow-hidden">
        <div className="ticker-container">
          <div className="ticker-wrapper">
            {[...prices, ...prices, ...prices].map((crypto, index) => (
              <div
                key={`${crypto.symbol}-${index}`}
                className="inline-flex items-center space-x-2 bg-gray-800 rounded-lg px-4 py-2 mx-2"
              >
                <img
                  src={crypto.icon}
                  alt={crypto.symbol}
                  className="w-6 h-6"
                  loading="lazy"
                />
                <span className="text-gray-200 font-medium min-w-[40px]">{crypto.symbol}</span>
                <span className="text-gray-200 min-w-[80px] text-right">${crypto.price}</span>
                <span
                  className={`flex items-center min-w-[70px] ${
                    parseFloat(crypto.priceChange) >= 0
                      ? 'text-green-400'
                      : 'text-red-400'
                  }`}
                >
                  {parseFloat(crypto.priceChange) >= 0 ? (
                    <TrendingUp className="w-4 h-4 mr-1" />
                  ) : (
                    <TrendingDown className="w-4 h-4 mr-1" />
                  )}
                  {Math.abs(parseFloat(crypto.priceChange))}%
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CryptoTicker);