import React from 'react';
import { FileText } from 'lucide-react';

export default function Terms() {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-sm p-8">
          <div className="flex items-center mb-8">
            <FileText className="w-8 h-8 text-blue-600 mr-4" />
            <h1 className="text-3xl font-bold text-gray-900">Terms of Service</h1>
          </div>

          <div className="prose prose-blue max-w-none">
            <h2>1. Acceptance of Terms</h2>
            <p>
              By accessing and using our services, you agree to be bound by these Terms of Service and all applicable laws and regulations.
            </p>

            <h2>2. Service Description</h2>
            <p>
              We provide a platform for buying and selling USDT (Tether) in Pakistan. Our service facilitates secure transactions between users and our platform.
            </p>

            <h2>3. User Obligations</h2>
            <ul>
              <li>You must be at least 18 years old to use our services</li>
              <li>You must provide accurate and complete information during registration</li>
              <li>You are responsible for maintaining the security of your account</li>
              <li>You agree not to use our services for any illegal activities</li>
            </ul>

            <h2>4. Transaction Terms</h2>
            <p>
              All transactions are subject to our verification process. We reserve the right to refuse or cancel any transaction that violates our policies.
            </p>

            <h2>5. Privacy and Security</h2>
            <p>
              Your privacy and security are important to us. Please review our Privacy Policy to understand how we collect, use, and protect your information.
            </p>

            <h2>6. Fees and Charges</h2>
            <p>
              Our fee structure is transparent and will be clearly displayed before each transaction. We reserve the right to modify our fees with prior notice.
            </p>

            <h2>7. Limitation of Liability</h2>
            <p>
              We strive to maintain a secure and reliable service but cannot guarantee uninterrupted access. We are not liable for any losses incurred through the use of our service.
            </p>

            <h2>8. Changes to Terms</h2>
            <p>
              We reserve the right to modify these terms at any time. Continued use of our service after changes constitutes acceptance of the new terms.
            </p>

            <h2>9. Contact Information</h2>
            <p>
              If you have any questions about these Terms, please contact our support team through the provided channels.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}