import React, { useState, useEffect } from 'react';
import { CreditCard, Plus, Trash2, Check, X } from 'lucide-react';
import type { BankAccount } from '../../types';
import { ref, onValue, push, remove, update } from 'firebase/database';
import { database } from '../../lib/firebase';
import toast from 'react-hot-toast';
import { useAuth } from '../../context/AuthContext';

interface BankDetailsFormProps {
  selectedAccount: BankAccount | null;
  onAccountSelect: (account: BankAccount) => void;
}

export default function BankDetailsForm({ selectedAccount, onAccountSelect }: BankDetailsFormProps) {
  const { user } = useAuth();
  const [accounts, setAccounts] = useState<BankAccount[]>([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [newAccount, setNewAccount] = useState({
    account_name: '',
    iban: '',
    bank_name: ''
  });
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (user) {
      const accountsRef = ref(database, `bank_accounts/${user.uid}`);
      const unsubscribe = onValue(accountsRef, (snapshot) => {
        try {
          if (snapshot.exists()) {
            const accountsData = snapshot.val();
            const accountsArray = Object.entries(accountsData).map(([id, data]: [string, any]) => ({
              id,
              ...data
            }));
            setAccounts(accountsArray);

            // Only select default account on initial load
            if (initialLoad) {
              if (!selectedAccount && accountsArray.length > 0) {
                const defaultAccount = accountsArray.find(acc => acc.is_default) || accountsArray[0];
                onAccountSelect(defaultAccount);
              }
              setInitialLoad(false);
            }
          } else {
            setAccounts([]);
          }
        } catch (error) {
          console.error('Error processing bank accounts:', error);
          toast.error('Failed to load bank accounts');
        } finally {
          setLoading(false);
        }
      });

      return () => unsubscribe();
    }
  }, [user, initialLoad, selectedAccount, onAccountSelect]);

  const handleAddAccount = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading || !user) return;

    try {
      setLoading(true);

      // Validate input
      if (newAccount.account_name.length < 3) {
        throw new Error('Account name must be at least 3 characters long');
      }
      if (newAccount.iban.length < 10) {
        throw new Error('Please enter a valid IBAN');
      }
      if (newAccount.bank_name.length < 2) {
        throw new Error('Please enter a valid bank name');
      }

      const accountsRef = ref(database, `bank_accounts/${user.uid}`);
      const newAccountRef = push(accountsRef);

      const accountData = {
        user_id: user.uid,
        account_name: newAccount.account_name,
        iban: newAccount.iban.replace(/\s+/g, ''),
        bank_name: newAccount.bank_name,
        is_default: accounts.length === 0, // Set as default if it's the first account
        created_at: new Date().toISOString()
      };

      await update(newAccountRef, accountData);

      // If this is the first account, select it
      if (accounts.length === 0) {
        onAccountSelect({ id: newAccountRef.key!, ...accountData });
      }

      setShowAddForm(false);
      setNewAccount({ account_name: '', iban: '', bank_name: '' });
      toast.success('Bank account added successfully');
    } catch (error: any) {
      console.error('Error adding bank account:', error);
      toast.error(error.message || 'Failed to add bank account');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAccount = async (id: string) => {
    if (loading || !user) return;

    if (accounts.length === 1) {
      toast.error('You must have at least one bank account');
      return;
    }

    try {
      setLoading(true);
      const accountRef = ref(database, `bank_accounts/${user.uid}/${id}`);
      await remove(accountRef);

      if (selectedAccount?.id === id && accounts.length > 0) {
        const remainingAccounts = accounts.filter(acc => acc.id !== id);
        onAccountSelect(remainingAccounts[0]);
      }

      toast.success('Bank account deleted successfully');
    } catch (error: any) {
      console.error('Error deleting bank account:', error);
      toast.error(error.message || 'Failed to delete bank account');
    } finally {
      setLoading(false);
    }
  };

  const setDefaultAccount = async (id: string) => {
    if (loading || !user) return;

    try {
      setLoading(true);
      const updates: { [key: string]: boolean } = {};
      
      // First, remove default status from all accounts
      accounts.forEach(account => {
        updates[`${user.uid}/${account.id}/is_default`] = false;
      });
      
      // Then set the selected account as default
      updates[`${user.uid}/${id}/is_default`] = true;

      await update(ref(database, 'bank_accounts'), updates);
      toast.success('Default account updated');
    } catch (error: any) {
      console.error('Error setting default account:', error);
      toast.error(error.message || 'Failed to update default account');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold flex items-center">
          <CreditCard className="w-5 h-5 mr-2 text-blue-600" />
          Bank Accounts
        </h2>
        {!showAddForm && (
          <button
            onClick={() => setShowAddForm(true)}
            className="flex items-center text-sm text-blue-600 hover:text-blue-800"
            disabled={loading}
          >
            <Plus className="w-4 h-4 mr-1" />
            Add Account
          </button>
        )}
      </div>

      {showAddForm && (
        <form onSubmit={handleAddAccount} className="mb-6 p-4 bg-gray-50 rounded-lg">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Account Holder Name
              </label>
              <input
                type="text"
                value={newAccount.account_name}
                onChange={(e) => setNewAccount({ ...newAccount, account_name: e.target.value })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter account holder name"
                minLength={3}
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                IBAN
              </label>
              <input
                type="text"
                value={newAccount.iban}
                onChange={(e) => setNewAccount({ ...newAccount, iban: e.target.value })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="e.g., PK36SCBL0000001123456702"
                minLength={10}
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Bank Name
              </label>
              <input
                type="text"
                value={newAccount.bank_name}
                onChange={(e) => setNewAccount({ ...newAccount, bank_name: e.target.value })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter bank name"
                minLength={2}
                required
              />
            </div>
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={() => {
                  setShowAddForm(false);
                  setNewAccount({ account_name: '', iban: '', bank_name: '' });
                }}
                className="px-4 py-2 text-gray-600 hover:text-gray-800 flex items-center"
                disabled={loading}
              >
                <X className="w-4 h-4 mr-1" />
                Cancel
              </button>
              <button
                type="submit"
                disabled={loading}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 flex items-center"
              >
                {loading ? (
                  <>Adding...</>
                ) : (
                  <>
                    <Plus className="w-4 h-4 mr-1" />
                    Add Account
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      )}

      <div className="space-y-4">
        {accounts.map((account) => (
          <div
            key={account.id}
            className={`p-4 border rounded-lg cursor-pointer transition-all ${
              selectedAccount?.id === account.id
                ? 'border-blue-500 bg-blue-50'
                : 'border-gray-200 hover:border-blue-300'
            }`}
            onClick={() => onAccountSelect(account)}
          >
            <div className="flex justify-between items-start">
              <div>
                <p className="font-medium">{account.account_name}</p>
                <p className="text-sm text-gray-600">{account.iban}</p>
                <p className="text-sm text-gray-600">{account.bank_name}</p>
              </div>
              <div className="flex items-center space-x-2">
                {account.is_default ? (
                  <span className="text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded-full">
                    Default
                  </span>
                ) : (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setDefaultAccount(account.id);
                    }}
                    className="text-blue-600 hover:text-blue-800"
                    disabled={loading}
                    title="Set as default"
                  >
                    <Check className="w-4 h-4" />
                  </button>
                )}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteAccount(account.id);
                  }}
                  className="text-red-600 hover:text-red-800"
                  disabled={loading || accounts.length === 1}
                  title="Delete account"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        ))}

        {accounts.length === 0 && !showAddForm && (
          <div className="text-center py-8">
            <CreditCard className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <p className="text-gray-500">No bank accounts added yet</p>
            <button
              onClick={() => setShowAddForm(true)}
              className="mt-4 text-blue-600 hover:text-blue-800"
            >
              Add your first bank account
            </button>
          </div>
        )}
      </div>
    </div>
  );
}