import React, { useState, useEffect } from 'react';
import { 
  Bell, 
  Clock, 
  CheckCircle,
  XCircle,
  AlertCircle,
  User,
  DollarSign,
  Search,
  VolumeX,
  Volume2,
  Filter
} from 'lucide-react';
import { ref, onValue, update, query, orderByChild, equalTo, get } from 'firebase/database';
import { database } from '../../lib/firebase';
import toast from 'react-hot-toast';

interface Order {
  id: string;
  user_id: string;
  amount: number;
  transaction_id: string;
  status: 'pending' | 'processing' | 'completed' | 'failed';
  bank_details: {
    account_name: string;
    bank_name: string;
    iban: string;
  };
  created_at: string;
  user_name?: string;
}

export default function OrderNotifications() {
  const [pendingOrders, setPendingOrders] = useState<Order[]>([]);
  const [hasNewOrder, setHasNewOrder] = useState(false);
  const [audio] = useState(new Audio('/notification.mp3'));
  const [isMuted, setIsMuted] = useState(() => {
    const savedMute = localStorage.getItem('notificationsMuted');
    return savedMute ? savedMute === 'true' : false;
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [amountFilter, setAmountFilter] = useState<'all' | 'high' | 'low'>('all');
  const [timeFilter, setTimeFilter] = useState<'all' | 'today' | 'week'>('all');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = subscribeToOrders();
    return () => unsubscribe();
  }, []);

  const subscribeToOrders = () => {
    try {
      const ordersRef = ref(database, 'orders');
      
      return onValue(ordersRef, async (snapshot) => {
        try {
          if (snapshot.exists()) {
            const ordersData = snapshot.val();
            const ordersArray = await Promise.all(
              Object.entries(ordersData)
                .filter(([_, order]: [string, any]) => order.status === 'pending')
                .map(async ([id, order]: [string, any]) => {
                  try {
                    // Fetch user details
                    const userRef = ref(database, `users/${order.user_id}`);
                    const userSnapshot = await get(userRef);
                    const userData = userSnapshot.val();

                    return {
                      id,
                      ...order,
                      user_name: userData?.full_name || 'Unknown User'
                    };
                  } catch (error) {
                    console.error('Error fetching user details:', error);
                    return {
                      id,
                      ...order,
                      user_name: 'Unknown User'
                    };
                  }
                })
            );

            // Filter out any failed user fetches and sort by date
            const validOrders = ordersArray
              .filter(Boolean)
              .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

            setPendingOrders(validOrders);

            // Check for new orders in the last 5 seconds
            const newOrder = validOrders.find(order => 
              new Date(order.created_at).getTime() > Date.now() - 5000
            );

            if (newOrder) {
              setHasNewOrder(true);
              if (!isMuted) {
                playNotificationSound();
              }
              toast.success('New pending order received!');
            }
          } else {
            setPendingOrders([]);
          }
        } catch (error) {
          console.error('Error processing orders:', error);
          toast.error('Failed to load orders');
        } finally {
          setLoading(false);
        }
      });
    } catch (error) {
      console.error('Error subscribing to orders:', error);
      toast.error('Failed to subscribe to orders');
      setLoading(false);
      return () => {}; // Return empty cleanup function
    }
  };

  const playNotificationSound = () => {
    if (!isMuted) {
      audio.play().catch(error => {
        console.error('Error playing notification sound:', error);
      });
    }
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
    localStorage.setItem('notificationsMuted', (!isMuted).toString());
  };

  const handleOrderAction = async (orderId: string, action: 'approve' | 'reject') => {
    try {
      const orderRef = ref(database, `orders/${orderId}`);
      const newStatus = action === 'approve' ? 'processing' : 'failed';
      
      await update(orderRef, {
        status: newStatus,
        updated_at: new Date().toISOString()
      });

      setPendingOrders(prev => prev.filter(order => order.id !== orderId));
      toast.success(`Order ${action === 'approve' ? 'approved' : 'rejected'} successfully`);
    } catch (error) {
      console.error('Error updating order:', error);
      toast.error('Failed to update order status');
    }
  };

  const filterOrders = (orders: Order[]) => {
    return orders.filter(order => {
      // Ensure order has required properties before filtering
      if (!order || !order.transaction_id) return false;

      // Search filter
      const matchesSearch = 
        order.transaction_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (order.user_name || '').toLowerCase().includes(searchTerm.toLowerCase());

      // Amount filter
      const matchesAmount = 
        amountFilter === 'all' ||
        (amountFilter === 'high' && order.amount >= 1000) ||
        (amountFilter === 'low' && order.amount < 1000);

      // Time filter
      const matchesTime = () => {
        if (timeFilter === 'all') return true;
        const orderDate = new Date(order.created_at);
        const now = new Date();
        if (timeFilter === 'today') {
          return orderDate.toDateString() === now.toDateString();
        }
        if (timeFilter === 'week') {
          const weekAgo = new Date(now.setDate(now.getDate() - 7));
          return orderDate >= weekAgo;
        }
        return true;
      };

      return matchesSearch && matchesAmount && matchesTime();
    });
  };

  const filteredOrders = filterOrders(pendingOrders);

  if (loading) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6 h-full flex flex-col">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold">Pending Orders</h2>
        </div>
        <div className="flex items-center justify-center h-[600px]">
          <Clock className="w-8 h-8 text-blue-600 animate-spin" />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 h-full flex flex-col">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center">
          <Bell className={`w-6 h-6 ${hasNewOrder ? 'text-blue-600' : 'text-gray-400'}`} />
          <h2 className="text-lg font-semibold ml-2">Pending Orders</h2>
          {hasNewOrder && (
            <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
              New
            </span>
          )}
        </div>
        <div className="flex items-center space-x-4">
          <button
            onClick={toggleMute}
            className="p-2 rounded-lg hover:bg-gray-100"
            title={isMuted ? 'Unmute notifications' : 'Mute notifications'}
          >
            {isMuted ? (
              <VolumeX className="w-5 h-5 text-gray-500" />
            ) : (
              <Volume2 className="w-5 h-5 text-blue-600" />
            )}
          </button>
        </div>
      </div>

      <div className="mb-6 space-y-4">
        <div className="flex items-center space-x-4">
          <div className="relative flex-1">
            <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
            <input
              type="text"
              placeholder="Search orders..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <select
            value={amountFilter}
            onChange={(e) => setAmountFilter(e.target.value as any)}
            className="px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="all">All Amounts</option>
            <option value="high">High Value (≥1000 USDT)</option>
            <option value="low">Low Value (&lt;1000 USDT)</option>
          </select>
          <select
            value={timeFilter}
            onChange={(e) => setTimeFilter(e.target.value as any)}
            className="px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="all">All Time</option>
            <option value="today">Today</option>
            <option value="week">This Week</option>
          </select>
        </div>
      </div>

      <div className="space-y-4 max-h-[600px] overflow-y-auto">
        {loading ? (
          <div className="text-center py-4">
            <div className="animate-spin w-6 h-6 border-2 border-blue-600 border-t-transparent rounded-full mx-auto"></div>
            <p className="text-gray-500 mt-2">Loading orders...</p>
          </div>
        ) : filteredOrders.length === 0 ? (
          <div className="text-center py-12">
            <DollarSign className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <p className="text-gray-500">No pending orders found</p>
          </div>
        ) : (
          filteredOrders.map((order) => (
            <div
              key={order.id}
              className="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
            >
              <div className="flex justify-between items-start">
                <div className="space-y-2">
                  <div className="flex items-center space-x-2">
                    <User className="w-5 h-5 text-gray-400" />
                    <span className="font-medium text-gray-900">{order.user_name}</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <DollarSign className="w-5 h-5 text-green-500" />
                    <span className="text-gray-900">{order.amount} USDT</span>
                  </div>
                  <div className="text-sm text-gray-500">
                    <p>Transaction ID: {order.transaction_id}</p>
                    <p>Bank: {order.bank_details.bank_name}</p>
                    <p>Account: {order.bank_details.account_name}</p>
                    <p>IBAN: {order.bank_details.iban}</p>
                  </div>
                  <div className="text-xs text-gray-400">
                    {new Date(order.created_at).toLocaleString()}
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => handleOrderAction(order.id, 'approve')}
                    className="px-3 py-1 bg-green-100 text-green-700 rounded-lg hover:bg-green-200 transition-colors"
                  >
                    <CheckCircle className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => handleOrderAction(order.id, 'reject')}
                    className="px-3 py-1 bg-red-100 text-red-700 rounded-lg hover:bg-red-200 transition-colors"
                  >
                    <XCircle className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
