import React, { useState, useEffect } from 'react';
import { MessageCircle, X, Loader } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { ref, get, query, orderByChild, equalTo, set, push } from 'firebase/database';
import { database } from '../../lib/firebase';
import ChatWindow from '../chat/ChatWindow';
import type { ChatConversation } from '../../types';
import toast from 'react-hot-toast';

export default function Chat() {
  const { user } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [conversation, setConversation] = useState<ChatConversation | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user && isOpen && !conversation) {
      initializeChat();
    }
  }, [user, isOpen]);

  const initializeChat = async () => {
    if (!user) {
      toast.error('Please sign in to use chat');
      return;
    }

    try {
      setLoading(true);
      
      // First try to get existing conversation
      const conversationsRef = ref(database, 'chat_conversations');
      const userConversations = await get(conversationsRef);
      
      let existingConversation = null;
      
      if (userConversations.exists()) {
        // Manually filter conversations for the current user
        userConversations.forEach((snapshot) => {
          const conv = snapshot.val();
          if (conv.user_id === user.uid) {
            existingConversation = {
              id: snapshot.key!,
              ...conv
            };
          }
        });
      }
      
      if (existingConversation) {
        // Use existing conversation
        setConversation(existingConversation);
      } else {
        // Create new conversation
        const newConversationRef = push(conversationsRef);
        const newConversation = {
          user_id: user.uid,
          admin_id: null,
          created_at: new Date().toISOString(),
          last_message: null,
          last_message_at: null,
          user_name: user.email
        };

        await set(newConversationRef, newConversation);

        setConversation({
          id: newConversationRef.key!,
          ...newConversation
        });
      }
    } catch (error) {
      console.error('Error initializing chat:', error?.message || error);
      toast.error('Failed to start chat. Please try again.');
      setIsOpen(false);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setConversation(null);
  };

  return (
    <>
      {/* Chat Button */}
      <button
        onClick={() => setIsOpen(true)}
        disabled={loading}
        className="fixed bottom-6 right-6 p-4 bg-blue-600 text-white rounded-full shadow-lg hover:bg-blue-700 transition-colors disabled:opacity-50 z-40"
      >
        {loading ? (
          <Loader className="w-6 h-6 animate-spin" />
        ) : (
          <MessageCircle className="w-6 h-6" />
        )}
      </button>

      {/* Chat Window */}
      {isOpen && (
        <div className="fixed inset-0 md:inset-auto md:bottom-24 md:right-6 z-50 flex items-end justify-end">
          <div className="w-full h-full md:w-auto md:h-auto">
            <div className="relative w-full h-full md:w-[350px] md:h-[500px] bg-white rounded-lg shadow-xl flex flex-col">
              <button
                onClick={handleClose}
                className="absolute top-2 right-2 p-2 text-gray-400 hover:text-gray-600 z-10 md:hidden"
              >
                <X className="w-6 h-6" />
              </button>
              {conversation ? (
                <ChatWindow
                  conversationId={conversation.id}
                  recipientName="Support"
                  onClose={handleClose}
                />
              ) : (
                <div className="flex items-center justify-center h-full">
                  <div className="text-center p-4">
                    <MessageCircle className="w-8 h-8 text-gray-400 mx-auto mb-2" />
                    <p className="text-gray-600">
                      {loading ? 'Starting chat...' : 'Chat failed to load'}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}