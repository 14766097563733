import React from 'react';
import { Image, LinkIcon } from 'lucide-react';
import type { SystemSettings } from '../../../types';

interface CompanyInfoProps {
  settings: SystemSettings;
  onSettingsChange: (settings: SystemSettings) => void;
}

export default function CompanyInfo({ settings, onSettingsChange }: CompanyInfoProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h2 className="text-lg font-semibold mb-4 flex items-center">
        <Image className="w-5 h-5 mr-2 text-blue-600" />
        Company Information
      </h2>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Company Name
          </label>
          <input
            type="text"
            value={settings.company_name}
            onChange={(e) => onSettingsChange({ ...settings, company_name: e.target.value })}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Company Logo URL
          </label>
          <div className="flex space-x-2">
            <div className="flex-1 relative">
              <LinkIcon className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
              <input
                type="url"
                value={settings.company_logo}
                onChange={(e) => onSettingsChange({ ...settings, company_logo: e.target.value })}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="https://example.com/logo.png"
              />
            </div>
            {settings.company_logo && (
              <img
                src={settings.company_logo}
                alt="Logo preview"
                className="h-10 w-auto object-contain"
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.src = '';
                }}
              />
            )}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Favicon URL
          </label>
          <div className="flex space-x-2">
            <div className="flex-1 relative">
              <LinkIcon className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
              <input
                type="url"
                value={settings.company_favicon}
                onChange={(e) => onSettingsChange({ ...settings, company_favicon: e.target.value })}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="https://example.com/favicon.ico"
              />
            </div>
            {settings.company_favicon && (
              <img
                src={settings.company_favicon}
                alt="Favicon preview"
                className="h-10 w-10 object-contain"
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.src = '';
                }}
              />
            )}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Company Description
          </label>
          <textarea
            value={settings.company_description}
            onChange={(e) => onSettingsChange({ ...settings, company_description: e.target.value })}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            rows={3}
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Meta Title
          </label>
          <input
            type="text"
            value={settings.meta_title}
            onChange={(e) => onSettingsChange({ ...settings, meta_title: e.target.value })}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Meta Description
          </label>
          <textarea
            value={settings.meta_description}
            onChange={(e) => onSettingsChange({ ...settings, meta_description: e.target.value })}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            rows={2}
            required
          />
        </div>
      </div>
    </div>
  );
}