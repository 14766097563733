import React, { useState, useEffect } from 'react';
import { Edit2, Save, Loader } from 'lucide-react';
import { ref, onValue, update } from 'firebase/database';
import { database } from '../../../lib/firebase';
import toast from 'react-hot-toast';

interface HeroContent {
  main_heading: string;
  sub_heading: string;
  background_image: string;
}

export default function HeroSection() {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [content, setContent] = useState<HeroContent>({
    main_heading: '#1 Trusted Platform to Sell USDT in Pakistan',
    sub_heading: 'Convert your USDT to PKR instantly with Pakistan\'s most secure and reliable crypto exchange platform',
    background_image: 'https://images.unsplash.com/photo-1639322537228-f710d846310a?auto=format&fit=crop&q=80'
  });

  useEffect(() => {
    const contentRef = ref(database, 'content/hero');
    const unsubscribe = onValue(contentRef, (snapshot) => {
      if (snapshot.exists()) {
        setContent(snapshot.val());
      }
      setLoading(false);
    }, (error) => {
      console.error('Error fetching hero content:', error);
      toast.error('Failed to load hero content');
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (saving) return;

    try {
      setSaving(true);
      const contentRef = ref(database, 'content/hero');
      await update(contentRef, {
        ...content,
        updated_at: new Date().toISOString()
      });
      toast.success('Hero section updated successfully');
    } catch (error) {
      console.error('Error saving hero content:', error);
      toast.error('Failed to save changes');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="animate-pulse">
        <div className="h-8 bg-gray-200 rounded w-1/4 mb-8"></div>
        <div className="space-y-4">
          {[...Array(3)].map((_, i) => (
            <div key={i} className="h-16 bg-gray-200 rounded"></div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">Hero Section</h1>
        <button
          onClick={handleSubmit}
          disabled={saving}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
        >
          {saving ? (
            <>
              <Loader className="w-5 h-5 mr-2 animate-spin" />
              Saving...
            </>
          ) : (
            <>
              <Save className="w-5 h-5 mr-2" />
              Save Changes
            </>
          )}
        </button>
      </div>

      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Main Heading
            </label>
            <input
              type="text"
              value={content.main_heading}
              onChange={(e) => setContent({ ...content, main_heading: e.target.value })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Subheading
            </label>
            <input
              type="text"
              value={content.sub_heading}
              onChange={(e) => setContent({ ...content, sub_heading: e.target.value })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Background Image URL
            </label>
            <div className="flex space-x-4">
              <input
                type="url"
                value={content.background_image}
                onChange={(e) => setContent({ ...content, background_image: e.target.value })}
                className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
              />
              {content.background_image && (
                <img
                  src={content.background_image}
                  alt="Background preview"
                  className="w-16 h-16 object-cover rounded-lg"
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.src = '';
                    toast.error('Failed to load image preview');
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}