import React from 'react';

export default function SupportedBanks() {
  const banks = [
    {
      name: 'Meezan Bank',
      logo: 'https://ik.imagekit.io/vv0raaunt/Exchange/meezan-bank.webp?tr=w-100,h-60'
    },
    {
      name: 'UBL',
      logo: 'https://ik.imagekit.io/vv0raaunt/Exchange/UBL%20bank.png?tr=w-150,h-70'
    },
    {
      name: 'Bank Alfalah',
      logo: 'https://ik.imagekit.io/vv0raaunt/Exchange/bank_alfalah.png?tr=w-100,h-60'
    },
    {
      name: 'HBL',
      logo: 'https://ik.imagekit.io/vv0raaunt/Exchange/HBL.png?tr=w-100,h-50'
    },
    {
      name: 'Bank of Punjab',
      logo: 'https://ik.imagekit.io/vv0raaunt/Exchange/bank-of-punjab.png?tr=w-100,h-50'
    },
    {
      name: 'Faysal Bank',
      logo: 'https://ik.imagekit.io/vv0raaunt/Exchange/faysal%20bank.png?tr=w-100,h-40'
    },
    {
      name: 'MCB Bank',
      logo: 'https://ik.imagekit.io/vv0raaunt/Exchange/mcb-bank-limited-pakistan.png?tr=w-100,h-45'
    },
    {
      name: 'Bank Islami',
      logo: 'https://ik.imagekit.io/vv0raaunt/Exchange/Bank%20islami.png?tr=w-100,h-80'
    },
    {
      name: 'Standard Chartered',
      logo: 'https://ik.imagekit.io/vv0raaunt/Exchange/Standard_Chartered.png?tr=w-100,h-40'
    },
    {
      name: 'Allied Bank',
      logo: 'https://ik.imagekit.io/vv0raaunt/Exchange/allied%20bank.png?tr=w-100,h-80'
    },
    {
      name: 'Easypaisa',
      logo: 'https://ik.imagekit.io/vv0raaunt/Exchange/easy%20paisa.png?tr=w-100,h-60'
    },
    {
      name: 'JazzCash',
      logo: 'https://ik.imagekit.io/vv0raaunt/Exchange/jazz_cash.png?tr=w-100,h-40'
    },
    {
      name: 'National Bank',
      logo: 'https://ik.imagekit.io/vv0raaunt/Exchange/national-bank-of-pakistan.png?tr=w-100,h-40'
    }
  ];

  return (
    <div className="mt-12">
      <p className="text-gray-600 mb-6 text-center">We Support All Major Banks in Pakistan</p>
      <div className="flex flex-wrap justify-center items-center gap-6 px-4">
        {banks.map((bank) => (
          <img
            key={bank.name}
            src={bank.logo}
            alt={bank.name}
            className="h-10 w-auto object-contain filter grayscale hover:grayscale-0 transition-all duration-300"
            loading="lazy"
            width="100"
            height="40"
          />
        ))}
      </div>
      <p className="text-sm text-gray-500 mt-6 text-center">
        And many more Pakistani banks supported
      </p>
    </div>
  );
}