import React, { useState, useEffect } from 'react';
import { Save, Plus, Trash2, Edit2, X, Star } from 'lucide-react';
import { ref, onValue, push, update, remove } from 'firebase/database';
import { database } from '../../../lib/firebase';
import toast from 'react-hot-toast';

interface Comparison {
  id: string;
  title: string;
  description: string;
  icon: string;
  sort_order: number;
  is_active: boolean;
}

export default function Comparison() {
  const [comparisons, setComparisons] = useState<Comparison[]>([]);
  const [loading, setLoading] = useState(true);
  const [showEditor, setShowEditor] = useState(false);
  const [editingComparison, setEditingComparison] = useState<Comparison | null>(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    icon: '',
    is_active: true
  });

  useEffect(() => {
    const unsubscribe = subscribeToComparisons();
    return () => unsubscribe();
  }, []);

  const subscribeToComparisons = () => {
    const comparisonsRef = ref(database, 'comparisons');
    return onValue(comparisonsRef, (snapshot) => {
      try {
        if (snapshot.exists()) {
          const comparisonsData = snapshot.val();
          const comparisonsArray = Object.entries(comparisonsData).map(([id, comparison]: [string, any]) => ({
            id,
            ...comparison
          })).sort((a, b) => a.sort_order - b.sort_order);
          setComparisons(comparisonsArray);
        } else {
          setComparisons([]);
        }
      } catch (error) {
        console.error('Error processing comparisons:', error);
        toast.error('Failed to load comparisons');
      } finally {
        setLoading(false);
      }
    }, (error) => {
      console.error('Error fetching comparisons:', error);
      toast.error('Failed to load comparisons');
      setLoading(false);
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (editingComparison) {
        const comparisonRef = ref(database, `comparisons/${editingComparison.id}`);
        await update(comparisonRef, {
          ...formData,
          updated_at: new Date().toISOString()
        });
        toast.success('Comparison updated successfully');
      } else {
        const comparisonsRef = ref(database, 'comparisons');
        const newComparisonRef = push(comparisonsRef);
        await update(newComparisonRef, {
          ...formData,
          sort_order: comparisons.length,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        });
        toast.success('Comparison added successfully');
      }

      setShowEditor(false);
      resetForm();
    } catch (error: any) {
      console.error('Error saving comparison:', error);
      toast.error(error.message || 'Failed to save comparison');
    }
  };

  const handleDelete = async (id: string) => {
    if (!confirm('Are you sure you want to delete this comparison?')) return;

    try {
      const comparisonRef = ref(database, `comparisons/${id}`);
      await remove(comparisonRef);
      
      // Update sort order of remaining comparisons
      const remainingComparisons = comparisons.filter(c => c.id !== id);
      const updates: { [key: string]: any } = {};
      remainingComparisons.forEach((comparison, index) => {
        updates[`comparisons/${comparison.id}/sort_order`] = index;
      });
      
      if (Object.keys(updates).length > 0) {
        await update(ref(database), updates);
      }

      toast.success('Comparison deleted successfully');
    } catch (error) {
      console.error('Error deleting comparison:', error);
      toast.error('Failed to delete comparison');
    }
  };

  const handleEdit = (comparison: Comparison) => {
    setEditingComparison(comparison);
    setFormData({
      title: comparison.title,
      description: comparison.description,
      icon: comparison.icon,
      is_active: comparison.is_active
    });
    setShowEditor(true);
  };

  const resetForm = () => {
    setFormData({
      title: '',
      description: '',
      icon: '',
      is_active: true
    });
    setEditingComparison(null);
  };

  const handleToggleActive = async (comparison: Comparison) => {
    try {
      const comparisonRef = ref(database, `comparisons/${comparison.id}`);
      await update(comparisonRef, {
        is_active: !comparison.is_active,
        updated_at: new Date().toISOString()
      });
      toast.success(`Comparison ${comparison.is_active ? 'disabled' : 'enabled'} successfully`);
    } catch (error) {
      console.error('Error toggling comparison status:', error);
      toast.error('Failed to update comparison status');
    }
  };

  if (loading) {
    return (
      <div className="animate-pulse">
        <div className="h-8 bg-gray-200 rounded w-1/4 mb-8"></div>
        <div className="space-y-4">
          {[...Array(3)].map((_, i) => (
            <div key={i} className="h-24 bg-gray-200 rounded"></div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">Comparison Management</h1>
        <button
          onClick={() => {
            resetForm();
            setShowEditor(true);
          }}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          <Plus className="w-5 h-5 mr-2" />
          Add Comparison
        </button>
      </div>

      <div className="grid gap-6">
        {comparisons.map((comparison) => (
          <div
            key={comparison.id}
            className={`bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow ${
              !comparison.is_active ? 'opacity-60' : ''
            }`}
          >
            <div className="flex justify-between items-start">
              <div className="flex-1">
                <h2 className="text-xl font-semibold text-gray-900 mb-2 flex items-center">
                  <Star className="w-5 h-5 text-yellow-500 mr-2" />
                  {comparison.title}
                </h2>
                <p className="text-gray-600 mb-4">{comparison.description}</p>
                <div className="text-sm text-gray-500">
                  <span className="font-medium">Icon: {comparison.icon}</span>
                </div>
              </div>
              <div className="flex items-center space-x-2 ml-4">
                <button
                  onClick={() => handleToggleActive(comparison)}
                  className={`px-3 py-1 rounded-lg text-sm font-medium ${
                    comparison.is_active
                      ? 'bg-green-100 text-green-800'
                      : 'bg-gray-100 text-gray-800'
                  }`}
                >
                  {comparison.is_active ? 'Active' : 'Inactive'}
                </button>
                <button
                  onClick={() => handleEdit(comparison)}
                  className="p-2 text-blue-600 hover:bg-blue-50 rounded-lg"
                >
                  <Edit2 className="w-5 h-5" />
                </button>
                <button
                  onClick={() => handleDelete(comparison.id)}
                  className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        ))}

        {comparisons.length === 0 && (
          <div className="text-center py-12 bg-white rounded-lg shadow-sm">
            <Star className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">No Comparisons Found</h3>
            <p className="text-gray-600">
              Add your first comparison to showcase your advantages.
            </p>
          </div>
        )}
      </div>

      {showEditor && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center overflow-y-auto">
          <div className="bg-white rounded-lg max-w-2xl w-full p-6 m-4">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">
                {editingComparison ? 'Edit Comparison' : 'Add Comparison'}
              </h2>
              <button
                onClick={() => setShowEditor(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="w-6 h-6" />
              </button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Title
                </label>
                <input
                  type="text"
                  value={formData.title}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Description
                </label>
                <textarea
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  rows={4}
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Icon Name
                </label>
                <input
                  type="text"
                  value={formData.icon}
                  onChange={(e) => setFormData({ ...formData, icon: e.target.value })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                  placeholder="e.g., Shield, Clock, Users"
                />
                <p className="mt-1 text-sm text-gray-500">
                  Enter a valid Lucide icon name. See <a href="https://lucide.dev/icons" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">Lucide Icons</a> for available icons.
                </p>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="is_active"
                  checked={formData.is_active}
                  onChange={(e) => setFormData({ ...formData, is_active: e.target.checked })}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label htmlFor="is_active" className="ml-2 block text-sm text-gray-900">
                  Active
                </label>
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setShowEditor(false)}
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  <Save className="w-5 h-5 mr-2" />
                  {editingComparison ? 'Update Comparison' : 'Add Comparison'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}