import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getDatabase, connectDatabaseEmulator, ref, onValue, onDisconnect, set, push, query, orderByChild, endAt, get, update, serverTimestamp } from 'firebase/database';
import { getStorage, connectStorageEmulator } from 'firebase/storage';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL
};

// Initialize Firebase app first
const firebaseApp = initializeApp(firebaseConfig);

// Then initialize services
const auth = getAuth(firebaseApp);
const database = getDatabase(firebaseApp);
const storage = getStorage(firebaseApp);

// Only connect to emulators in development and if explicitly enabled
const USE_EMULATOR = false; // Set this to false to use production Firebase
if (import.meta.env.DEV && USE_EMULATOR) {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectDatabaseEmulator(database, 'localhost', 9000);
  connectStorageEmulator(storage, 'localhost', 9199);
}

// Track online presence
const trackPresence = (uid: string) => {
  const userStatusRef = ref(database, `/status/${uid}`);
  const connectedRef = ref(database, '.info/connected');

  onValue(connectedRef, (snapshot) => {
    if (snapshot.val() === false) return;

    onDisconnect(userStatusRef).set({
      state: 'offline',
      last_changed: serverTimestamp(),
    });

    set(userStatusRef, {
      state: 'online',
      last_changed: serverTimestamp(),
    });
  });
};

// Track user activity
const trackUserActivity = async (
  eventType: string,
  userId: string | null,
  status: 'success' | 'failed' | 'pending',
  details: any = {}
) => {
  try {
    const logsRef = ref(database, 'security_logs');
    const newLogRef = push(logsRef);
    
    await set(newLogRef, {
      event_type: eventType,
      user_id: userId,
      ip_address: await fetch('https://api.ipify.org?format=json').then(r => r.json()).then(data => data.ip),
      status,
      details: {
        ...details,
        timestamp: serverTimestamp()
      }
    });
  } catch (error) {
    console.error('Failed to log security event:', error);
  }
};

// Cleanup expired data
const cleanupExpiredData = async () => {
  try {
    const now = new Date();
    const ninetyDaysAgo = new Date(now.getTime() - (90 * 24 * 60 * 60 * 1000));
    
    // Clean up old security logs
    const logsRef = ref(database, 'security_logs');
    const oldLogsQuery = query(
      logsRef,
      orderByChild('details/timestamp'),
      endAt(ninetyDaysAgo.getTime())
    );
    
    const snapshot = await get(oldLogsQuery);
    if (snapshot.exists()) {
      const updates: { [key: string]: null } = {};
      snapshot.forEach((child) => {
        updates[child.key as string] = null;
      });
      await update(logsRef, updates);
    }
    
    // Clean up old presence data
    const oneDayAgo = new Date(now.getTime() - (24 * 60 * 60 * 1000));
    const statusRef = ref(database, 'status');
    const oldStatusQuery = query(
      statusRef,
      orderByChild('last_changed'),
      endAt(oneDayAgo.getTime())
    );
    
    const statusSnapshot = await get(oldStatusQuery);
    if (statusSnapshot.exists()) {
      const updates: { [key: string]: null } = {};
      statusSnapshot.forEach((child) => {
        updates[child.key as string] = null;
      });
      await update(statusRef, updates);
    }
  } catch (error) {
    console.error('Error cleaning up expired data:', error);
  }
};

export {
  auth,
  database,
  storage,
  trackPresence,
  trackUserActivity,
  cleanupExpiredData
};