import React, { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../../lib/firebase';
import * as icons from 'lucide-react';

interface Comparison {
  id: string;
  title: string;
  description: string;
  icon: string;
  sort_order: number;
  is_active: boolean;
}

export default function ComparisonSection() {
  const [comparisons, setComparisons] = useState<Comparison[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const comparisonsRef = ref(database, 'comparisons');
    const unsubscribe = onValue(comparisonsRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const comparisonsArray = Object.entries(data).map(([id, comparison]: [string, any]) => ({
          id,
          ...comparison
        })).filter(comparison => comparison.is_active)
          .sort((a, b) => a.sort_order - b.sort_order);
        setComparisons(comparisonsArray);
      }
      setLoading(false);
    }, (error) => {
      console.error('Error fetching comparisons:', error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const getIcon = (iconName: string) => {
    const Icon = (icons as any)[iconName];
    return Icon ? <Icon className="w-8 h-8 text-blue-600" /> : null;
  };

  if (loading) {
    return (
      <div className="py-20 bg-gradient-to-b from-white to-indigo-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="animate-pulse">
            <div className="h-8 bg-gray-200 rounded w-1/4 mx-auto mb-8"></div>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[...Array(6)].map((_, i) => (
                <div key={i} className="h-48 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="py-20 bg-gradient-to-b from-white to-indigo-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-indigo-600">
            Why Choose Us Over Other Exchanges?
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            We're not just another crypto exchange - we're Pakistan's premier USDT exchange platform,
            built specifically for Pakistani users with features and benefits that international exchanges can't match.
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {comparisons.map((comparison) => (
            <div
              key={comparison.id}
              className="bg-white p-6 rounded-xl shadow-sm border border-gray-100 hover:shadow-md transition-all duration-300 transform hover:-translate-y-1"
            >
              <div className="flex items-start space-x-4">
                <div className="flex-shrink-0">
                  {getIcon(comparison.icon)}
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">
                    {comparison.title}
                  </h3>
                  <p className="text-gray-600">
                    {comparison.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-16 bg-gradient-to-r from-blue-600 to-indigo-600 rounded-2xl p-8 text-white">
          <div className="max-w-3xl mx-auto text-center">
            <h3 className="text-2xl font-bold mb-4">
              The Smart Choice for Pakistani Crypto Users
            </h3>
            <p className="text-lg opacity-90">
              While international exchanges serve a global audience, our platform is specifically designed
              for Pakistani users. We understand local banking systems, regulatory requirements, and user
              needs better than any international platform. Our focus on the Pakistani market allows us
              to provide faster, more reliable, and more cost-effective services tailored to your needs.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}