import React from 'react';
import {
  DollarSign,
  UserPlus,
  AlertCircle,
  CheckCircle,
  XCircle,
  Clock
} from 'lucide-react';

interface Activity {
  id: string;
  type: 'transaction' | 'user' | 'alert';
  title: string;
  description: string;
  status: 'completed' | 'pending' | 'failed';
  timestamp: string;
}

export default function RecentActivity() {
  const activities: Activity[] = [
    {
      id: '1',
      type: 'transaction',
      title: 'New Transaction',
      description: 'User completed USDT sale of $5,000',
      status: 'completed',
      timestamp: '5 minutes ago'
    },
    {
      id: '2',
      type: 'user',
      title: 'New User Registration',
      description: 'ahmed.khan@example.com registered',
      status: 'completed',
      timestamp: '10 minutes ago'
    },
    {
      id: '3',
      type: 'transaction',
      title: 'Pending Transaction',
      description: 'Awaiting confirmation for $3,000 USDT',
      status: 'pending',
      timestamp: '15 minutes ago'
    },
    {
      id: '4',
      type: 'alert',
      title: 'System Alert',
      description: 'High transaction volume detected',
      status: 'failed',
      timestamp: '20 minutes ago'
    }
  ];

  const getIcon = (type: Activity['type']) => {
    switch (type) {
      case 'transaction':
        return DollarSign;
      case 'user':
        return UserPlus;
      case 'alert':
        return AlertCircle;
    }
  };

  const getStatusIcon = (status: Activity['status']) => {
    switch (status) {
      case 'completed':
        return <CheckCircle className="w-5 h-5 text-green-500" />;
      case 'pending':
        return <Clock className="w-5 h-5 text-yellow-500" />;
      case 'failed':
        return <XCircle className="w-5 h-5 text-red-500" />;
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm h-full">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-lg font-semibold text-gray-900">Recent Activity</h2>
      </div>
      <div className="divide-y divide-gray-200">
        {activities.map((activity) => {
          const Icon = getIcon(activity.type);
          return (
            <div key={activity.id} className="p-6 flex items-start space-x-4">
              <div className="flex-shrink-0">
                <Icon className="w-6 h-6 text-blue-600" />
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-gray-900">
                  {activity.title}
                </p>
                <p className="text-sm text-gray-500">
                  {activity.description}
                </p>
                <p className="text-xs text-gray-400 mt-1">
                  {activity.timestamp}
                </p>
              </div>
              <div className="flex-shrink-0">
                {getStatusIcon(activity.status)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}