import React, { useState, useEffect } from 'react';
import { useRate } from '../../context/RateContext';
import { ref, onValue } from 'firebase/database';
import { database } from '../../lib/firebase';

interface RatesSettings {
  section_title: string;
  buy_rate_label: string;
  sell_rate_label: string;
  show_buy_rate: boolean;
  show_sell_rate: boolean;
  manual_rates: boolean;
  manual_buy_rate: number;
  manual_sell_rate: number;
  api_source: string;
  api_key: string;
}

export default function LiveRates() {
  const { buyRate, sellRate, lastUpdated } = useRate();
  const [settings, setSettings] = useState<RatesSettings>({
    section_title: 'Best USDT Rates in Pakistan',
    buy_rate_label: 'Buy Rate',
    sell_rate_label: 'Sell Rate',
    show_buy_rate: true,
    show_sell_rate: true,
    manual_rates: false,
    manual_buy_rate: 285.50,
    manual_sell_rate: 284.50,
    api_source: 'exchangerate-api.com',
    api_key: 'fa47175a9f3fa91907fa7f8d'
  });

  useEffect(() => {
    const settingsRef = ref(database, 'rates_settings');
    const unsubscribe = onValue(settingsRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setSettings(data);
      }
    }, (error) => {
      console.error('Error fetching rates settings:', error);
    });

    return () => unsubscribe();
  }, []);

  const displayedBuyRate = settings.manual_rates ? settings.manual_buy_rate : buyRate;
  const displayedSellRate = settings.manual_rates ? settings.manual_sell_rate : sellRate;

  return (
    <div id="live-rates" className="py-16 scroll-mt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-gradient-to-br from-white to-indigo-50 rounded-2xl shadow-xl p-8 border border-indigo-100">
          <h2 className="text-3xl font-bold text-center mb-8 text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-indigo-600">
            {settings.section_title}
          </h2>
          <div className="flex justify-center items-center space-x-12">
            {settings.show_buy_rate && (
              <div className="text-center bg-white p-6 rounded-xl shadow-sm">
                <p className="text-gray-600 mb-2">{settings.buy_rate_label}</p>
                <p className="text-3xl font-bold text-green-600">
                  PKR {displayedBuyRate.toFixed(2)}
                </p>
              </div>
            )}
            {settings.show_sell_rate && (
              <div className="text-center bg-white p-6 rounded-xl shadow-sm">
                <p className="text-gray-600 mb-2">{settings.sell_rate_label}</p>
                <p className="text-3xl font-bold text-red-600">
                  PKR {displayedSellRate.toFixed(2)}
                </p>
              </div>
            )}
          </div>
          {lastUpdated && !settings.manual_rates && (
            <p className="text-center text-sm text-gray-500 mt-4">
              Last updated: {lastUpdated.toLocaleString()}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}