import React, { useState, useEffect } from 'react';
import {
  DollarSign,
  ArrowUpRight,
  ArrowDownRight,
  Activity,
  Clock,
  RefreshCcw
} from 'lucide-react';
import { ref, onValue, query, orderByChild, startAt, endAt } from 'firebase/database';
import { database } from '../../lib/firebase';
import toast from 'react-hot-toast';

interface DashboardStats {
  totalVolume: number;
  totalTransactions: number;
  activeOrders: number;
  avgProcessingTime: number;
  volumeChange: number;
  transactionChange: number;
  processingTimeChange: number;
}

export default function DashboardStats() {
  const [stats, setStats] = useState<DashboardStats>({
    totalVolume: 0,
    totalTransactions: 0,
    activeOrders: 0,
    avgProcessingTime: 0,
    volumeChange: 0,
    transactionChange: 0,
    processingTimeChange: 0
  });
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    fetchStats();
  }, []);

  const fetchStats = async () => {
    try {
      setRefreshing(true);
      
      // Get date ranges
      const now = new Date();
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const startOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      const endOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0);

      // Fetch current month's orders
      const ordersRef = ref(database, 'orders');
      const currentMonthQuery = query(
        ordersRef,
        orderByChild('created_at'),
        startAt(startOfMonth.toISOString()),
        endAt(now.toISOString())
      );
      
      // Subscribe to current month's orders
      onValue(currentMonthQuery, (currentMonthSnapshot) => {
        try {
          const currentMonthOrders = currentMonthSnapshot.val() || {};
          const currentMonthOrdersArray = Object.values(currentMonthOrders) as any[];
          
          // Calculate current month stats
          const currentMonthVolume = currentMonthOrdersArray
            .filter(order => order.status === 'completed')
            .reduce((sum, order) => sum + (order.amount || 0), 0);
          
          const currentMonthTransactions = currentMonthOrdersArray.length;
          const currentActiveOrders = currentMonthOrdersArray
            .filter(order => order.status === 'pending' || order.status === 'processing')
            .length;

          // Fetch last month's orders
          const lastMonthQuery = query(
            ordersRef,
            orderByChild('created_at'),
            startAt(startOfLastMonth.toISOString()),
            endAt(endOfLastMonth.toISOString())
          );

          onValue(lastMonthQuery, (lastMonthSnapshot) => {
            try {
              const lastMonthOrders = lastMonthSnapshot.val() || {};
              const lastMonthOrdersArray = Object.values(lastMonthOrders) as any[];
              
              // Calculate last month stats
              const lastMonthVolume = lastMonthOrdersArray
                .filter(order => order.status === 'completed')
                .reduce((sum, order) => sum + (order.amount || 0), 0);
              
              const lastMonthTransactions = lastMonthOrdersArray.length;

              // Calculate changes
              const volumeChange = lastMonthVolume > 0 
                ? ((currentMonthVolume - lastMonthVolume) / lastMonthVolume) * 100 
                : 0;
              
              const transactionChange = lastMonthTransactions > 0 
                ? ((currentMonthTransactions - lastMonthTransactions) / lastMonthTransactions) * 100 
                : 0;

              // Calculate average processing time
              const completedOrders = [...currentMonthOrdersArray, ...lastMonthOrdersArray]
                .filter(order => order.status === 'completed');
              
              const processingTimes = completedOrders.map(order => {
                const created = new Date(order.created_at);
                const updated = new Date(order.updated_at);
                return (updated.getTime() - created.getTime()) / (1000 * 60); // Convert to minutes
              });

              const avgProcessingTime = processingTimes.length > 0
                ? processingTimes.reduce((sum, time) => sum + time, 0) / processingTimes.length
                : 0;

              const lastMonthAvg = lastMonthOrdersArray
                .filter(order => order.status === 'completed')
                .reduce((sum, order) => {
                  const created = new Date(order.created_at);
                  const updated = new Date(order.updated_at);
                  return sum + (updated.getTime() - created.getTime()) / (1000 * 60);
                }, 0) / (lastMonthOrdersArray.filter(order => order.status === 'completed').length || 1);

              const processingTimeChange = lastMonthAvg > 0
                ? ((avgProcessingTime - lastMonthAvg) / lastMonthAvg) * 100
                : 0;

              setStats({
                totalVolume: currentMonthVolume,
                totalTransactions: currentMonthTransactions,
                activeOrders: currentActiveOrders,
                avgProcessingTime: Math.round(avgProcessingTime),
                volumeChange,
                transactionChange,
                processingTimeChange
              });
            } catch (error) {
              console.error('Error processing last month stats:', error);
              toast.error('Failed to calculate monthly comparison');
            }
          });
        } catch (error) {
          console.error('Error processing current month stats:', error);
          toast.error('Failed to calculate current month stats');
        } finally {
          setLoading(false);
          setRefreshing(false);
        }
      });
    } catch (error) {
      console.error('Error fetching dashboard stats:', error);
      toast.error('Failed to load dashboard statistics');
      setLoading(false);
      setRefreshing(false);
    }
  };

  if (loading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {[...Array(4)].map((_, i) => (
          <div key={i} className="bg-white p-6 rounded-lg shadow-sm animate-pulse">
            <div className="h-4 bg-gray-200 rounded w-1/2 mb-4"></div>
            <div className="h-8 bg-gray-200 rounded mb-2"></div>
            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      <div className="bg-white p-6 rounded-lg shadow-sm">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm text-gray-600 mb-1">Monthly Volume</p>
            <h3 className="text-2xl font-bold text-gray-900">
              {stats.totalVolume >= 1000000
                ? `$${(stats.totalVolume / 1000000).toFixed(1)}M`
                : `$${(stats.totalVolume / 1000).toFixed(1)}K`} USDT
            </h3>
            <div className={`flex items-center mt-2 ${
              stats.volumeChange >= 0 ? 'text-green-600' : 'text-red-600'
            }`}>
              {stats.volumeChange >= 0 ? (
                <ArrowUpRight className="w-4 h-4 mr-1" />
              ) : (
                <ArrowDownRight className="w-4 h-4 mr-1" />
              )}
              <span className="text-sm font-medium">
                {Math.abs(stats.volumeChange).toFixed(1)}% from last month
              </span>
            </div>
          </div>
          <div className="p-3 bg-green-50 rounded-full">
            <DollarSign className="w-6 h-6 text-green-600" />
          </div>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-sm">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm text-gray-600 mb-1">Active Orders</p>
            <h3 className="text-2xl font-bold text-gray-900">{stats.activeOrders}</h3>
            <div className="flex items-center mt-2 text-blue-600">
              <Activity className="w-4 h-4 mr-1" />
              <span className="text-sm font-medium">Pending + Processing</span>
            </div>
          </div>
          <div className="p-3 bg-blue-50 rounded-full">
            <Activity className="w-6 h-6 text-blue-600" />
          </div>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-sm">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm text-gray-600 mb-1">Monthly Transactions</p>
            <h3 className="text-2xl font-bold text-gray-900">{stats.totalTransactions}</h3>
            <div className={`flex items-center mt-2 ${
              stats.transactionChange >= 0 ? 'text-green-600' : 'text-red-600'
            }`}>
              {stats.transactionChange >= 0 ? (
                <ArrowUpRight className="w-4 h-4 mr-1" />
              ) : (
                <ArrowDownRight className="w-4 h-4 mr-1" />
              )}
              <span className="text-sm font-medium">
                {Math.abs(stats.transactionChange).toFixed(1)}% from last month
              </span>
            </div>
          </div>
          <div className="p-3 bg-purple-50 rounded-full">
            <Activity className="w-6 h-6 text-purple-600" />
          </div>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-sm">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm text-gray-600 mb-1">Avg. Processing Time</p>
            <h3 className="text-2xl font-bold text-gray-900">{stats.avgProcessingTime} min</h3>
            <div className={`flex items-center mt-2 ${
              stats.processingTimeChange >= 0 ? 'text-green-600' : 'text-red-600'
            }`}>
              {stats.processingTimeChange >= 0 ? (
                <ArrowUpRight className="w-4 h-4 mr-1" />
              ) : (
                <ArrowDownRight className="w-4 h-4 mr-1" />
              )}
              <span className="text-sm font-medium">
                {Math.abs(stats.processingTimeChange).toFixed(1)}% from last month
              </span>
            </div>
          </div>
          <div className="p-3 bg-orange-50 rounded-full">
            <Clock className="w-6 h-6 text-orange-600" />
          </div>
        </div>
      </div>
    </div>
  );
}