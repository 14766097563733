import React from 'react';
import { Scale } from 'lucide-react';

export default function AML() {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-sm p-8">
          <div className="flex items-center mb-8">
            <Scale className="w-8 h-8 text-blue-600 mr-4" />
            <h1 className="text-3xl font-bold text-gray-900">Anti-Money Laundering (AML) Policy</h1>
          </div>

          <div className="prose prose-blue max-w-none">
            <h2>1. Introduction</h2>
            <p>
              This Anti-Money Laundering (AML) Policy outlines our commitment to preventing money laundering and any activity that facilitates money laundering or funding of terrorist or criminal activities.
            </p>

            <h2>2. Compliance Framework</h2>
            <p>
              We comply with all applicable AML laws and regulations in Pakistan, including:
            </p>
            <ul>
              <li>Anti-Money Laundering Act, 2010</li>
              <li>State Bank of Pakistan's AML/CFT Regulations</li>
              <li>FATF Recommendations</li>
            </ul>

            <h2>3. Customer Due Diligence</h2>
            <p>
              We implement robust Know Your Customer (KYC) procedures:
            </p>
            <ul>
              <li>Verification of identity using government-issued documents</li>
              <li>Address verification</li>
              <li>Source of funds verification for large transactions</li>
              <li>Ongoing monitoring of transactions</li>
            </ul>

            <h2>4. Risk Assessment</h2>
            <p>
              Our risk-based approach includes:
            </p>
            <ul>
              <li>Customer risk profiling</li>
              <li>Transaction monitoring thresholds</li>
              <li>Geographic risk factors</li>
              <li>Business relationship risk assessment</li>
            </ul>

            <h2>5. Transaction Monitoring</h2>
            <p>
              We monitor transactions for suspicious activities:
            </p>
            <ul>
              <li>Unusual transaction patterns</li>
              <li>Structured transactions</li>
              <li>High-risk transaction alerts</li>
              <li>Regular account activity reviews</li>
            </ul>

            <h2>6. Record Keeping</h2>
            <p>
              We maintain comprehensive records of:
            </p>
            <ul>
              <li>Customer identification documents</li>
              <li>Transaction history</li>
              <li>Due diligence reports</li>
              <li>Suspicious activity reports</li>
            </ul>

            <h2>7. Staff Training</h2>
            <p>
              Our staff undergoes regular training on:
            </p>
            <ul>
              <li>AML regulations and compliance</li>
              <li>Identifying suspicious activities</li>
              <li>Customer due diligence procedures</li>
              <li>Reporting requirements</li>
            </ul>

            <h2>8. Reporting</h2>
            <p>
              We have established procedures for:
            </p>
            <ul>
              <li>Internal reporting of suspicious activities</li>
              <li>Regulatory reporting requirements</li>
              <li>Cooperation with law enforcement</li>
            </ul>

            <h2>9. Policy Updates</h2>
            <p>
              This policy is reviewed and updated regularly to ensure compliance with evolving regulations and best practices.
            </p>

            <h2>10. Contact</h2>
            <p>
              For questions about our AML policy or to report suspicious activity, please contact our compliance team.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}