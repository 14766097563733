import React, { useState, useEffect } from 'react';
import { Save, Loader } from 'lucide-react';
import { ref, onValue, update } from 'firebase/database';
import { database } from '../../../lib/firebase';
import toast from 'react-hot-toast';

interface UrduContent {
  main_heading: string;
  main_content: string;
  button_text: string;
}

export default function UrduSection() {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [content, setContent] = useState<UrduContent>({
    main_heading: 'محفوظ اور قابلِ اعتماد USDT ٹریڈنگ – بینک ڈسپیوٹ سے نجات پائیں!',
    main_content: 'پاکستان میں Binance P2P پر USDT فروخت کرتے ہوئے بہت سے صارفین کو بینک ڈسپیوٹ اور اکاؤنٹ فریز ہونے جیسے مسائل کا سامنا کرنا پڑتا ہے...',
    button_text: 'ٹریڈ شروع کرنے کے لیے ابھی ہمارے پلیٹ فارم پر رجسٹر کریں!'
  });

  useEffect(() => {
    const contentRef = ref(database, 'content/urdu');
    const unsubscribe = onValue(contentRef, (snapshot) => {
      if (snapshot.exists()) {
        setContent(snapshot.val());
      }
      setLoading(false);
    }, (error) => {
      console.error('Error fetching Urdu content:', error);
      toast.error('Failed to load Urdu content');
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (saving) return;

    try {
      setSaving(true);
      const contentRef = ref(database, 'content/urdu');
      await update(contentRef, {
        ...content,
        updated_at: new Date().toISOString()
      });
      toast.success('Urdu section updated successfully');
    } catch (error) {
      console.error('Error saving Urdu content:', error);
      toast.error('Failed to save changes');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="animate-pulse">
        <div className="h-8 bg-gray-200 rounded w-1/4 mb-8"></div>
        <div className="space-y-4">
          {[...Array(3)].map((_, i) => (
            <div key={i} className="h-16 bg-gray-200 rounded"></div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">Urdu Section</h1>
        <button
          onClick={handleSubmit}
          disabled={saving}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
        >
          {saving ? (
            <>
              <Loader className="w-5 h-5 mr-2 animate-spin" />
              Saving...
            </>
          ) : (
            <>
              <Save className="w-5 h-5 mr-2" />
              Save Changes
            </>
          )}
        </button>
      </div>

      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Main Heading (Urdu)
            </label>
            <input
              type="text"
              value={content.main_heading}
              onChange={(e) => setContent({ ...content, main_heading: e.target.value })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 font-['Noto_Nastaliq_Urdu']"
              dir="rtl"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Main Content (Urdu)
            </label>
            <textarea
              value={content.main_content}
              onChange={(e) => setContent({ ...content, main_content: e.target.value })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 font-['Noto_Nastaliq_Urdu'] h-40"
              dir="rtl"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Button Text (Urdu)
            </label>
            <input
              type="text"
              value={content.button_text}
              onChange={(e) => setContent({ ...content, button_text: e.target.value })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 font-['Noto_Nastaliq_Urdu']"
              dir="rtl"
              required
            />
          </div>
        </div>
      </div>
    </div>
  );
}