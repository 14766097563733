import React, { useRef, useEffect, useState } from 'react';
import { DollarSign, ArrowRight, Loader, Upload, X, ArrowRightCircle, Info } from 'lucide-react';
import type { PaymentMethod, TransactionProof } from '../../types';
import PaymentMethodSelector from './PaymentMethodSelector';
import { useRate } from '../../context/RateContext';
import { ref, onValue } from 'firebase/database';
import { database } from '../../lib/firebase';

interface SellFormProps {
  amount: string;
  onAmountChange: (amount: string) => void;
  selectedMethod: PaymentMethod | null;
  onMethodSelect: (method: PaymentMethod) => void;
  paymentMethods: PaymentMethod[];
  onSubmit: (e: React.FormEvent) => void;
  proof: TransactionProof | null;
  onProofChange: (proof: TransactionProof | null) => void;
  submitting: boolean;
}

export default function SellForm({
  amount,
  onAmountChange,
  selectedMethod,
  onMethodSelect,
  paymentMethods,
  onSubmit,
  proof,
  onProofChange,
  submitting
}: SellFormProps) {
  const { currentRate } = useRate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [currentStep, setCurrentStep] = useState<'amount' | 'method' | 'proof' | 'submit'>('amount');
  const [transactionFee, setTransactionFee] = useState(0);
  const [feeType, setFeeType] = useState<'percentage' | 'flat'>('percentage');
  const [feeEnabled, setFeeEnabled] = useState(false);
  const numAmount = parseFloat(amount);
  const isValidAmount = !isNaN(numAmount) && numAmount > 0;
  const feeAmount = isValidAmount && feeEnabled
    ? feeType === 'percentage'
      ? (numAmount * transactionFee) / 100
      : transactionFee
    : 0;
  const netAmount = isValidAmount ? numAmount - feeAmount : 0;
  const totalPKR = isValidAmount ? (netAmount * currentRate).toFixed(2) : '0.00';

  useEffect(() => {
    // Subscribe to transaction fee settings
    const settingsRef = ref(database, 'system_settings');
    const unsubscribe = onValue(settingsRef, (snapshot) => {
      if (snapshot.exists()) {
        const settings = snapshot.val();
        setFeeEnabled(settings.transaction_fee_enabled || false);
        setFeeType(settings.transaction_fee_type || 'percentage');
        if (settings.transaction_fee_type === 'flat') {
          setTransactionFee(settings.transaction_fee_flat || 0);
        } else {
          setTransactionFee(settings.transaction_fee_percentage || 0);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (amount) {
      if (!selectedMethod) {
        setCurrentStep('method');
      } else if (!proof) {
        setCurrentStep('proof');
      } else {
        setCurrentStep('submit');
      }
    } else {
      setCurrentStep('amount');
    }
  }, [amount, selectedMethod, proof]);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    try {
      // Validate file type
      if (!file.type.startsWith('image/')) {
        throw new Error('Please upload an image file');
      }

      // Validate file size (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        throw new Error('File size should be less than 5MB');
      }

      // Create preview
      const reader = new FileReader();
      reader.onloadend = () => {
        onProofChange({
          file,
          preview: reader.result as string
        });
      };
      reader.readAsDataURL(file);
    } catch (error: any) {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      alert(error.message);
    }
  };

  const removeProof = () => {
    onProofChange(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const isButtonDisabled = !amount || !selectedMethod || !isValidAmount || !proof || submitting;

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h2 className="text-xl font-semibold mb-6 flex items-center">
        <DollarSign className="w-5 h-5 mr-2 text-blue-600" />
        Sell USDT
      </h2>
      <form onSubmit={onSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Amount (USDT)
          </label>
          <div className="relative">
            <input
              type="number"
              value={amount}
              onChange={(e) => onAmountChange(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter USDT amount"
              min="0"
              step="any"
              required
              disabled={submitting}
            />
            <div className="absolute inset-y-0 right-0 flex items-center">
              <span className="px-4 text-gray-500 font-medium">USDT</span>
              {currentStep === 'amount' && (
                <div className="guide-arrow visible">
                  <ArrowRightCircle className="w-6 h-6 text-blue-600 mr-2" />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="relative">
          <PaymentMethodSelector
            paymentMethods={paymentMethods}
            selectedMethod={selectedMethod}
            onMethodSelect={onMethodSelect}
            disabled={submitting}
          />
          {currentStep === 'method' && (
            <div className="guide-arrow visible absolute -right-8 top-1/2 transform -translate-y-1/2">
              <ArrowRightCircle className="w-6 h-6 text-blue-600" />
            </div>
          )}
        </div>

        <div className="relative">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Payment Proof Screenshot
          </label>
          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg">
            <div className="space-y-1 text-center">
              {proof ? (
                <div className="relative">
                  <img
                    src={proof.preview}
                    alt="Payment proof"
                    className="max-h-48 rounded-lg mx-auto"
                  />
                  <button
                    type="button"
                    onClick={removeProof}
                    className="absolute -top-2 -right-2 bg-red-100 text-red-600 rounded-full p-1 hover:bg-red-200"
                    disabled={submitting}
                  >
                    <X className="w-4 h-4" />
                  </button>
                </div>
              ) : (
                <>
                  <Upload className="mx-auto h-12 w-12 text-gray-400" />
                  <div className="flex text-sm text-gray-600">
                    <label
                      htmlFor="proof-upload"
                      className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                    >
                      <span>Upload a file</span>
                      <input
                        id="proof-upload"
                        ref={fileInputRef}
                        type="file"
                        className="sr-only"
                        onChange={handleFileChange}
                        accept="image/*"
                        required={!proof}
                        disabled={submitting}
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-gray-500">
                    PNG, JPG, GIF up to 5MB
                  </p>
                </>
              )}
            </div>
          </div>
          {currentStep === 'proof' && (
            <div className="guide-arrow visible absolute -right-8 top-1/2 transform -translate-y-1/2">
              <ArrowRightCircle className="w-6 h-6 text-blue-600" />
            </div>
          )}
        </div>

        <div className="p-4 bg-gray-50 rounded-lg space-y-2">
          <div className="flex justify-between items-center">
            <p className="text-sm text-gray-600">Current Rate</p>
            <p className="text-lg font-bold text-blue-600">1 USDT = PKR {currentRate.toFixed(2)}</p>
          </div>
          
          {feeEnabled && isValidAmount && (
            <div className="flex justify-between items-center text-sm">
              <div className="flex items-center text-gray-600">
                <Info className="w-4 h-4 mr-1" />
                Transaction Fee {feeType === 'percentage' ? `(${transactionFee}%)` : '(Flat)'}
              </div>
              <p className="text-gray-900">{feeAmount.toFixed(2)} USDT</p>
            </div>
          )}

          {isValidAmount && (
            <div className="flex justify-between items-center pt-2 border-t border-gray-200">
              <p className="text-sm text-gray-600">You'll Receive</p>
              <p className="text-lg font-bold text-green-600">PKR {totalPKR}</p>
            </div>
          )}
        </div>

        <div className="relative">
          <button
            type="submit"
            disabled={isButtonDisabled}
            className={`w-full py-3 px-4 rounded-lg font-semibold flex items-center justify-center transition-all duration-200 ${
              isButtonDisabled
                ? 'bg-gray-400 text-white cursor-not-allowed'
                : 'bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white shadow-lg hover:shadow-xl'
            }`}
          >
            {submitting ? (
              <>
                <Loader className="w-5 h-5 mr-2 animate-spin" />
                Processing...
              </>
            ) : (
              <>
                Submit Transaction
                <ArrowRight className="w-5 h-5 ml-2" />
              </>
            )}
          </button>
          {currentStep === 'submit' && (
            <div className="guide-arrow visible absolute -right-8 top-1/2 transform -translate-y-1/2">
              <ArrowRightCircle className="w-6 h-6 text-blue-600" />
            </div>
          )}
        </div>
      </form>
    </div>
  );
}