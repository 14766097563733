import React from 'react';
import type { Transaction } from '../../../types';

interface TransactionHeaderProps {
  transactions: Transaction[];
}

export default function TransactionHeader({ transactions }: TransactionHeaderProps) {
  const totalVolume = transactions
    .filter(tx => tx.status === 'completed')
    .reduce((acc, tx) => acc + tx.amount, 0);

  return (
    <div className="flex justify-between items-center">
      <h1 className="text-2xl font-bold text-gray-900">Transaction Management</h1>
      <div className="flex items-center space-x-4">
        <span className="text-sm text-gray-500">Total Volume:</span>
        <span className="text-lg font-bold text-blue-600">
          {totalVolume.toFixed(2)} USDT
        </span>
      </div>
    </div>
  );
}