import React, { useState, useRef } from 'react';
import { X as XIcon, Loader, CheckCircle, Clock, XCircle, Image as ImageIcon, ExternalLink, Upload } from 'lucide-react';
import type { Transaction } from '../../../types';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { ref as dbRef, update, push } from 'firebase/database';
import { storage, database } from '../../../lib/firebase';
import toast from 'react-hot-toast';

interface TransactionDetailsModalProps {
  transaction: Transaction;
  onClose: () => void;
}

export default function TransactionDetailsModal({ transaction, onClose }: TransactionDetailsModalProps) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadingProof, setUploadingProof] = useState(false);
  const [selectedProofUrl, setSelectedProofUrl] = useState<string | null>(null);
  const [proofType, setProofType] = useState<'user' | 'admin' | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const getStatusIcon = (status: Transaction['status']) => {
    switch (status) {
      case 'completed':
        return <CheckCircle className="w-5 h-5 text-green-500" />;
      case 'processing':
        return <Clock className="w-5 h-5 text-blue-500" />;
      case 'failed':
        return <XCircle className="w-5 h-5 text-red-500" />;
      default:
        return <Clock className="w-5 h-5 text-yellow-500" />;
    }
  };

  const getStatusColor = (status: Transaction['status']) => {
    switch (status) {
      case 'completed':
        return 'text-green-800 bg-green-100';
      case 'processing':
        return 'text-blue-800 bg-blue-100';
      case 'failed':
        return 'text-red-800 bg-red-100';
      default:
        return 'text-yellow-800 bg-yellow-100';
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Validate file type
    if (!file.type.startsWith('image/')) {
      toast.error('Please upload an image file');
      return;
    }

    // Validate file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      toast.error('File size should be less than 5MB');
      return;
    }

    setSelectedFile(file);
  };

  const uploadAdminProof = async () => {
    if (!selectedFile) return;

    try {
      setUploadingProof(true);

      // Upload to transaction-proofs/user_id/filename
      const fileExtension = selectedFile.name.split('.').pop();
      const fileName = `${transaction.transaction_id}_admin_${Date.now()}.${fileExtension}`;
      const proofPath = `transaction-proofs/${transaction.user_id}/${fileName}`;
      const proofRef = storageRef(storage, proofPath);
      
      // Upload the file
      await uploadBytes(proofRef, selectedFile);
      const proofUrl = await getDownloadURL(proofRef);

      // Update transaction with admin proof and complete status
      const orderRef = dbRef(database, `orders/${transaction.id}`);
      await update(orderRef, {
        admin_proof_url: proofUrl,
        status: 'completed',
        updated_at: new Date().toISOString()
      });

      // Create notification for user
      const notificationRef = dbRef(database, 'notifications');
      await push(notificationRef, {
        user_id: transaction.user_id,
        title: 'Transaction Completed',
        message: `Your transaction of ${transaction.amount} USDT has been completed. Payment proof is available in your order details.`,
        type: 'success',
        created_at: new Date().toISOString()
      });

      toast.success('Transaction completed with payment proof');
      setSelectedFile(null);
      onClose();
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      console.error('Error uploading admin proof:', error);
      toast.error('Failed to upload payment proof. Please try again.');
    } finally {
      setUploadingProof(false);
    }
  };

  const viewProof = (url: string, type: 'user' | 'admin') => {
    setSelectedProofUrl(url);
    setProofType(type);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4 overflow-y-auto">
      <div className="bg-white rounded-lg max-w-4xl w-full">
        {/* Header */}
        <div className="flex justify-between items-center p-6 border-b border-gray-200">
          <h2 className="text-xl font-semibold">Transaction Details</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <XIcon className="w-6 h-6" />
          </button>
        </div>

        {/* Content */}
        <div className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Left Column */}
            <div className="space-y-6">
              <div>
                <h3 className="text-sm font-medium text-gray-500">Transaction ID</h3>
                <p className="mt-1 text-gray-900 break-all">{transaction.transaction_id}</p>
              </div>

              <div>
                <h3 className="text-sm font-medium text-gray-500">Amount</h3>
                <p className="mt-1 text-gray-900">{transaction.amount} USDT</p>
                <p className="text-gray-600">Rate: {transaction.rate} PKR</p>
                <p className="text-gray-600 font-medium">
                  Total: {(transaction.amount * transaction.rate).toFixed(2)} PKR
                </p>
              </div>

              <div>
                <h3 className="text-sm font-medium text-gray-500">Status</h3>
                <div className="flex items-center mt-1">
                  {getStatusIcon(transaction.status)}
                  <span className={`ml-2 px-2 py-1 rounded-full capitalize ${
                    getStatusColor(transaction.status)
                  }`}>
                    {transaction.status}
                  </span>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="space-y-6">
              <div>
                <h3 className="text-sm font-medium text-gray-500">User Details</h3>
                <p className="mt-1 text-gray-900">{transaction.user_name}</p>
                <p className="text-gray-600">{transaction.user_email}</p>
              </div>

              <div>
                <h3 className="text-sm font-medium text-gray-500">Bank Details</h3>
                <p className="mt-1 text-gray-900">{transaction.bank_details.account_name}</p>
                <p className="text-gray-600">{transaction.bank_details.bank_name}</p>
                <p className="text-gray-600 break-all">{transaction.bank_details.iban}</p>
              </div>

              <div>
                <h3 className="text-sm font-medium text-gray-500">Timestamps</h3>
                <p className="mt-1 text-gray-900">Created: {new Date(transaction.created_at).toLocaleString()}</p>
                <p className="text-gray-600">
                  Updated: {new Date(transaction.updated_at).toLocaleString()}
                </p>
              </div>
            </div>
          </div>

          {/* Proof Section */}
          <div className="mt-8 border-t pt-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* User's Payment Proof */}
              <div>
                <h3 className="text-sm font-medium text-gray-500 mb-2">User's Payment Proof</h3>
                <button
                  onClick={() => viewProof(transaction.proof_url, 'user')}
                  className="inline-flex items-center px-4 py-2 text-blue-600 hover:bg-blue-50 rounded-lg"
                >
                  <ImageIcon className="w-5 h-5 mr-2" />
                  View Proof
                </button>
              </div>

              {/* Admin's Payment Proof */}
              <div>
                {transaction.status === 'processing' ? (
                  <div>
                    <h3 className="text-sm font-medium text-gray-500 mb-2">Upload Payment Proof</h3>
                    <div className="space-y-4">
                      <div className="flex items-center space-x-4">
                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={handleFileChange}
                          accept="image/*"
                          className="hidden"
                        />
                        <button
                          type="button"
                          onClick={() => fileInputRef.current?.click()}
                          className="flex items-center px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                        >
                          <Upload className="w-5 h-5 mr-2" />
                          Select Image
                        </button>
                        {selectedFile && (
                          <span className="text-sm text-gray-600 truncate max-w-[200px]">
                            {selectedFile.name}
                          </span>
                        )}
                      </div>
                      {selectedFile && (
                        <button
                          onClick={uploadAdminProof}
                          disabled={uploadingProof}
                          className="w-full flex items-center justify-center px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 disabled:opacity-50"
                        >
                          {uploadingProof ? (
                            <>
                              <Loader className="w-5 h-5 mr-2 animate-spin" />
                              Uploading...
                            </>
                          ) : (
                            <>
                              <CheckCircle className="w-5 h-5 mr-2" />
                              Complete Transaction with Proof
                            </>
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                ) : transaction.admin_proof_url && (
                  <div>
                    <h3 className="text-sm font-medium text-gray-500 mb-2">Admin Payment Proof</h3>
                    <button
                      onClick={() => viewProof(transaction.admin_proof_url!, 'admin')}
                      className="inline-flex items-center px-4 py-2 text-blue-600 hover:bg-blue-50 rounded-lg"
                    >
                      <ImageIcon className="w-5 h-5 mr-2" />
                      View Admin Proof
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Proof Viewer Modal */}
      {selectedProofUrl && (
        <div className="fixed inset-0 z-60 flex items-center justify-center bg-black bg-opacity-75">
          <div className="relative max-w-4xl w-full mx-4">
            <div className="bg-white rounded-lg p-4">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium">
                  {proofType === 'user' ? "User's Payment Proof" : "Admin's Payment Proof"}
                </h3>
                <div className="flex items-center space-x-2">
                  <a
                    href={selectedProofUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-2 text-gray-600 hover:text-gray-900"
                    title="Open in new tab"
                  >
                    <ExternalLink className="w-5 h-5" />
                  </a>
                  <button
                    onClick={() => {
                      setSelectedProofUrl(null);
                      setProofType(null);
                    }}
                    className="p-2 text-gray-600 hover:text-gray-900"
                  >
                    <XIcon className="w-5 h-5" />
                  </button>
                </div>
              </div>
              <div className="relative w-full max-h-[70vh] overflow-auto">
                <img
                  src={selectedProofUrl}
                  alt="Payment Proof"
                  className="max-w-full h-auto mx-auto rounded-lg"
                  style={{ maxHeight: 'calc(70vh - 4rem)' }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}